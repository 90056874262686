#characterSelect {
    text-align: center;

    .container {
        @include slide-container;

        .side {
            @include slide-side;

            @media (min-width: $desktop) and (min-height: 800px) {
                opacity: 1;
            }

            &.left {
                display: none;

                @include landscape {
                    display: flex;
                }
            }
        }

        .center {
            @include slide-center;

            h2 {
                @include title;
                margin-top: 3rem;

                @include landscape {
                    margin-top: inherit;
                }
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 10%) scale(1.5);
                z-index: -1;
                opacity: 0.8;

                @include portrait {
                    transform: translate(-50%, -13%) scale(1.2);
                }

                @include landscape {
                    transform: translate(-50%, -33%) scale(0.8);
                }

                @include desktop {
                    transform: translate(-50%, -13%);
                }
            }

            .button {
                margin-bottom: 3rem;

                @include landscape {
                    margin-bottom: inherit;
                }
            }
        }

        .content,
        .grid {
            margin: auto;
        }

        .grid {
            display: grid;
            @include grid-6;
            grid-gap: 5px;
            max-width: 500px;

            @include min-h(700px) {
                @include grid-4;
            }

            @include min-h(800px) {
                @include grid-3;
            }

            @include portrait {
                @include grid-4;
            }

            @include landscape {
                grid-template-columns: repeat(4, 75px);
            }

            @include desktop {
                grid-gap: 10px;
                max-width: unset;
            }

            @include min(1600px) {
                grid-gap: 18px;
                grid-template-columns: repeat(4, 95px);
            }

            @include min(2000px) {
                grid-gap: 18px;
                grid-template-columns: repeat(4, 115px);
            }

            .item {
                position: relative;
                display: none;
                opacity: 0.8;
                border: 1px solid #fff;

                @include max(1023px) {
                    &:nth-child(-n+12) {
                        display: block;
                    }
                }

                @include min(1024px) {
                    &:nth-child(-n+12) {
                        display: block;
                    }
                }

                @media (min-width: 1024px) and (min-height: 600px) {
                    &:nth-child(-n+16) {
                        display: block;
                    }
                }

                @media (min-width: 1024px) and (min-height: 800px) {
                    &:nth-child(-n+20) {
                        display: block;
                    }
                }

                span.question-mark {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: $colour-primary;

                    &:after {
                        content: '\003F';
                        display: block;
                        color: #fff;
                        @include font-black;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -45%);
                        font-size: 15vw;
                        opacity: 0.5;

                        @include min(700px) {
                            font-size: 10vw;
                        }

                        @include landscape {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .fp-bg {
        background-image: url(../img/bg/character-select.jpg);
        background-position: center center;
    }
}

#clients {
    text-align: center;

    .container {
        @include slide-container;
        margin: 2rem auto;
        max-width: 1560px;

        @include portrait {
            margin: 4rem auto 2rem;
        }

        @media (min-height: 1100px) {
            max-width: 1850px;
        }

        @include x-large-height {
            max-width: 2100px;
        }

        .tile {
            @media (min-width: 1150px) and (max-height: 900px) and (max-width: 1299px),
            (min-width: 1400px) and (max-height: 900px) {
                padding-bottom: 85%;
            }


            p {
                margin-bottom: 1rem;
            }
        }

        .side {
            @include slide-side;
            justify-content: center;
        }

        .center {
            @include slide-center;
            padding: 2rem 0;
            min-width: 0;

            @include max(1023px) {
                @include force-full-width;
            }

            @include landscape {
                padding-top: 0;
                padding-bottom: 0;
            }

            h2 {
                @include title;
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;

                @include min(1300px) {
                    max-width: 560px;
                }
            }
        }

        .content {
            margin: auto;
            min-width: 0;

            @include large {
                padding: 0 2rem;
            }

            .button {
                margin: 2rem 0;
            }
        }
    }

    .center {
        @include landscape {
            padding: 0 2rem;
        }
    }

    #client-logos {

        @include max($desktop) {
            -webkit-mask-image: linear-gradient(to left, transparent 0%, black 35%, black 65%, transparent 100%);
            -webkit-mask-mode: luminance;
        }

        @include large {
            margin: 5rem 0 3rem;
        }

        .slick-slide {
            display: inline-block;
            vertical-align: middle;
            float: none;
        }

        img {
            margin: auto;
            padding: 10px;
            max-width: 130px;
            width: 100%;
        }

        @include min($desktop) {
            display: grid;
            @include grid-4;
            row-gap: 2rem;
            column-gap: 3rem;

            div {
                display: flex;

                img {
                    padding: 0;
                }
            }
        }
    }

    .contact-us-tile__buttons {
        @include tiny {
            display: none;
        }
        @include medium-only {
            display: none;
        }
    }

    .services-slider-inner > h4 {
        @include tiny {
            display: none;
        }
    }

    .services-slider-inner > .button {
        @include medium-only {
            display: none;
        }
    }

    .contact-us-tile__text {
        @include medium-only {
            margin: 10px 0 15px;
        }
    }


}

#social {
  .container {
    @include slide-container;
  }
}

#characterSelect {
    .slide-fade-leave {
        transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        opacity: 0;
    }
    .slide-fade-active {
        transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        opacity: 1;
    }
}
