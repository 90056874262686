@import 'settings';
@import 'mixins';

.custom-scrollbar {
  .mCSB_scrollTools {
    right: 15px;
  }

  // .mCustomScrollBox {
  //   @include fade-bottom;
  // }

  .mCSB_inside > .mCSB_container {
     @include min($desktop) {
      margin-right: 54px;
     }
  }

  .mCSB_container {
    display: flex;
    flex-direction: column;
  }

  .mCSB_scrollTools .mCSB_draggerContainer {
    height: calc(100% - 120px);
    right: -14px;
    top: 2.5rem;
  }

  &--small {
    .mCSB_scrollTools .mCSB_draggerContainer {
      height: 70%;
    }
  }

  &--medium {
    .mCSB_scrollTools .mCSB_draggerContainer {
      height: 73%;
    }
  }

  .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 1px;
    width: 2px;
  }

  .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 1px;
    width: 10px;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  .mCSB_buttonUp,
  .mCSB_buttonDown {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    cursor: pointer;
    height: 30px !important;
    width: 30px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: border .3s ease-in;

    &:hover {
      border-color: rgba(255, 255, 255, 1);

      &::before {
        border-bottom: 10px solid rgba(255,255,255,1);
      }
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 10px solid rgba(255,255,255,0.5);
    }
  }

  .mCSB_buttonDown {
    &::before {
      transform: rotate(180deg);
    }
  }

  .mCSB_scrollTools {
    display: flex !important;
  }
}
