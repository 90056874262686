

#family {
    text-align: center;
    overflow: hidden;

    .fp-tableCell {
        vertical-align: bottom;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: center;
        transform: scale(1.03);
    }

    .container {
        @include gutter;
        padding-bottom: 50px;
        padding-top: 50px;
        display: flex;

        @include portrait {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        background: -moz-linear-gradient(top, rgba(27, 75, 191, 0) 0%, rgba(27, 75, 191, 0.01) 1%, rgba(27, 74, 190, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(27, 75, 191, 0) 0%, rgba(27, 75, 191, 0.01) 1%, rgba(27, 74, 190, 1) 100%);
        background: linear-gradient(to bottom, rgba(27, 75, 191, 0) 0%, rgba(27, 75, 191, 0.01) 1%, rgba(27, 74, 190, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001b4bbf', endColorstr='#1b4abe', GradientType=0);

        .inner {
            margin: auto;
            max-width: 920px;

            h1 {
                margin-bottom: 1.5rem;
                opacity: 0;

                @include portrait {
                    @include title;
                }
            }

            p,
            .button {
                opacity: 0;
            }

            .button {
                margin-top: 1rem;
            }
        }
    }
}

#grid-gallery {
    text-align: center;
    overflow: hidden;
    padding: 0;
    margin-bottom: 50px;

    @include landscape {
        margin-bottom: 0;
    }

    .container {
        @include gutter;
        margin-left: auto;
        margin-right: auto;

        @include min(1024px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @include min-h(800px) {
            padding-top: 0;
            padding-bottom: 0;
        }

        @media (min-height: 800px) and (min-width: 1240px) {
            max-width: 1150px;
        }

        @media (min-height: 800px) and (min-width: 1440px) {
            max-width: 1400px;
        }

        @media (min-height: 800px) and (min-width: 1840px) {
            max-width: 1700px;
        }

        @media (min-width: $desktop) and (max-height: 950px) {
            max-width: 1300px;
        }

        @media (min-width: 1500px) and (max-height: 950px) {
            max-width: 1500px;
        }

        max-width: 2300px;

        section {
            min-width: 0;

            @include landscape {
                display: grid;
                grid-gap: 1rem;

                &.left {
                    grid-template-columns: 22.5% 1fr;
                    margin-bottom: -40px;

                    @include large {
                        margin-bottom: -13%;
                    }

                    @media (min-height: 800px) and (min-width: 1440px) {
                        margin-bottom: -5%;
                    }

                    .carousel-letterbox {
                        margin-bottom: 1rem;
                    }
                }

                &.right {
                    grid-template-columns: 1fr 22.5%;
                    align-items: end;

                    .carousel-rectangle {
                        order: 2;
                    }

                    .slick-wrap {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

                    .carousel-letterbox {
                        margin-top: 1rem;
                    }
                }
            }
        }

        .slick-wrap {
            min-width: 0;
        }

        .center {
            @include landscape {
                position: absolute;
                z-index: 5;
                width: 80%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-30deg);
                margin: 4px 0 0;
                max-width: 1500px;
            }
        }

    }

    h2 {
        line-height: 1;
        margin: 0;

        @include min(780px) {
            font-size: 10vw;
        }

        @include landscape {
            background: -webkit-linear-gradient(45deg, #0B33B6, #0B33B6);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 5rem;
            text-align: left;
            padding-left: 11.5%;
        }

        @media (min-height: 800px) and (min-width: 1440px) {
            font-size: 6rem;
        }

        @media (min-height: 800px) and (min-width: 1840px) {
            font-size: 8rem;
        }

        @media (min-width: $desktop) and (max-height: 950px) {
            font-size: 5.5rem;
        }

        &.light {
            @include landscape {
                background: -webkit-linear-gradient(45deg, #0E6FD8, #0E6FD8);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                text-align: right;
                padding-right: 11.5%;
                padding-left: 0;
            }
        }
    }

    .divider {
        margin: 15px 0 23px;
        position: relative;

        @include landscape {
            margin: 2px auto 11px;
            width: 85%;
        }



        &:after,
        &:before {
            content: '';
            display: block;
            border-top: 1px solid #fff;
            margin-top: -1px;
            width: calc(50% - 5.5rem);
            position: absolute;
            top: 50%;
            left: 0;

            @include landscape {
                width: calc(50% - 6.5rem);
            }
        }

        &:after {
            left: unset;
            right: 0;
        }
    }

    .carousel-rectangle {
        @include max(1023px) {
            display: none;
        }

        &.mobile {
            @include max(1023px) {
                position: absolute;
                top: 0;
                left: 0;
                height: 100vh;
                width: 100vw;
                z-index: -1;
                display: block;
                transform: scale(1.01);
                display: block;

                img {
                    height: 100vh;
                    width: 100vw;
                    object-fit: cover;
                    object-position: center;
                    top: 0;
                    left: 0;
                    filter: brightness(0.8);
                }
            }
        }
    }

    .carousel-letterbox {
        @include max(1023px) {
            display: none;
        }
        width: 36%;
    }

    .carousel-square {
        @include max(1023px) {
            display: none;
        }
        width: 13%;
    }
}