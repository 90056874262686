.footer {
  .container {
    @include slide-container;

    display: flex;
    justify-content: center;
    font-size: $font-size;

    @include mobile-only {
      padding: 0.8rem 1rem;
    }

    @include desktop {
      padding: 1rem 1rem;
    }
  }

  .menu {
    display: flex;
    list-style: none;
    flex-direction: column;
    padding-inline-start: 0;
    align-items: center;

    @include mobile-only {
      font-size: 11px;
    }

    a {
      text-decoration: none;
    }

    li {
      position: relative;
    }
  }

  .copyright-mobile {
    display: flex;
  }
  
  .copyright-desktop {
    display: none;
  }
}