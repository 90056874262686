// TODO Move to module level

.social-feeds-grid {
  padding: 0;
  font-size: .85em;
}

.social-feeds-grid-item {
  width: 100%;
  box-sizing: border-box;
}