#fullpage {
    overflow: hidden;
    .fp-controlArrow {
        margin-top: -18px;

        @include slider-nav-button;

        &.fp-next {
            @include slider-nav-next;

            @include desktop {
                right: 80px;
            }
        }

        &.fp-prev {
            @include slider-nav-prev;
            
            @include desktop {
                left: 80px;
            }
        }
    }
}

// Scroller Icon
.scroller {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    margin: auto;
    width: 34px;
    height: 50px;
    display: none;
    transform: translateX(-50%);
    opacity: 0.8;
    cursor: pointer;
    filter: brightness(1.3);

    @media (min-width: 1100px) and (min-height: 800px) {
        display: block;
    }
}

.mouse {
    width: 3px;
    padding: 2px 10px;
    height: 35px;
    border: 2px solid #0091FF;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
    margin: auto;
}

.wheel {
    width: 3px;
    height: 3px;
    margin-top: 3px;
    border-radius: 100%;
    background-color: #0091FF;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15, .41, .69, .94);
    animation-iteration-count: infinite;
}

@keyframes scroll {
    0% {
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(20px);
        opacity: 0;
    }
}

