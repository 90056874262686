.nav-trigger {
    height: 60px;
    width: 60px;
    margin: auto 0 auto -15px;
    position: relative;

    @include desktop {
        height: 60px;
        width: 60px;
    }

    .hamburger {
        width: 24px;
        height: 17px;
        position: absolute;
        top: 50%;
        z-index: 10;
        left: 50%;
        margin: -8px 0 0 -15px;

        @include desktop {
            width: 30px;
            height: 20px;
            margin: -10px 0 0 -15px;
        }

        span {
            display: block;
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            transition: .3s ease-in-out;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 50%;
                margin-top: -2px;
            }

            &:nth-child(3) {
                bottom: 1px;

                @include desktop {
                    bottom: 2px;
                }
            }
        }
    }
}

#menu {
    @include nav-bg;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 999999;
    width: 80%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: -20px 0 20px 20px rgba(0, 0, 0, 0);
    transform: translate(-100%, 0%);
    transition: all 1s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    overflow: auto;

    @include desktop {
        width: 25%;
        max-width: 600px;
    }

    &.is-open {
        transform: translate(0%, 0%);
        box-shadow: -20px 0 20px 20px rgba(0, 0, 0, 0.2);
    }

    #close-menu {
        align-self: flex-end;
        margin: 1rem;
        border-color: rgba(255,255,255,0.2);
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        flex: 1;

        &.Main {
            margin: auto;

            li {
                margin: 20px 0;
                text-align: center;
            }

            a {
                text-decoration: none;
                @include font-black;
                text-transform: uppercase;
                font-size: 1.5rem;
                display: inline-block;
                text-align: center;
                transition: color 0.25s ease-in-out;
                padding: 0 10px;

                @include desktop {
                    font-size: 2rem;
                    position: relative;

                    &:before {
                        content: "";
                        width: 0;
                        background-color: white;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        transition: width 0.25s ease-in-out;
                        z-index: -1;
                    }

                    &:hover {
                        color: $colour-primary !important;
                        cursor: pointer;
                        
                        &:before {
                            width: 100%;
                        }
                    }
                }

                &.contact-button {
                    margin: 0;
                    position: static;

                    &:after {
                        content: '';
                        width: 0;
                    }
                }
            }
        }
    }

    .social {
        flex: unset;
        margin: auto;
        margin-bottom: 2rem;

        ul {
            margin: auto;
            display: flex;

            li {
                margin-left: 0;
                margin-right: 10px;
            }
        }


    }
}

