.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

#social {
    @media (max-height: 799px), (max-width: 1099px) {
        height: auto !important;
        padding-bottom: 50px;
    }

    @media (min-height: 900px) and (max-width: 1099px) {
        padding-bottom: 5rem;
    }

    @include desktop {
        padding-top: 110px;
    }

    @include x-large-height {
        padding-top: 160px;
    }

    .fp-tableCell {
        vertical-align: unset;
    }

    footer {
        .container {
            padding-bottom: 0;
            padding-top: 2rem;

            .menu {
                margin-bottom: 0;
            }
        }
    }
}

.social-feeds {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;

    @include min($desktop) {
        flex-direction: row;
        margin-left: -32px;
    }

    // &__loading-spinner {
    //   position: relative;
    //   top: -300px;
    //   z-index: 9999;
    // }

    &__loading-spinner--hide {
      display: none;
    }

    &__main {
        display: flex;
        flex-direction: column;
        height: 90%;
        width: 100%;

        @media (max-height: 799px), (max-width: 1099px) {
            height: auto !important;
        }

        @include min($desktop) {
            width: 92%;
        }
    }

    &__load-more-wrap {
      display: flex !important;
      justify-content: center !important;
      flex-direction: column;
      align-items: center;
    }

    &__load-more {
        width: 100%;
        margin: 2rem 0 5px;
        justify-content: center;
        align-self: center;

        @include min($desktop) {
            width: 15%;
        }
    }

    &__load-more--hide {
        display: none;
    }

    .mCSB_container {
        margin-right: 48px !important;
    }
}

.social-feeds-filters {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include panel-blue;
    margin: 0;
    height: 150px;

    @include max(1024px) {
        height: 150px !important;
    }

    @include max(1099px) {
        max-height: 150px;
    }

    @include max(768px) {
        max-height: 130px;
    }

    @include min(650px) {
        margin-bottom: 2rem;
        padding-bottom: 0.8rem;
    }

    @include min($desktop) {
        height: 100%;
        padding: 30px 15px;
        width: 8%;
        margin-right: 20px;
    }

    &__toggles {
        list-style: none;
        padding-inline-start: 0;
        display: flex;
        margin: 0.8rem 0 1rem;

        @include min($desktop) {
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }
    }

    &__toggle-item {
        margin: 0 15px;
        transition: all .25s ease-in-out;

        @include min($desktop) {
            margin: 40px 0;
        }

        &:hover {
            cursor: pointer;

            svg g g {
                fill: rgba(255, 255, 255, 1);
            }
        }

        a[data-filter-active=true] {
            svg g g {
                fill: rgba(255, 255, 255, 1);
            }
        }

        svg {
            height: 25px;
            width: 25px;

            @include min(1400px) {
                width: 45px;
                height: 45px;
            }

            g g {
                fill: rgba(255, 255, 255, 0.5);
            }
        }
    }

    &--active-item {
        svg {
            transform: scale(1.1);
        }

        svg g g {
            fill: rgba(255, 255, 255, 1);
        }
    }

    &__title {
        text-align: center;

        h3 {
            @include sub-title;
            text-transform: uppercase;
            font-weight: normal;
            margin: 2rem 0 0 0;

            @include min($desktop) {
                font-size: 1.5vw;
                margin-top: 0.5rem;
            }
        }
    }

    &__selected-count {
        font-size: 14px;
        margin: 0;
    }

    &__selected {
        padding-inline-start: 0;
        list-style: none;
        margin: 0;
        font-size: 14px;

        li {
            text-transform: capitalize;
        }

        @include mobile-only {
            display: none;
        }
    }

    &__clear,
    &__selected-filters,
    &__selected-count {
        visibility: hidden;

        @include mobile-only {
            display: none;
        }
    }

    &__clear {
        @include max(1500px) {
            display: none
        }
    }

    &--filters-applied {

        .social-feeds-filters__clear,
        .social-feeds-filters__selected-filters,
        .social-feeds-filters__selected-count {
            visibility: visible;

            @include mobile-only {
                display: none;
            }
        }
    }
}

.social-feeds-grid-item {
    @include panel-blue;
    padding: 30px;
    opacity: 1;
    width: 100%;
    padding: 2em;
    margin-bottom: 20px;

    @include min(750px) {
        width: calc(50% - 10px);  
    }

    @include min(1200px) {
        width: calc(33.5% - 20px);
    }

    @include min(1400px) {
        width: calc(25% - 20px);
    }

    p {
        font-size: 16px;
    }

    a {
        text-decoration: none;

        &:focus, &:hover {
            text-decoration: underline;
        }
    }

    &__title {
        h5 {
            margin: 0;
            text-transform: capitalize;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    &__image {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__author {
        display: flex;
        margin-bottom: 15px;

        img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 10px;
        }

        h5 {
            font-weight: normal;
            font-style: italic;
        }

        p {
            font-weight: bold;
            line-height: 1;
            margin-top: -4px;
        }

        h5,
        p {
            margin: 0;
            font-size: 14px;
        }
    }

    &__footer {
        margin-top: 8px;

        span {
            font-size: 12px;
            margin-right: 10px;

            &:after {
                font-family: 'Font Awesome 5 Pro';
                font-weight: 100;
                margin-left: 5px;
                font-size: 13px;
                transform: translate(0, 1px);
                display: inline-block;
            }

            &.likes:after {
                content: '\f004';
            }

            &.comments:after {
                content: '\f075';
            }
        }
    }
  
  &--transparent {
    opacity: 0;
  }
}
