@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.file-container {
  overflow: hidden;
  margin-top: 10px; }
  .file-container input[type="file"] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    height: 60px; }
  .file-container svg {
    fill: #ffffff;
    margin-left: 5px; }
  .file-container .file-label {
    padding: 8px;
    background: #000000;
    color: #ffffff;
    font-size: 15px;
    width: 100%;
    text-align: center !important;
    margin-bottom: 20px;
    cursor: pointer; }
  .file-container input[type="file"]:focus + .file-label,
  .file-container input[type="file"] + .file-label:hover {
    background-color: red; }
  .file-container input[type="file"] + .file-label {
    cursor: pointer; }

.col-lg-6.file-container {
  position: relative; }

.finalist-submit {
  background: #be8f2d;
  color: #ffffff;
  width: 100%;
  border: 0px;
  font-size: 18px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom; }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr; }

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto; }

.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonUp {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft, .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  margin-left: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

.mCSB_scrollTools, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
  -webkit-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out;
  transition: width .2s ease-out .2s,height .2s ease-out .2s,margin-left .2s ease-out .2s,margin-right .2s ease-out .2s,margin-top .2s ease-out .2s,margin-bottom .2s ease-out .2s,opacity .2s ease-in-out,background-color .2s ease-in-out; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools, .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools, .mCustomScrollBox:hover > .mCSB_scrollTools, .mCustomScrollBox:hover ~ .mCSB_scrollTools, .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag, .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight, .mCSB_scrollTools .mCSB_buttonUp {
  background-image: url(mCSB_buttons.png);
  background-repeat: no-repeat;
  opacity: .4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0; }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px; }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px; }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px; }

.mCSB_scrollTools .mCSB_buttonDown:hover, .mCSB_scrollTools .mCSB_buttonLeft:hover, .mCSB_scrollTools .mCSB_buttonRight:hover, .mCSB_scrollTools .mCSB_buttonUp:hover {
  opacity: .75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonDown:active, .mCSB_scrollTools .mCSB_buttonLeft:active, .mCSB_scrollTools .mCSB_buttonRight:active, .mCSB_scrollTools .mCSB_buttonUp:active {
  opacity: .9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger, .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  height: 16px;
  margin: -1px 0; }

.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
  background-repeat: repeat-y;
  opacity: .3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%); }

.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d-dark.mCSB_scrollTools, .mCS-3d.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick-dark.mCSB_scrollTools, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer, .mCS-3d-thick.mCSB_scrollTools, .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick-dark.mCSB_scrollTools_vertical, .mCS-3d-thick.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal, .mCS-3d-thick.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal, .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical, .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger, .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail, .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight, .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail, .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.social-feeds-grid {
  padding: 0;
  font-size: .85em; }

.social-feeds-grid-item {
  width: 100%;
  box-sizing: border-box; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* CSA related */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: inline-block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: .75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }

.fa-ul > li {
  position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  to {
    transform: rotate(1turn); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1); }

.fa-flip-vertical {
  transform: scaleY(-1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical,
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)"; }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1); }

:root .fa-flip-both,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

.fa-500px:before {
  content: "\f26e"; }

.fa-abacus:before {
  content: "\f640"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acorn:before {
  content: "\f6ae"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adobe:before {
  content: "\f778"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-alarm-clock:before {
  content: "\f34e"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-alicorn:before {
  content: "\f6b0"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-analytics:before {
  content: "\f643"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angel:before {
  content: "\f779"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-crate:before {
  content: "\f6b1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-alt-down:before {
  content: "\f354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\f346"; }

.fa-arrow-alt-from-left:before {
  content: "\f347"; }

.fa-arrow-alt-from-right:before {
  content: "\f348"; }

.fa-arrow-alt-from-top:before {
  content: "\f349"; }

.fa-arrow-alt-left:before {
  content: "\f355"; }

.fa-arrow-alt-right:before {
  content: "\f356"; }

.fa-arrow-alt-square-down:before {
  content: "\f350"; }

.fa-arrow-alt-square-left:before {
  content: "\f351"; }

.fa-arrow-alt-square-right:before {
  content: "\f352"; }

.fa-arrow-alt-square-up:before {
  content: "\f353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\f34a"; }

.fa-arrow-alt-to-left:before {
  content: "\f34b"; }

.fa-arrow-alt-to-right:before {
  content: "\f34c"; }

.fa-arrow-alt-to-top:before {
  content: "\f34d"; }

.fa-arrow-alt-up:before {
  content: "\f357"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-from-bottom:before {
  content: "\f342"; }

.fa-arrow-from-left:before {
  content: "\f343"; }

.fa-arrow-from-right:before {
  content: "\f344"; }

.fa-arrow-from-top:before {
  content: "\f345"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-square-down:before {
  content: "\f339"; }

.fa-arrow-square-left:before {
  content: "\f33a"; }

.fa-arrow-square-right:before {
  content: "\f33b"; }

.fa-arrow-square-up:before {
  content: "\f33c"; }

.fa-arrow-to-bottom:before {
  content: "\f33d"; }

.fa-arrow-to-left:before {
  content: "\f33e"; }

.fa-arrow-to-right:before {
  content: "\f340"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-atom-alt:before {
  content: "\f5d3"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-axe:before {
  content: "\f6b2"; }

.fa-axe-battle:before {
  content: "\f6b3"; }

.fa-baby:before {
  content: "\f77c"; }

.fa-baby-carriage:before {
  content: "\f77d"; }

.fa-backpack:before {
  content: "\f5d4"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-bacon:before {
  content: "\f7e5"; }

.fa-badge:before {
  content: "\f335"; }

.fa-badge-check:before {
  content: "\f336"; }

.fa-badge-dollar:before {
  content: "\f645"; }

.fa-badge-percent:before {
  content: "\f646"; }

.fa-badger-honey:before {
  content: "\f6b4"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-balance-scale-left:before {
  content: "\f515"; }

.fa-balance-scale-right:before {
  content: "\f516"; }

.fa-ball-pile:before {
  content: "\f77e"; }

.fa-ballot:before {
  content: "\f732"; }

.fa-ballot-check:before {
  content: "\f733"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-barcode-alt:before {
  content: "\f463"; }

.fa-barcode-read:before {
  content: "\f464"; }

.fa-barcode-scan:before {
  content: "\f465"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball:before {
  content: "\f432"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-basketball-hoop:before {
  content: "\f435"; }

.fa-bat:before {
  content: "\f6b5"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-bolt:before {
  content: "\f376"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-slash:before {
  content: "\f377"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-school:before {
  content: "\f5d5"; }

.fa-bell-school-slash:before {
  content: "\f5d6"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bells:before {
  content: "\f77f"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-biohazard:before {
  content: "\f780"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blanket:before {
  content: "\f498"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blender-phone:before {
  content: "\f6b6"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blog:before {
  content: "\f781"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bone-break:before {
  content: "\f5d8"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-alt:before {
  content: "\f5d9"; }

.fa-book-dead:before {
  content: "\f6b7"; }

.fa-book-heart:before {
  content: "\f499"; }

.fa-book-medical:before {
  content: "\f7e6"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-book-spells:before {
  content: "\f6b8"; }

.fa-book-user:before {
  content: "\f7e7"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-books:before {
  content: "\f5db"; }

.fa-books-medical:before {
  content: "\f7e8"; }

.fa-boot:before {
  content: "\f782"; }

.fa-booth-curtain:before {
  content: "\f734"; }

.fa-bow-arrow:before {
  content: "\f6b9"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-bowling-pins:before {
  content: "\f437"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-alt:before {
  content: "\f49a"; }

.fa-box-ballot:before {
  content: "\f735"; }

.fa-box-check:before {
  content: "\f467"; }

.fa-box-fragile:before {
  content: "\f49b"; }

.fa-box-full:before {
  content: "\f49c"; }

.fa-box-heart:before {
  content: "\f49d"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-box-up:before {
  content: "\f49f"; }

.fa-box-usd:before {
  content: "\f4a0"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-boxes-alt:before {
  content: "\f4a1"; }

.fa-boxing-glove:before {
  content: "\f438"; }

.fa-brackets:before {
  content: "\f7e9"; }

.fa-brackets-curly:before {
  content: "\f7ea"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-bread-loaf:before {
  content: "\f7eb"; }

.fa-bread-slice:before {
  content: "\f7ec"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-browser:before {
  content: "\f37e"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-bullseye-arrow:before {
  content: "\f648"; }

.fa-bullseye-pointer:before {
  content: "\f649"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-burrito:before {
  content: "\f7ed"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-bus-school:before {
  content: "\f5dd"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-cabinet-filing:before {
  content: "\f64b"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calculator-alt:before {
  content: "\f64c"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-day:before {
  content: "\f783"; }

.fa-calendar-edit:before {
  content: "\f333"; }

.fa-calendar-exclamation:before {
  content: "\f334"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-star:before {
  content: "\f736"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-calendar-week:before {
  content: "\f784"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-alt:before {
  content: "\f332"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-campfire:before {
  content: "\f6ba"; }

.fa-campground:before {
  content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-candle-holder:before {
  content: "\f6bc"; }

.fa-candy-cane:before {
  content: "\f786"; }

.fa-candy-corn:before {
  content: "\f6bd"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-bump:before {
  content: "\f5e0"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-garage:before {
  content: "\f5e2"; }

.fa-car-mechanic:before {
  content: "\f5e3"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-car-tilt:before {
  content: "\f5e5"; }

.fa-car-wash:before {
  content: "\f5e6"; }

.fa-caret-circle-down:before {
  content: "\f32d"; }

.fa-caret-circle-left:before {
  content: "\f32e"; }

.fa-caret-circle-right:before {
  content: "\f330"; }

.fa-caret-circle-up:before {
  content: "\f331"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-carrot:before {
  content: "\f787"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cash-register:before {
  content: "\f788"; }

.fa-cat:before {
  content: "\f6be"; }

.fa-cauldron:before {
  content: "\f6bf"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chair:before {
  content: "\f6c0"; }

.fa-chair-office:before {
  content: "\f6c1"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-line-down:before {
  content: "\f64d"; }

.fa-chart-network:before {
  content: "\f78a"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-chart-pie-alt:before {
  content: "\f64e"; }

.fa-chart-scatter:before {
  content: "\f7ee"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-cheese:before {
  content: "\f7ef"; }

.fa-cheese-swiss:before {
  content: "\f7f0"; }

.fa-cheeseburger:before {
  content: "\f7f1"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-bishop-alt:before {
  content: "\f43b"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-clock:before {
  content: "\f43d"; }

.fa-chess-clock-alt:before {
  content: "\f43e"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-king-alt:before {
  content: "\f440"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-knight-alt:before {
  content: "\f442"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-pawn-alt:before {
  content: "\f444"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-queen-alt:before {
  content: "\f446"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chess-rook-alt:before {
  content: "\f448"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-double-down:before {
  content: "\f322"; }

.fa-chevron-double-left:before {
  content: "\f323"; }

.fa-chevron-double-right:before {
  content: "\f324"; }

.fa-chevron-double-up:before {
  content: "\f325"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-square-down:before {
  content: "\f329"; }

.fa-chevron-square-left:before {
  content: "\f32a"; }

.fa-chevron-square-right:before {
  content: "\f32b"; }

.fa-chevron-square-up:before {
  content: "\f32c"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chimney:before {
  content: "\f78b"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-claw-marks:before {
  content: "\f6c2"; }

.fa-clinic-medical:before {
  content: "\f7f2"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clipboard-list-check:before {
  content: "\f737"; }

.fa-clipboard-prescription:before {
  content: "\f5e8"; }

.fa-clipboard-user:before {
  content: "\f7f3"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-drizzle:before {
  content: "\f738"; }

.fa-cloud-hail:before {
  content: "\f739"; }

.fa-cloud-hail-mixed:before {
  content: "\f73a"; }

.fa-cloud-meatball:before {
  content: "\f73b"; }

.fa-cloud-moon:before {
  content: "\f6c3"; }

.fa-cloud-moon-rain:before {
  content: "\f73c"; }

.fa-cloud-rain:before {
  content: "\f73d"; }

.fa-cloud-rainbow:before {
  content: "\f73e"; }

.fa-cloud-showers:before {
  content: "\f73f"; }

.fa-cloud-showers-heavy:before {
  content: "\f740"; }

.fa-cloud-sleet:before {
  content: "\f741"; }

.fa-cloud-snow:before {
  content: "\f742"; }

.fa-cloud-sun:before {
  content: "\f6c4"; }

.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-clouds:before {
  content: "\f744"; }

.fa-clouds-moon:before {
  content: "\f745"; }

.fa-clouds-sun:before {
  content: "\f746"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-club:before {
  content: "\f327"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-code-commit:before {
  content: "\f386"; }

.fa-code-merge:before {
  content: "\f387"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-coffee-togo:before {
  content: "\f6c5"; }

.fa-coffin:before {
  content: "\f6c6"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-alt-check:before {
  content: "\f4a2"; }

.fa-comment-alt-dollar:before {
  content: "\f650"; }

.fa-comment-alt-dots:before {
  content: "\f4a3"; }

.fa-comment-alt-edit:before {
  content: "\f4a4"; }

.fa-comment-alt-exclamation:before {
  content: "\f4a5"; }

.fa-comment-alt-lines:before {
  content: "\f4a6"; }

.fa-comment-alt-medical:before {
  content: "\f7f4"; }

.fa-comment-alt-minus:before {
  content: "\f4a7"; }

.fa-comment-alt-plus:before {
  content: "\f4a8"; }

.fa-comment-alt-slash:before {
  content: "\f4a9"; }

.fa-comment-alt-smile:before {
  content: "\f4aa"; }

.fa-comment-alt-times:before {
  content: "\f4ab"; }

.fa-comment-check:before {
  content: "\f4ac"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-edit:before {
  content: "\f4ae"; }

.fa-comment-exclamation:before {
  content: "\f4af"; }

.fa-comment-lines:before {
  content: "\f4b0"; }

.fa-comment-medical:before {
  content: "\f7f5"; }

.fa-comment-minus:before {
  content: "\f4b1"; }

.fa-comment-plus:before {
  content: "\f4b2"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comment-smile:before {
  content: "\f4b4"; }

.fa-comment-times:before {
  content: "\f4b5"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-alt:before {
  content: "\f4b6"; }

.fa-comments-alt-dollar:before {
  content: "\f652"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compass-slash:before {
  content: "\f5e9"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-alt:before {
  content: "\f422"; }

.fa-compress-arrows-alt:before {
  content: "\f78c"; }

.fa-compress-wide:before {
  content: "\f326"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-container-storage:before {
  content: "\f4b7"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-conveyor-belt:before {
  content: "\f46e"; }

.fa-conveyor-belt-alt:before {
  content: "\f46f"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-corn:before {
  content: "\f6c7"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cow:before {
  content: "\f6c8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-credit-card-blank:before {
  content: "\f389"; }

.fa-credit-card-front:before {
  content: "\f38a"; }

.fa-cricket:before {
  content: "\f449"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-croissant:before {
  content: "\f7f6"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-crutch:before {
  content: "\f7f7"; }

.fa-crutches:before {
  content: "\f7f8"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-curling:before {
  content: "\f44a"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dagger:before {
  content: "\f6cb"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-debug:before {
  content: "\f7f9"; }

.fa-deer:before {
  content: "\f78e"; }

.fa-deer-rudolph:before {
  content: "\f78f"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-democrat:before {
  content: "\f747"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-desktop-alt:before {
  content: "\f390"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dewpoint:before {
  content: "\f748"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-d10:before {
  content: "\f6cd"; }

.fa-dice-d12:before {
  content: "\f6ce"; }

.fa-dice-d20:before {
  content: "\f6cf"; }

.fa-dice-d4:before {
  content: "\f6d0"; }

.fa-dice-d6:before {
  content: "\f6d1"; }

.fa-dice-d8:before {
  content: "\f6d2"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-diploma:before {
  content: "\f5ea"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-disease:before {
  content: "\f7fa"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-do-not-enter:before {
  content: "\f5ec"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dog:before {
  content: "\f6d3"; }

.fa-dog-leashed:before {
  content: "\f6d4"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-empty:before {
  content: "\f473"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-dolly-flatbed-alt:before {
  content: "\f475"; }

.fa-dolly-flatbed-empty:before {
  content: "\f476"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dragon:before {
  content: "\f6d5"; }

.fa-draw-circle:before {
  content: "\f5ed"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-draw-square:before {
  content: "\f5ef"; }

.fa-dreidel:before {
  content: "\f792"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drumstick:before {
  content: "\f6d6"; }

.fa-drumstick-bite:before {
  content: "\f6d7"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-duck:before {
  content: "\f6d8"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dumpster:before {
  content: "\f793"; }

.fa-dumpster-fire:before {
  content: "\f794"; }

.fa-dungeon:before {
  content: "\f6d9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-ear:before {
  content: "\f5f0"; }

.fa-ear-muffs:before {
  content: "\f795"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-eclipse:before {
  content: "\f749"; }

.fa-eclipse-alt:before {
  content: "\f74a"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-egg:before {
  content: "\f7fb"; }

.fa-egg-fried:before {
  content: "\f7fc"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-elephant:before {
  content: "\f6da"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-h-alt:before {
  content: "\f39b"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ellipsis-v-alt:before {
  content: "\f39c"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-empty-set:before {
  content: "\f656"; }

.fa-engine-warning:before {
  content: "\f5f2"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-dollar:before {
  content: "\f657"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-ethernet:before {
  content: "\f796"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-square:before {
  content: "\f321"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-alt:before {
  content: "\f424"; }

.fa-expand-arrows:before {
  content: "\f31d"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expand-wide:before {
  content: "\f320"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-evil:before {
  content: "\f6db"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-female:before {
  content: "\f182"; }

.fa-field-hockey:before {
  content: "\f44c"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-figma:before {
  content: "\f799"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-certificate:before {
  content: "\f5f3"; }

.fa-file-chart-line:before {
  content: "\f659"; }

.fa-file-chart-pie:before {
  content: "\f65a"; }

.fa-file-check:before {
  content: "\f316"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-csv:before {
  content: "\f6dd"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-edit:before {
  content: "\f31c"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-exclamation:before {
  content: "\f31a"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-minus:before {
  content: "\f318"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-plus:before {
  content: "\f319"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-spreadsheet:before {
  content: "\f65b"; }

.fa-file-times:before {
  content: "\f317"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-user:before {
  content: "\f65c"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-files-medical:before {
  content: "\f7fd"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-film-alt:before {
  content: "\f3a0"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-alt:before {
  content: "\f7e4"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-fire-smoke:before {
  content: "\f74b"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-fireplace:before {
  content: "\f79a"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-fish-cooked:before {
  content: "\f7fe"; }

.fa-fist-raised:before {
  content: "\f6de"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-alt:before {
  content: "\f74c"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flag-usa:before {
  content: "\f74d"; }

.fa-flame:before {
  content: "\f6df"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flask-poison:before {
  content: "\f6e0"; }

.fa-flask-potion:before {
  content: "\f6e1"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flower:before {
  content: "\f7ff"; }

.fa-flower-daffodil:before {
  content: "\f800"; }

.fa-flower-tulip:before {
  content: "\f801"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-fog:before {
  content: "\f74e"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-folder-times:before {
  content: "\f65f"; }

.fa-folder-tree:before {
  content: "\f802"; }

.fa-folders:before {
  content: "\f660"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-football-helmet:before {
  content: "\f44f"; }

.fa-forklift:before {
  content: "\f47a"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-fragile:before {
  content: "\f4bb"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-french-fries:before {
  content: "\f803"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frosty-head:before {
  content: "\f79b"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-function:before {
  content: "\f661"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gas-pump-slash:before {
  content: "\f5f4"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-ghost:before {
  content: "\f6e2"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gift-card:before {
  content: "\f663"; }

.fa-gifts:before {
  content: "\f79c"; }

.fa-gingerbread-man:before {
  content: "\f79d"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass:before {
  content: "\f804"; }

.fa-glass-champagne:before {
  content: "\f79e"; }

.fa-glass-cheers:before {
  content: "\f79f"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fa-glass-whiskey-rocks:before {
  content: "\f7a1"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glasses-alt:before {
  content: "\f5f5"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-europe:before {
  content: "\f7a2"; }

.fa-globe-snow:before {
  content: "\f7a3"; }

.fa-globe-stand:before {
  content: "\f5f6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-golf-club:before {
  content: "\f451"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-lines:before {
  content: "\f7a4"; }

.fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guitar:before {
  content: "\f7a6"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-h1:before {
  content: "\f313"; }

.fa-h2:before {
  content: "\f314"; }

.fa-h3:before {
  content: "\f315"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamburger:before {
  content: "\f805"; }

.fa-hammer:before {
  content: "\f6e3"; }

.fa-hammer-war:before {
  content: "\f6e4"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-heart:before {
  content: "\f4bc"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-box:before {
  content: "\f47b"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-magic:before {
  content: "\f6e5"; }

.fa-hand-holding-seedling:before {
  content: "\f4bf"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-holding-water:before {
  content: "\f4c1"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-middle-finger:before {
  content: "\f806"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-receiving:before {
  content: "\f47c"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-heart:before {
  content: "\f4c3"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-hands-usd:before {
  content: "\f4c5"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-handshake-alt:before {
  content: "\f4c6"; }

.fa-hanukiah:before {
  content: "\f6e6"; }

.fa-hard-hat:before {
  content: "\f807"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hat-santa:before {
  content: "\f7a7"; }

.fa-hat-winter:before {
  content: "\f7a8"; }

.fa-hat-witch:before {
  content: "\f6e7"; }

.fa-hat-wizard:before {
  content: "\f6e8"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-head-side:before {
  content: "\f6e9"; }

.fa-head-side-brain:before {
  content: "\f808"; }

.fa-head-side-medical:before {
  content: "\f809"; }

.fa-head-vr:before {
  content: "\f6ea"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-broken:before {
  content: "\f7a9"; }

.fa-heart-circle:before {
  content: "\f4c7"; }

.fa-heart-rate:before {
  content: "\f5f8"; }

.fa-heart-square:before {
  content: "\f4c8"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-helmet-battle:before {
  content: "\f6eb"; }

.fa-hexagon:before {
  content: "\f312"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hiking:before {
  content: "\f6ec"; }

.fa-hippo:before {
  content: "\f6ed"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-mask:before {
  content: "\f6ee"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-hockey-sticks:before {
  content: "\f454"; }

.fa-holly-berry:before {
  content: "\f7aa"; }

.fa-home:before {
  content: "\f015"; }

.fa-home-alt:before {
  content: "\f80a"; }

.fa-home-heart:before {
  content: "\f4c9"; }

.fa-home-lg:before {
  content: "\f80b"; }

.fa-home-lg-alt:before {
  content: "\f80c"; }

.fa-hood-cloak:before {
  content: "\f6ef"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-horse:before {
  content: "\f6f0"; }

.fa-horse-head:before {
  content: "\f7ab"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hospital-user:before {
  content: "\f80d"; }

.fa-hospitals:before {
  content: "\f80e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotdog:before {
  content: "\f80f"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-house-damage:before {
  content: "\f6f1"; }

.fa-house-flood:before {
  content: "\f74f"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-hryvnia:before {
  content: "\f6f2"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-humidity:before {
  content: "\f750"; }

.fa-hurricane:before {
  content: "\f751"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-ice-cream:before {
  content: "\f810"; }

.fa-ice-skate:before {
  content: "\f7ac"; }

.fa-icicles:before {
  content: "\f7ad"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-igloo:before {
  content: "\f7ae"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-inbox-in:before {
  content: "\f310"; }

.fa-inbox-out:before {
  content: "\f311"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-industry-alt:before {
  content: "\f3b3"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-info-square:before {
  content: "\f30f"; }

.fa-inhaler:before {
  content: "\f5f9"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-integral:before {
  content: "\f667"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-intersection:before {
  content: "\f668"; }

.fa-inventory:before {
  content: "\f480"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-island-tropical:before {
  content: "\f811"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-key:before {
  content: "\f084"; }

.fa-key-skeleton:before {
  content: "\f6f3"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-keynote:before {
  content: "\f66c"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kidneys:before {
  content: "\f5fb"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kite:before {
  content: "\f6f4"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-knife-kitchen:before {
  content: "\f6f5"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-lambda:before {
  content: "\f66e"; }

.fa-lamp:before {
  content: "\f4ca"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-landmark-alt:before {
  content: "\f752"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laptop-medical:before {
  content: "\f812"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-layer-minus:before {
  content: "\f5fe"; }

.fa-layer-plus:before {
  content: "\f5ff"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leaf-heart:before {
  content: "\f4cb"; }

.fa-leaf-maple:before {
  content: "\f6f6"; }

.fa-leaf-oak:before {
  content: "\f6f7"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-lightbulb-dollar:before {
  content: "\f670"; }

.fa-lightbulb-exclamation:before {
  content: "\f671"; }

.fa-lightbulb-on:before {
  content: "\f672"; }

.fa-lightbulb-slash:before {
  content: "\f673"; }

.fa-lights-holiday:before {
  content: "\f7b2"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lips:before {
  content: "\f600"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location:before {
  content: "\f601"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-location-circle:before {
  content: "\f602"; }

.fa-location-slash:before {
  content: "\f603"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-alt:before {
  content: "\f30d"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-lock-open-alt:before {
  content: "\f3c2"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-loveseat:before {
  content: "\f4cc"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luchador:before {
  content: "\f455"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lungs:before {
  content: "\f604"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-mace:before {
  content: "\f6f8"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailbox:before {
  content: "\f813"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-mandolin:before {
  content: "\f6f9"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-marker-alt-slash:before {
  content: "\f605"; }

.fa-map-marker-check:before {
  content: "\f606"; }

.fa-map-marker-edit:before {
  content: "\f607"; }

.fa-map-marker-exclamation:before {
  content: "\f608"; }

.fa-map-marker-minus:before {
  content: "\f609"; }

.fa-map-marker-plus:before {
  content: "\f60a"; }

.fa-map-marker-question:before {
  content: "\f60b"; }

.fa-map-marker-slash:before {
  content: "\f60c"; }

.fa-map-marker-smile:before {
  content: "\f60d"; }

.fa-map-marker-times:before {
  content: "\f60e"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mask:before {
  content: "\f6fa"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-meat:before {
  content: "\f814"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaphone:before {
  content: "\f675"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-meteor:before {
  content: "\f753"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mind-share:before {
  content: "\f677"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-hexagon:before {
  content: "\f307"; }

.fa-minus-octagon:before {
  content: "\f308"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mistletoe:before {
  content: "\f7b4"; }

.fa-mitten:before {
  content: "\f7b5"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-mobile-android:before {
  content: "\f3ce"; }

.fa-mobile-android-alt:before {
  content: "\f3cf"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-monitor-heart-rate:before {
  content: "\f611"; }

.fa-monkey:before {
  content: "\f6fb"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-moon-cloud:before {
  content: "\f754"; }

.fa-moon-stars:before {
  content: "\f755"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mountain:before {
  content: "\f6fc"; }

.fa-mountains:before {
  content: "\f6fd"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-mug-hot:before {
  content: "\f7b6"; }

.fa-mug-marshmallows:before {
  content: "\f7b7"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-narwhal:before {
  content: "\f6fe"; }

.fa-neos:before {
  content: "\f612"; }

.fa-network-wired:before {
  content: "\f6ff"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-nintendo-switch:before {
  content: "\f418"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-octagon:before {
  content: "\f306"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-oil-temp:before {
  content: "\f614"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-omega:before {
  content: "\f67a"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-ornament:before {
  content: "\f7b8"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-otter:before {
  content: "\f700"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-pager:before {
  content: "\f815"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-brush-alt:before {
  content: "\f5a9"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-pallet-alt:before {
  content: "\f483"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-parking:before {
  content: "\f540"; }

.fa-parking-circle:before {
  content: "\f615"; }

.fa-parking-circle-slash:before {
  content: "\f616"; }

.fa-parking-slash:before {
  content: "\f617"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paw-alt:before {
  content: "\f701"; }

.fa-paw-claws:before {
  content: "\f702"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pegasus:before {
  content: "\f703"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-paintbrush:before {
  content: "\f618"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-pennant:before {
  content: "\f456"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-pepper-hot:before {
  content: "\f816"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-booth:before {
  content: "\f756"; }

.fa-person-carry:before {
  content: "\f4cf"; }

.fa-person-dolly:before {
  content: "\f4d0"; }

.fa-person-dolly-empty:before {
  content: "\f4d1"; }

.fa-person-sign:before {
  content: "\f757"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-office:before {
  content: "\f67d"; }

.fa-phone-plus:before {
  content: "\f4d2"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-php:before {
  content: "\f457"; }

.fa-pi:before {
  content: "\f67e"; }

.fa-pie:before {
  content: "\f705"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pig:before {
  content: "\f706"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-pizza:before {
  content: "\f817"; }

.fa-pizza-slice:before {
  content: "\f818"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-alt:before {
  content: "\f3de"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-hexagon:before {
  content: "\f300"; }

.fa-plus-octagon:before {
  content: "\f301"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-podium:before {
  content: "\f680"; }

.fa-podium-star:before {
  content: "\f758"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poll-people:before {
  content: "\f759"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poo-storm:before {
  content: "\f75a"; }

.fa-poop:before {
  content: "\f619"; }

.fa-popcorn:before {
  content: "\f819"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-presentation:before {
  content: "\f685"; }

.fa-print:before {
  content: "\f02f"; }

.fa-print-search:before {
  content: "\f81a"; }

.fa-print-slash:before {
  content: "\f686"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pumpkin:before {
  content: "\f707"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-question-square:before {
  content: "\f2fd"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-rabbit:before {
  content: "\f708"; }

.fa-rabbit-fast:before {
  content: "\f709"; }

.fa-racquet:before {
  content: "\f45a"; }

.fa-radiation:before {
  content: "\f7b9"; }

.fa-radiation-alt:before {
  content: "\f7ba"; }

.fa-rainbow:before {
  content: "\f75b"; }

.fa-raindrops:before {
  content: "\f75c"; }

.fa-ram:before {
  content: "\f70a"; }

.fa-ramp-loading:before {
  content: "\f4d4"; }

.fa-random:before {
  content: "\f074"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-rectangle-landscape:before {
  content: "\f2fa"; }

.fa-rectangle-portrait:before {
  content: "\f2fb"; }

.fa-rectangle-wide:before {
  content: "\f2fc"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-repeat:before {
  content: "\f363"; }

.fa-repeat-1:before {
  content: "\f365"; }

.fa-repeat-1-alt:before {
  content: "\f366"; }

.fa-repeat-alt:before {
  content: "\f364"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-republican:before {
  content: "\f75e"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-restroom:before {
  content: "\f7bd"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-retweet-alt:before {
  content: "\f361"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-ring:before {
  content: "\f70b"; }

.fa-rings-wedding:before {
  content: "\f81b"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-route-highway:before {
  content: "\f61a"; }

.fa-route-interstate:before {
  content: "\f61b"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-triangle:before {
  content: "\f61c"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-running:before {
  content: "\f70c"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-rv:before {
  content: "\f7be"; }

.fa-sack:before {
  content: "\f81c"; }

.fa-sack-dollar:before {
  content: "\f81d"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-salad:before {
  content: "\f81e"; }

.fa-sandwich:before {
  content: "\f81f"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-satellite:before {
  content: "\f7bf"; }

.fa-satellite-dish:before {
  content: "\f7c0"; }

.fa-sausage:before {
  content: "\f820"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-scalpel:before {
  content: "\f61d"; }

.fa-scalpel-path:before {
  content: "\f61e"; }

.fa-scanner:before {
  content: "\f488"; }

.fa-scanner-keyboard:before {
  content: "\f489"; }

.fa-scanner-touchscreen:before {
  content: "\f48a"; }

.fa-scarecrow:before {
  content: "\f70d"; }

.fa-scarf:before {
  content: "\f7c1"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scroll:before {
  content: "\f70e"; }

.fa-scroll-old:before {
  content: "\f70f"; }

.fa-scrubber:before {
  content: "\f2f8"; }

.fa-scythe:before {
  content: "\f710"; }

.fa-sd-card:before {
  content: "\f7c2"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-all:before {
  content: "\f367"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-sheep:before {
  content: "\f711"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield:before {
  content: "\f132"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-shield-check:before {
  content: "\f2f7"; }

.fa-shield-cross:before {
  content: "\f712"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shipping-timed:before {
  content: "\f48c"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shish-kebab:before {
  content: "\f821"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shovel:before {
  content: "\f713"; }

.fa-shovel-snow:before {
  content: "\f7c3"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shredder:before {
  content: "\f68a"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-shuttlecock:before {
  content: "\f45b"; }

.fa-sickle:before {
  content: "\f822"; }

.fa-sigma:before {
  content: "\f68b"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signal-1:before {
  content: "\f68c"; }

.fa-signal-2:before {
  content: "\f68d"; }

.fa-signal-3:before {
  content: "\f68e"; }

.fa-signal-4:before {
  content: "\f68f"; }

.fa-signal-alt:before {
  content: "\f690"; }

.fa-signal-alt-1:before {
  content: "\f691"; }

.fa-signal-alt-2:before {
  content: "\f692"; }

.fa-signal-alt-3:before {
  content: "\f693"; }

.fa-signal-alt-slash:before {
  content: "\f694"; }

.fa-signal-slash:before {
  content: "\f695"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-sim-card:before {
  content: "\f7c4"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skating:before {
  content: "\f7c5"; }

.fa-skeleton:before {
  content: "\f620"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-ski-jump:before {
  content: "\f7c7"; }

.fa-ski-lift:before {
  content: "\f7c8"; }

.fa-skiing:before {
  content: "\f7c9"; }

.fa-skiing-nordic:before {
  content: "\f7ca"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skull-crossbones:before {
  content: "\f714"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-slash:before {
  content: "\f715"; }

.fa-sledding:before {
  content: "\f7cb"; }

.fa-sleigh:before {
  content: "\f7cc"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-sliders-h-square:before {
  content: "\f3f0"; }

.fa-sliders-v:before {
  content: "\f3f1"; }

.fa-sliders-v-square:before {
  content: "\f3f2"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-plus:before {
  content: "\f5b9"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smog:before {
  content: "\f75f"; }

.fa-smoke:before {
  content: "\f760"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-sms:before {
  content: "\f7cd"; }

.fa-snake:before {
  content: "\f716"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snow-blowing:before {
  content: "\f761"; }

.fa-snowboarding:before {
  content: "\f7ce"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-snowflakes:before {
  content: "\f7cf"; }

.fa-snowman:before {
  content: "\f7d0"; }

.fa-snowmobile:before {
  content: "\f7d1"; }

.fa-snowplow:before {
  content: "\f7d2"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-soup:before {
  content: "\f823"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-spade:before {
  content: "\f2f4"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-spider:before {
  content: "\f717"; }

.fa-spider-black-widow:before {
  content: "\f718"; }

.fa-spider-web:before {
  content: "\f719"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-spinner-third:before {
  content: "\f3f4"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root:before {
  content: "\f697"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-squirrel:before {
  content: "\f71a"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-staff:before {
  content: "\f71b"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-christmas:before {
  content: "\f7d4"; }

.fa-star-exclamation:before {
  content: "\f2f3"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-stars:before {
  content: "\f762"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steak:before {
  content: "\f824"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-steering-wheel:before {
  content: "\f622"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stocking:before {
  content: "\f7d5"; }

.fa-stomach:before {
  content: "\f623"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-stretcher:before {
  content: "\f825"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-sun-cloud:before {
  content: "\f763"; }

.fa-sun-dust:before {
  content: "\f764"; }

.fa-sun-haze:before {
  content: "\f765"; }

.fa-sunrise:before {
  content: "\f766"; }

.fa-sunset:before {
  content: "\f767"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-sword:before {
  content: "\f71c"; }

.fa-swords:before {
  content: "\f71d"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablet-android:before {
  content: "\f3fb"; }

.fa-tablet-android-alt:before {
  content: "\f3fc"; }

.fa-tablet-rugged:before {
  content: "\f48f"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer:before {
  content: "\f0e4"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tachometer-alt-average:before {
  content: "\f624"; }

.fa-tachometer-alt-fast:before {
  content: "\f625"; }

.fa-tachometer-alt-fastest:before {
  content: "\f626"; }

.fa-tachometer-alt-slow:before {
  content: "\f627"; }

.fa-tachometer-alt-slowest:before {
  content: "\f628"; }

.fa-tachometer-average:before {
  content: "\f629"; }

.fa-tachometer-fast:before {
  content: "\f62a"; }

.fa-tachometer-fastest:before {
  content: "\f62b"; }

.fa-tachometer-slow:before {
  content: "\f62c"; }

.fa-tachometer-slowest:before {
  content: "\f62d"; }

.fa-taco:before {
  content: "\f826"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tally:before {
  content: "\f69c"; }

.fa-tanakh:before {
  content: "\f827"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-tasks-alt:before {
  content: "\f828"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-temperature-frigid:before {
  content: "\f768"; }

.fa-temperature-high:before {
  content: "\f769"; }

.fa-temperature-hot:before {
  content: "\f76a"; }

.fa-temperature-low:before {
  content: "\f76b"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tenge:before {
  content: "\f7d7"; }

.fa-tennis-ball:before {
  content: "\f45e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-theta:before {
  content: "\f69e"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-thunderstorm:before {
  content: "\f76c"; }

.fa-thunderstorm-moon:before {
  content: "\f76d"; }

.fa-thunderstorm-sun:before {
  content: "\f76e"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-tilde:before {
  content: "\f69f"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-times-hexagon:before {
  content: "\f2ee"; }

.fa-times-octagon:before {
  content: "\f2f0"; }

.fa-times-square:before {
  content: "\f2d3"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tire:before {
  content: "\f631"; }

.fa-tire-flat:before {
  content: "\f632"; }

.fa-tire-pressure-warning:before {
  content: "\f633"; }

.fa-tire-rugged:before {
  content: "\f634"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toilet:before {
  content: "\f7d8"; }

.fa-toilet-paper:before {
  content: "\f71e"; }

.fa-toilet-paper-alt:before {
  content: "\f71f"; }

.fa-tombstone:before {
  content: "\f720"; }

.fa-tombstone-alt:before {
  content: "\f721"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tools:before {
  content: "\f7d9"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-toothbrush:before {
  content: "\f635"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-tornado:before {
  content: "\f76f"; }

.fa-tractor:before {
  content: "\f722"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-cone:before {
  content: "\f636"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-traffic-light-go:before {
  content: "\f638"; }

.fa-traffic-light-slow:before {
  content: "\f639"; }

.fa-traffic-light-stop:before {
  content: "\f63a"; }

.fa-train:before {
  content: "\f238"; }

.fa-tram:before {
  content: "\f7da"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-trash-restore:before {
  content: "\f829"; }

.fa-trash-restore-alt:before {
  content: "\f82a"; }

.fa-treasure-chest:before {
  content: "\f723"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-tree-alt:before {
  content: "\f400"; }

.fa-tree-christmas:before {
  content: "\f7db"; }

.fa-tree-decorated:before {
  content: "\f7dc"; }

.fa-tree-large:before {
  content: "\f7dd"; }

.fa-tree-palm:before {
  content: "\f82b"; }

.fa-trees:before {
  content: "\f724"; }

.fa-trello:before {
  content: "\f181"; }

.fa-triangle:before {
  content: "\f2ec"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-trophy-alt:before {
  content: "\f2eb"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-container:before {
  content: "\f4dc"; }

.fa-truck-couch:before {
  content: "\f4dd"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-truck-plow:before {
  content: "\f7de"; }

.fa-truck-ramp:before {
  content: "\f4e0"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-turkey:before {
  content: "\f725"; }

.fa-turtle:before {
  content: "\f726"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-tv-retro:before {
  content: "\f401"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-unicorn:before {
  content: "\f727"; }

.fa-union:before {
  content: "\f6a2"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usd-circle:before {
  content: "\f2e8"; }

.fa-usd-square:before {
  content: "\f2e9"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-chart:before {
  content: "\f6a3"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-crown:before {
  content: "\f6a4"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-hard-hat:before {
  content: "\f82c"; }

.fa-user-headset:before {
  content: "\f82d"; }

.fa-user-injured:before {
  content: "\f728"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-md-chat:before {
  content: "\f82e"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-nurse:before {
  content: "\f82f"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-class:before {
  content: "\f63d"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-users-crown:before {
  content: "\f6a5"; }

.fa-users-medical:before {
  content: "\f830"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-fork:before {
  content: "\f2e3"; }

.fa-utensil-knife:before {
  content: "\f2e4"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-utensils-alt:before {
  content: "\f2e6"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-value-absolute:before {
  content: "\f6a6"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-plus:before {
  content: "\f4e1"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-volcano:before {
  content: "\f770"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume:before {
  content: "\f6a8"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-slash:before {
  content: "\f2e2"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vote-nay:before {
  content: "\f771"; }

.fa-vote-yea:before {
  content: "\f772"; }

.fa-vr-cardboard:before {
  content: "\f729"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walker:before {
  content: "\f831"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-wand:before {
  content: "\f72a"; }

.fa-wand-magic:before {
  content: "\f72b"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-warehouse-alt:before {
  content: "\f495"; }

.fa-watch:before {
  content: "\f2e1"; }

.fa-watch-fitness:before {
  content: "\f63e"; }

.fa-water:before {
  content: "\f773"; }

.fa-water-lower:before {
  content: "\f774"; }

.fa-water-rise:before {
  content: "\f775"; }

.fa-webcam:before {
  content: "\f832"; }

.fa-webcam-slash:before {
  content: "\f833"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whale:before {
  content: "\f72c"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheat:before {
  content: "\f72d"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whistle:before {
  content: "\f460"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wifi-1:before {
  content: "\f6aa"; }

.fa-wifi-2:before {
  content: "\f6ab"; }

.fa-wifi-slash:before {
  content: "\f6ac"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-wind:before {
  content: "\f72e"; }

.fa-wind-warning:before {
  content: "\f776"; }

.fa-window:before {
  content: "\f40e"; }

.fa-window-alt:before {
  content: "\f40f"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-windsock:before {
  content: "\f777"; }

.fa-wine-bottle:before {
  content: "\f72f"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-wreath:before {
  content: "\f7e2"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(../fonts/fa-brands-400.eot);
  src: url(../fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"), url(../fonts/fa-brands-400.woff2) format("woff2"), url(../fonts/fa-brands-400.woff) format("woff"), url(../fonts/fa-brands-400.ttf) format("truetype"), url(../fonts/fa-brands-400.svg#fontawesome) format("svg"); }

.fab {
  font-family: "Font Awesome 5 Brands"; }

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(../fonts/fa-light-300.eot);
  src: url(../fonts/fa-light-300.eot?#iefix) format("embedded-opentype"), url(../fonts/fa-light-300.woff2) format("woff2"), url(../fonts/fa-light-300.woff) format("woff"), url(../fonts/fa-light-300.ttf) format("truetype"), url(../fonts/fa-light-300.svg#fontawesome) format("svg"); }

.fal {
  font-weight: 300; }

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../fonts/fa-regular-400.eot);
  src: url(../fonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(../fonts/fa-regular-400.woff2) format("woff2"), url(../fonts/fa-regular-400.woff) format("woff"), url(../fonts/fa-regular-400.ttf) format("truetype"), url(../fonts/fa-regular-400.svg#fontawesome) format("svg"); }

.fal,
.far {
  font-family: "Font Awesome 5 Pro"; }

.far {
  font-weight: 400; }

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../fonts/fa-solid-900.eot);
  src: url(../fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(../fonts/fa-solid-900.woff2) format("woff2"), url(../fonts/fa-solid-900.woff) format("woff"), url(../fonts/fa-solid-900.ttf) format("truetype"), url(../fonts/fa-solid-900.svg#fontawesome) format("svg"); }

.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900; }

/*!
 * fullPage 3.0.8
 * https://github.com/alvarotrigo/fullPage.js
 *
 * @license GPLv3 for open source use only
 * or Fullpage Commercial License for commercial use
 * http://alvarotrigo.com/fullPage/pricing/
 *
 * Copyright (C) 2018 http://alvarotrigo.com/fullPage - A project by Alvaro Trigo
 */
html.fp-enabled,
.fp-enabled body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*Avoid flicker on slides transitions for mobile phones #336 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.fp-section {
  position: relative;
  -webkit-box-sizing: border-box;
  /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box;
  /* <=28 */
  box-sizing: border-box; }

.fp-slide {
  float: left; }

.fp-slide,
.fp-slidesContainer {
  height: 100%;
  display: block; }

.fp-slides {
  z-index: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  /* Safari<=6 Android<=4.3 */
  transition: all 0.3s ease-out; }

.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%; }

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%; }

.fp-slidesContainer {
  float: left;
  position: relative; }

.fp-controlArrow {
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
  position: absolute;
  z-index: 4;
  top: 50%;
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  margin-top: -38px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fp-controlArrow.fp-prev {
  left: 15px;
  width: 0;
  border-width: 38.5px 34px 38.5px 0;
  border-color: transparent #fff transparent transparent; }

.fp-controlArrow.fp-next {
  right: 15px;
  border-width: 38.5px 0 38.5px 34px;
  border-color: transparent transparent transparent #fff; }

.fp-scrollable {
  overflow: hidden;
  position: relative; }

.fp-scroller {
  overflow: hidden; }

.iScrollIndicator {
  border: 0 !important; }

.fp-notransition {
  -webkit-transition: none !important;
  transition: none !important; }

#fp-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0); }

#fp-nav.fp-right {
  right: 17px; }

#fp-nav.fp-left {
  left: 17px; }

.fp-slidesNav {
  position: absolute;
  z-index: 4;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  left: 0 !important;
  right: 0;
  margin: 0 auto !important; }

.fp-slidesNav.fp-bottom {
  bottom: 17px; }

.fp-slidesNav.fp-top {
  top: 17px; }

#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0; }

#fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative; }

.fp-slidesNav ul li {
  display: inline-block; }

#fp-nav ul li a,
.fp-slidesNav ul li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none; }

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%; }

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0px 0px -5px; }

#fp-nav ul li .fp-tooltip {
  position: absolute;
  top: -2px;
  color: #fff;
  font-size: 0.83rem;
  font-family: arial, helvetica, sans-serif;
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  display: block;
  opacity: 0;
  width: 0;
  cursor: pointer; }

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  width: auto;
  opacity: 1; }

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px; }

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px; }

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
  height: auto !important; }

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
  height: auto !important; }

/*Only display content to screen readers*/
.fp-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

@keyframes fade-in {
  from {
    opacity: 0%; }
  to {
    opacity: 100%; } }

@keyframes fade-in {
  from {
    opacity: 0%; }
  to {
    opacity: 100%; } }

.custom-scrollbar .mCSB_scrollTools {
  right: 15px; }

@media only screen and (min-width: 1100px) {
  .custom-scrollbar .mCSB_inside > .mCSB_container {
    margin-right: 54px; } }

.custom-scrollbar .mCSB_container {
  display: flex;
  flex-direction: column; }

.custom-scrollbar .mCSB_scrollTools .mCSB_draggerContainer {
  height: calc(100% - 120px);
  right: -14px;
  top: 2.5rem; }

.custom-scrollbar--small .mCSB_scrollTools .mCSB_draggerContainer {
  height: 70%; }

.custom-scrollbar--medium .mCSB_scrollTools .mCSB_draggerContainer {
  height: 73%; }

.custom-scrollbar .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  width: 2px; }

.custom-scrollbar .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 1px;
  width: 10px; }
  .custom-scrollbar .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar:hover {
    background-color: white; }

.custom-scrollbar .mCSB_buttonUp,
.custom-scrollbar .mCSB_buttonDown {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  cursor: pointer;
  height: 30px !important;
  width: 30px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: border .3s ease-in; }
  .custom-scrollbar .mCSB_buttonUp:hover,
  .custom-scrollbar .mCSB_buttonDown:hover {
    border-color: white; }
    .custom-scrollbar .mCSB_buttonUp:hover::before,
    .custom-scrollbar .mCSB_buttonDown:hover::before {
      border-bottom: 10px solid white; }
  .custom-scrollbar .mCSB_buttonUp::before,
  .custom-scrollbar .mCSB_buttonDown::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid rgba(255, 255, 255, 0.5); }

.custom-scrollbar .mCSB_buttonDown::before {
  transform: rotate(180deg); }

.custom-scrollbar .mCSB_scrollTools {
  display: flex !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "proxima-nova", sans-serif;
  font-weight: 900;
  font-style: normal;
  text-transform: uppercase; }

a {
  color: #fff; }

#loading {
  background: radial-gradient(circle, #1f5fe0 0%, #153091 100%);
  background-attachment: fixed;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex; }
  @media only screen and (min-height: 800px) {
    #loading {
      background: radial-gradient(circle, #1f5fe0 0%, #153091 85%);
      background-attachment: fixed; } }
  #loading #container {
    width: 80%;
    margin: auto;
    text-align: center; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #loading #container {
        width: 50%; } }
  #loading #loading-bar {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }
  #loading .progress {
    height: 3px;
    background: #fff;
    width: 0.01%;
    position: relative; }
  #loading .count {
    font-family: "proxima-nova", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2rem;
    height: 2rem; }

html {
  height: 100%;
  overflow-x: hidden;
  background-color: #1A46B6; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    html {
      background-color: unset; } }

body {
  background: radial-gradient(circle, #1f5fe0 0%, #153091 100%);
  background-attachment: fixed;
  color: #fff;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  height: 100%;
  overflow-x: hidden; }
  @media only screen and (min-height: 800px) {
    body {
      background: radial-gradient(circle, #1f5fe0 0%, #153091 85%);
      background-attachment: fixed; } }

#header {
  display: flex;
  height: 80px;
  padding-left: 6%;
  padding-right: 6%; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #header {
      padding-left: 5%;
      padding-right: 5%; } }
  #header .back {
    margin: auto 0 auto 0;
    border: none;
    text-align: center;
    box-shadow: none;
    background: transparent; }
  #header .contact-button {
    margin: auto auto auto 1rem;
    padding: 13px 38px 11px 16px; }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      #header .contact-button {
        padding: 0 0 0 1rem;
        border: none;
        font-size: 0;
        line-height: 0; } }
    @media only screen and (max-width: 399px) {
      #header .contact-button {
        position: absolute;
        top: 49px;
        right: 4px; } }
    @media (max-height: 699px) and (max-width: 399px) {
      #header .contact-button {
        top: 39px; } }
    #header .contact-button:after {
      right: 12px !important; }
      @media only screen and (max-width: 1099px), (max-height: 649px) {
        #header .contact-button:after {
          content: '\f0e0';
          width: 15px;
          font-weight: 100;
          font-size: 24px;
          line-height: 23px;
          transform: translateY(-50%) !important; } }
  @media only screen and (max-width: 1099px) and (max-width: 399px), only screen and (max-height: 649px) and (max-width: 399px) {
    #header .contact-button:after {
      width: 35px; } }
    #header .contact-button--close:after {
      content: '\f00d';
      font-weight: 100;
      font-size: 24px;
      line-height: 23px;
      width: 10px; }
      @media only screen and (max-width: 399px) {
        #header .contact-button--close:after {
          width: 35px; } }
      @media only screen and (min-width: 1100px) {
        #header .contact-button--close:after {
          width: 15px;
          font-size: 18px;
          line-height: 22px; } }
    #header .contact-button--hide {
      display: none; }
  @media only screen and (min-height: 700px) {
    #header {
      height: 100px; } }
  @media (max-height: 799px), (max-width: 1099px) {
    #header {
      background: rgba(25, 50, 148, 0.7); } }
  @media only screen and (min-height: 1140px) {
    #header {
      height: 150px; } }
  #header div {
    flex: 1;
    display: flex; }
  #header .logo {
    display: inline-block;
    margin: auto; }
    #header .logo img {
      width: 90px; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #header .logo img {
          width: 125px; } }
  #header .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
    opacity: 0; }

body.fullpage header {
  position: fixed;
  width: 100%;
  z-index: 100; }

#dot-grid,
.dot-grid {
  background-image: url(../img/bg/dot-grid.svg);
  background-position: center 0.1%;
  background-repeat: repeat;
  background-size: 140px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0; }
  @media only screen and (min-height: 700px) {
    #dot-grid,
    .dot-grid {
      background-position: center 0.3%; } }
  @media only screen and (min-width: 768px) {
    #dot-grid,
    .dot-grid {
      background-position: center 0.85%; } }
  @media only screen and (min-width: 1024px) {
    #dot-grid,
    .dot-grid {
      background-size: 200px;
      background-position: center 0.6%; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #dot-grid,
    .dot-grid {
      background-position: center 0.75%; } }

.dot-grid {
  opacity: 0.4; }

.nav-trigger {
  height: 60px;
  width: 60px;
  margin: auto 0 auto -15px;
  position: relative; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    .nav-trigger {
      height: 60px;
      width: 60px; } }
  .nav-trigger .hamburger {
    width: 24px;
    height: 17px;
    position: absolute;
    top: 50%;
    z-index: 10;
    left: 50%;
    margin: -8px 0 0 -15px; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      .nav-trigger .hamburger {
        width: 30px;
        height: 20px;
        margin: -10px 0 0 -15px; } }
    .nav-trigger .hamburger span {
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
      position: absolute;
      transition: .3s ease-in-out; }
      .nav-trigger .hamburger span:nth-child(1) {
        top: 0; }
      .nav-trigger .hamburger span:nth-child(2) {
        top: 50%;
        margin-top: -2px; }
      .nav-trigger .hamburger span:nth-child(3) {
        bottom: 1px; }
        @media only screen and (min-width: 1100px) and (min-height: 650px) {
          .nav-trigger .hamburger span:nth-child(3) {
            bottom: 2px; } }

#menu {
  transition: all 0.5s ease-out;
  backdrop-filter: blur(35px) contrast(1.5) brightness(1);
  background: linear-gradient(90deg, rgba(0, 168, 253, 0.4) 0%, rgba(185, 130, 252, 0.4) 100%);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999999;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  box-shadow: -20px 0 20px 20px rgba(0, 0, 0, 0);
  transform: translate(-100%, 0%);
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: auto; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #menu {
      width: 25%;
      max-width: 600px; } }
  #menu.is-open {
    transform: translate(0%, 0%);
    box-shadow: -20px 0 20px 20px rgba(0, 0, 0, 0.2); }
  #menu #close-menu {
    align-self: flex-end;
    margin: 1rem;
    border-color: rgba(255, 255, 255, 0.2); }
  #menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
    flex: 1; }
    #menu ul.Main {
      margin: auto; }
      #menu ul.Main li {
        margin: 20px 0;
        text-align: center; }
      #menu ul.Main a {
        text-decoration: none;
        font-family: "proxima-nova", sans-serif;
        font-weight: 900;
        font-style: normal;
        text-transform: uppercase;
        font-size: 1.5rem;
        display: inline-block;
        text-align: center;
        transition: color 0.25s ease-in-out;
        padding: 0 10px; }
        @media only screen and (min-width: 1100px) and (min-height: 650px) {
          #menu ul.Main a {
            font-size: 2rem;
            position: relative; }
            #menu ul.Main a:before {
              content: "";
              width: 0;
              background-color: white;
              height: 100%;
              position: absolute;
              left: 0;
              transition: width 0.25s ease-in-out;
              z-index: -1; }
            #menu ul.Main a:hover {
              color: #0E67CB !important;
              cursor: pointer; }
              #menu ul.Main a:hover:before {
                width: 100%; } }
        #menu ul.Main a.contact-button {
          margin: 0;
          position: static; }
          #menu ul.Main a.contact-button:after {
            content: '';
            width: 0; }
  #menu .social {
    flex: unset;
    margin: auto;
    margin-bottom: 2rem; }
    #menu .social ul {
      margin: auto;
      display: flex; }
      #menu .social ul li {
        margin-left: 0;
        margin-right: 10px; }

.social ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  margin: auto 0 auto auto; }
  @media only screen and (min-width: 400px) {
    .social ul {
      display: flex; } }
  .social ul li {
    display: flex;
    margin-left: 13px;
    line-height: 0; }
    .social ul li a {
      display: block; }
      .social ul li a i {
        font-size: 1.4rem; }
        @media only screen and (min-width: 1100px) and (min-height: 650px) {
          .social ul li a i {
            font-size: 1.6rem; } }

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite; }

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px; }

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px; }

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px; }

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px; }

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px; }

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px; }

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px; }

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px; }

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px; }

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px; }

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px; }

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px; }

@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1); }
  50% {
    transform: scale(1.5); } }

@media (max-height: 799px), (max-width: 1099px) {
  #social {
    height: auto !important;
    padding-bottom: 50px; } }

@media (min-height: 900px) and (max-width: 1099px) {
  #social {
    padding-bottom: 5rem; } }

@media only screen and (min-width: 1100px) and (min-height: 650px) {
  #social {
    padding-top: 110px; } }

@media only screen and (min-height: 1140px) {
  #social {
    padding-top: 160px; } }

#social .fp-tableCell {
  vertical-align: unset; }

#social footer .container {
  padding-bottom: 0;
  padding-top: 2rem; }
  #social footer .container .menu {
    margin-bottom: 0; }

.social-feeds {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0; }
  @media only screen and (min-width: 1100px) {
    .social-feeds {
      flex-direction: row;
      margin-left: -32px; } }
  .social-feeds__loading-spinner--hide {
    display: none; }
  .social-feeds__main {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%; }
    @media (max-height: 799px), (max-width: 1099px) {
      .social-feeds__main {
        height: auto !important; } }
    @media only screen and (min-width: 1100px) {
      .social-feeds__main {
        width: 92%; } }
  .social-feeds__load-more-wrap {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column;
    align-items: center; }
  .social-feeds__load-more {
    width: 100%;
    margin: 2rem 0 5px;
    justify-content: center;
    align-self: center; }
    @media only screen and (min-width: 1100px) {
      .social-feeds__load-more {
        width: 15%; } }
  .social-feeds__load-more--hide {
    display: none; }
  .social-feeds .mCSB_container {
    margin-right: 48px !important; }

.social-feeds-filters {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0, 168, 253, 0.4);
  margin: 0;
  height: 150px; }
  @media only screen and (max-width: 1024px) {
    .social-feeds-filters {
      height: 150px !important; } }
  @media only screen and (max-width: 1099px) {
    .social-feeds-filters {
      max-height: 150px; } }
  @media only screen and (max-width: 768px) {
    .social-feeds-filters {
      max-height: 130px; } }
  @media only screen and (min-width: 650px) {
    .social-feeds-filters {
      margin-bottom: 2rem;
      padding-bottom: 0.8rem; } }
  @media only screen and (min-width: 1100px) {
    .social-feeds-filters {
      height: 100%;
      padding: 30px 15px;
      width: 8%;
      margin-right: 20px; } }
  .social-feeds-filters__toggles {
    list-style: none;
    padding-inline-start: 0;
    display: flex;
    margin: 0.8rem 0 1rem; }
    @media only screen and (min-width: 1100px) {
      .social-feeds-filters__toggles {
        flex-direction: column;
        height: 100%;
        justify-content: center; } }
  .social-feeds-filters__toggle-item {
    margin: 0 15px;
    transition: all .25s ease-in-out; }
    @media only screen and (min-width: 1100px) {
      .social-feeds-filters__toggle-item {
        margin: 40px 0; } }
    .social-feeds-filters__toggle-item:hover {
      cursor: pointer; }
      .social-feeds-filters__toggle-item:hover svg g g {
        fill: white; }
    .social-feeds-filters__toggle-item a[data-filter-active=true] svg g g {
      fill: white; }
    .social-feeds-filters__toggle-item svg {
      height: 25px;
      width: 25px; }
      @media only screen and (min-width: 1400px) {
        .social-feeds-filters__toggle-item svg {
          width: 45px;
          height: 45px; } }
      .social-feeds-filters__toggle-item svg g g {
        fill: rgba(255, 255, 255, 0.5); }
  .social-feeds-filters--active-item svg {
    transform: scale(1.1); }
  .social-feeds-filters--active-item svg g g {
    fill: white; }
  .social-feeds-filters__title {
    text-align: center; }
    .social-feeds-filters__title h3 {
      font-size: 1.5rem;
      line-height: 1.25;
      text-transform: uppercase;
      font-weight: normal;
      margin: 2rem 0 0 0; }
      @media only screen and (min-width: 1300px) {
        .social-feeds-filters__title h3 {
          font-size: 2rem; } }
      @media only screen and (min-width: 1100px) {
        .social-feeds-filters__title h3 {
          font-size: 1.5vw;
          margin-top: 0.5rem; } }
  .social-feeds-filters__selected-count {
    font-size: 14px;
    margin: 0; }
  .social-feeds-filters__selected {
    padding-inline-start: 0;
    list-style: none;
    margin: 0;
    font-size: 14px; }
    .social-feeds-filters__selected li {
      text-transform: capitalize; }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      .social-feeds-filters__selected {
        display: none; } }
  .social-feeds-filters__clear, .social-feeds-filters__selected-filters, .social-feeds-filters__selected-count {
    visibility: hidden; }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      .social-feeds-filters__clear, .social-feeds-filters__selected-filters, .social-feeds-filters__selected-count {
        display: none; } }
  @media only screen and (max-width: 1500px) {
    .social-feeds-filters__clear {
      display: none; } }
  .social-feeds-filters--filters-applied .social-feeds-filters__clear,
  .social-feeds-filters--filters-applied .social-feeds-filters__selected-filters,
  .social-feeds-filters--filters-applied .social-feeds-filters__selected-count {
    visibility: visible; }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      .social-feeds-filters--filters-applied .social-feeds-filters__clear,
      .social-feeds-filters--filters-applied .social-feeds-filters__selected-filters,
      .social-feeds-filters--filters-applied .social-feeds-filters__selected-count {
        display: none; } }

.social-feeds-grid-item {
  background: rgba(0, 168, 253, 0.4);
  padding: 30px;
  opacity: 1;
  width: 100%;
  padding: 2em;
  margin-bottom: 20px; }
  @media only screen and (min-width: 750px) {
    .social-feeds-grid-item {
      width: calc(50% - 10px); } }
  @media only screen and (min-width: 1200px) {
    .social-feeds-grid-item {
      width: calc(33.5% - 20px); } }
  @media only screen and (min-width: 1400px) {
    .social-feeds-grid-item {
      width: calc(25% - 20px); } }
  .social-feeds-grid-item p {
    font-size: 16px; }
  .social-feeds-grid-item a {
    text-decoration: none; }
    .social-feeds-grid-item a:focus, .social-feeds-grid-item a:hover {
      text-decoration: underline; }
  .social-feeds-grid-item__title h5 {
    margin: 0;
    text-transform: capitalize;
    font-weight: 400 !important;
    font-size: 14px; }
  .social-feeds-grid-item__image {
    width: 100%; }
    .social-feeds-grid-item__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .social-feeds-grid-item__author {
    display: flex;
    margin-bottom: 15px; }
    .social-feeds-grid-item__author img {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 10px; }
    .social-feeds-grid-item__author h5 {
      font-weight: normal;
      font-style: italic; }
    .social-feeds-grid-item__author p {
      font-weight: bold;
      line-height: 1;
      margin-top: -4px; }
    .social-feeds-grid-item__author h5,
    .social-feeds-grid-item__author p {
      margin: 0;
      font-size: 14px; }
  .social-feeds-grid-item__footer {
    margin-top: 8px; }
    .social-feeds-grid-item__footer span {
      font-size: 12px;
      margin-right: 10px; }
      .social-feeds-grid-item__footer span:after {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 100;
        margin-left: 5px;
        font-size: 13px;
        transform: translate(0, 1px);
        display: inline-block; }
      .social-feeds-grid-item__footer span.likes:after {
        content: '\f004'; }
      .social-feeds-grid-item__footer span.comments:after {
        content: '\f075'; }
  .social-feeds-grid-item--transparent {
    opacity: 0; }

section {
  min-width: 0; }

.slick-slide:focus {
  outline: none; }

.slick-arrow {
  z-index: 10;
  width: 34px;
  height: 34px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  text-align: center;
  line-height: 1.9;
  top: 47%; }
  .slick-arrow:focus, .slick-arrow:hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.9); }
  .slick-arrow:hover:after, .slick-arrow:hover:before {
    opacity: 1 !important; }
  .slick-arrow.slick-next:before {
    content: '';
    border-style: solid;
    border-width: 4px 0 4px 9px;
    border-color: transparent transparent transparent #fff;
    font-size: unset;
    margin-left: 1px;
    display: inline-block;
    opacity: .75; }
  .slick-arrow.slick-prev:before {
    content: '';
    border-style: solid;
    border-width: 4px 9px 4px 0;
    border-color: transparent #fff transparent transparent;
    font-size: unset;
    margin-right: 1px;
    display: inline-block;
    opacity: .75; }
  .slick-arrow.slick-disabled {
    border-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
    box-shadow: unset; }

.slick-dots li {
  margin: 0;
  width: 12px; }
  .slick-dots li button {
    display: flex;
    padding: 0;
    box-shadow: none; }
    .slick-dots li button:before {
      color: #fff;
      opacity: 1;
      content: '';
      width: 8px;
      height: 8px;
      border: 1px solid #fff;
      border-radius: 100%;
      margin: auto;
      position: static; }
  .slick-dots li.slick-active button:before {
    background-color: #fff;
    opacity: 1; }

#fullpage {
  overflow: hidden; }
  #fullpage .fp-controlArrow {
    margin-top: -18px;
    width: 34px;
    height: 34px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    text-align: center;
    line-height: 1.9; }
    #fullpage .fp-controlArrow:focus, #fullpage .fp-controlArrow:hover {
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
      border-color: rgba(255, 255, 255, 0.9); }
    #fullpage .fp-controlArrow:hover:after, #fullpage .fp-controlArrow:hover:before {
      opacity: 1 !important; }
    #fullpage .fp-controlArrow.fp-next:before {
      content: '';
      border-style: solid;
      border-width: 4px 0 4px 9px;
      border-color: transparent transparent transparent #fff;
      font-size: unset;
      margin-left: 1px;
      display: inline-block;
      opacity: .75; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #fullpage .fp-controlArrow.fp-next {
        right: 80px; } }
    #fullpage .fp-controlArrow.fp-prev:before {
      content: '';
      border-style: solid;
      border-width: 4px 9px 4px 0;
      border-color: transparent #fff transparent transparent;
      font-size: unset;
      margin-right: 1px;
      display: inline-block;
      opacity: .75; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #fullpage .fp-controlArrow.fp-prev {
        left: 80px; } }

.scroller {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  margin: auto;
  width: 34px;
  height: 50px;
  display: none;
  transform: translateX(-50%);
  opacity: 0.8;
  cursor: pointer;
  filter: brightness(1.3); }
  @media (min-width: 1100px) and (min-height: 800px) {
    .scroller {
      display: block; } }

.mouse {
  width: 3px;
  padding: 2px 10px;
  height: 35px;
  border: 2px solid #0091FF;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
  margin: auto; }

.wheel {
  width: 3px;
  height: 3px;
  margin-top: 3px;
  border-radius: 100%;
  background-color: #0091FF;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite; }

@keyframes scroll {
  0% {
    opacity: 0; }
  10% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(20px);
    opacity: 0; } }

.form {
  max-width: 805px;
  margin: auto;
  width: 100%;
  padding-top: 35px;
  padding-left: 6%;
  padding-right: 6%; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    .form {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    .form {
      padding-left: 0;
      padding-right: 0; } }
  .form button,
  .form .button {
    margin-top: 1.5rem; }
  .form__subtitle {
    display: flex;
    justify-content: center;
    text-align: center; }
    @media only screen and (min-width: desktop) {
      .form__subtitle {
        padding: 8px 35px; } }

.message {
  background: rgba(0, 168, 253, 0.4);
  padding: 0;
  list-style: none;
  margin: 0 0 2rem;
  padding: 0.5rem 0.8rem;
  font-size: 0.83rem;
  position: relative; }
  .message:after {
    display: block;
    font-size: 16px;
    line-height: 22px;
    width: 22px;
    height: 22px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content: '\f0f3';
    position: absolute;
    right: 0;
    top: 6px; }
  .message.error:after {
    content: '\f071';
    right: 3px;
    top: 6px;
    font-size: 14px; }
  .message.success:after {
    content: '\f058';
    font-weight: 500;
    right: 3px; }

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.66); }

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.66); }

:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.66); }

:-moz-placeholder {
  color: rgba(255, 255, 255, 0.66); }

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s; }

@keyframes next {
  0% {
    transform: translate(0%, -50%); }
  50% {
    transform: translate(30%, -50%); }
  100% {
    transform: translate(0%, -50%); } }

button,
.button {
  border: 1px solid #fff;
  outline: none;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  line-height: 1.2;
  padding: 16px 22px 14px;
  font-size: 0.865rem;
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  cursor: pointer; }
  button:focus, button:hover,
  .button:focus,
  .button:hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.9); }
  button.search,
  .button.search {
    padding-right: 60px;
    position: relative; }
    button.search:after,
    .button.search:after {
      content: '';
      display: block;
      top: 50%;
      position: absolute;
      right: 18px;
      transform: translateY(-50%);
      background-repeat: no-repeat; }
    button.search:after,
    .button.search:after {
      width: 22px;
      height: 22px;
      background-image: url(../img/icons/search.svg); }
  button.filter,
  .button.filter {
    padding-right: 60px;
    position: relative; }
    button.filter:after,
    .button.filter:after {
      content: '';
      display: block;
      top: 50%;
      position: absolute;
      right: 18px;
      transform: translateY(-50%);
      background-repeat: no-repeat; }
    button.filter:after,
    .button.filter:after {
      width: 21px;
      height: 19px;
      background-image: url(../img/icons/filter.svg); }
  button.close, button.clear,
  .button.close,
  .button.clear {
    padding-right: 60px;
    position: relative; }
    button.close:after, button.clear:after,
    .button.close:after,
    .button.clear:after {
      content: '';
      display: block;
      top: 50%;
      position: absolute;
      right: 18px;
      transform: translateY(-50%);
      background-repeat: no-repeat; }
    button.close:after, button.clear:after,
    .button.close:after,
    .button.clear:after {
      font-size: 16px;
      line-height: 22px;
      width: 22px;
      height: 22px;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      content: '\f00d';
      font-weight: 100; }
  button.back,
  .button.back {
    padding-right: 60px;
    position: relative; }
    button.back:after,
    .button.back:after {
      content: '';
      display: block;
      top: 50%;
      position: absolute;
      right: 18px;
      transform: translateY(-50%);
      background-repeat: no-repeat; }
    button.back:after,
    .button.back:after {
      font-size: 16px;
      line-height: 22px;
      width: 22px;
      height: 22px;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      content: '\f053'; }
  button.next,
  .button.next {
    padding-right: 60px;
    position: relative; }
    button.next:after,
    .button.next:after {
      content: '';
      display: block;
      top: 50%;
      position: absolute;
      right: 18px;
      transform: translateY(-50%);
      background-repeat: no-repeat; }
    button.next:after,
    .button.next:after {
      font-size: 16px;
      line-height: 22px;
      width: 22px;
      height: 22px;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      content: '\f054';
      width: 15px; }
    button.next:hover:after, button.next:focus:after,
    .button.next:hover:after,
    .button.next:focus:after {
      animation-name: next;
      animation-duration: 1s;
      animation-iteration-count: infinite; }
  button.icon-only,
  .button.icon-only {
    font-size: 0;
    line-height: 0;
    width: 37px;
    height: 35px;
    padding: 0; }
    button.icon-only:after,
    .button.icon-only:after {
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%); }
    button.icon-only.search:after,
    .button.icon-only.search:after {
      margin-right: -1px; }
  button.icon-only.next:after,
  .button.icon-only.next:after {
    animation-name: unset;
    animation-duration: unset;
    animation-iteration-count: unset; }

input[type=text], input[type=email] {
  background: transparent;
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-style: normal;
  width: 100%;
  border: none;
  border-bottom: 1px solid #fff;
  font-size: 0.865rem;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.8; }
  input[type=text]:focus, input[type=email]:focus {
    outline: none; }

textarea {
  background: transparent;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-top-color: rgba(255, 255, 255, 0);
  border-left-color: rgba(255, 255, 255, 0);
  border-right-color: rgba(255, 255, 255, 0);
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0.35rem;
  margin-bottom: 2rem;
  height: 1rem;
  transition: all 0.2s;
  outline: none; }
  textarea:focus {
    border-color: white;
    min-height: 10rem; }

.is-filled textarea {
  border-color: white;
  min-height: 10rem; }

.is-filled .required:after {
  opacity: 0; }

label {
  text-transform: uppercase;
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-style: normal;
  padding: 18px 0 0 0;
  display: block;
  font-size: 0.865rem;
  margin-bottom: 0.45rem; }
  label.required {
    position: relative; }
    label.required:after {
      font-size: 16px;
      line-height: 22px;
      width: 22px;
      height: 22px;
      font-family: 'Font Awesome 5 Pro';
      font-weight: 400;
      content: '\f069';
      position: absolute;
      right: 0;
      font-size: 4px;
      width: 4px;
      top: 14px;
      transition: all 0.2s; }

small .fas {
  font-size: 4px;
  height: 4px;
  transform: translateY(-6px);
  margin-right: 2px; }

.form-field {
  display: block;
  margin-bottom: 1rem;
  margin-bottom: 2rem; }
  .form-field .control {
    overflow: hidden;
    position: relative;
    width: 100%; }
    .form-field .control:after {
      content: '';
      display: block;
      transition: all 0.2s;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: #fff;
      transform: scaleX(0);
      transform-origin: left; }
    .form-field .control label {
      left: 0;
      margin: 0;
      position: absolute;
      top: 0;
      transition: all 0.2s;
      width: 100%; }
    .form-field .control input {
      appearance: none;
      background: transparent;
      border: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.8);
      display: block;
      margin-top: 20px;
      outline: 0;
      padding: 0 0 0 0;
      width: 100%; }
  .form-field.is-active .control:after {
    transform: scaleX(1); }
  .form-field.is-active label {
    font-size: 0.8rem;
    transform: translateY(-20px);
    opacity: 0.8; }
  .form-field.is-filled label {
    font-size: 0.8rem;
    transform: translateY(-20px);
    opacity: 0.8; }

#contact.open {
  display: flex;
  flex-direction: column; }
  #contact.open .dot-grid {
    z-index: -1; }

#contact {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  overflow-y: auto;
  overflow-x: hidden; }
  @media only screen and (max-width: 1099px), (max-height: 649px) {
    #contact {
      padding-bottom: 3.5rem; } }
  @media only screen and (max-height: 800px) {
    #contact {
      padding: 80px 0; } }
  @media (max-height: 799px), (max-width: 1099px) {
    #contact {
      padding-top: 100px; } }
  #contact h2 {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.25;
    padding-left: 6%;
    padding-right: 6%;
    margin: 3rem 0;
    line-height: 1.1; }
    @media only screen and (min-width: 1300px) {
      #contact h2 {
        font-size: 2rem; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #contact h2 {
        padding-left: 5%;
        padding-right: 5%; } }
    #contact h2.form__subtitle-tag {
      font-size: 1.5rem;
      line-height: 1;
      font-family: "proxima-nova", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: bold;
      line-height: 1.2;
      margin-left: auto;
      margin-right: auto;
      max-width: 600px; }

@media only screen and (max-height: 1000px) {
  .grecaptcha-badge {
    bottom: -200px !important; } }

.tiles {
  display: grid;
  grid-gap: 2rem; }
  @media only screen and (min-width: 768px) {
    .tiles {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (min-width: 1024px) {
    .tiles {
      grid-template-columns: repeat(1, 1fr); } }

.tile {
  width: 100%;
  padding-bottom: 100%;
  background: rgba(0, 0, 0, 0.2);
  height: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column; }
  .tile h4 {
    font-size: 1.5rem;
    line-height: 1.25;
    line-height: 0.9;
    margin-top: 0; }
    @media only screen and (min-width: 1300px) {
      .tile h4 {
        font-size: 2rem; } }
  .tile p {
    font-size: 0.83rem;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
  .tile.blue {
    background: rgba(0, 168, 253, 0.4); }
  .tile.purple {
    background: rgba(185, 130, 252, 0.4); }
  .tile.contact p {
    margin-bottom: 3rem; }
  .tile .wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 2rem; }
    .tile .wrap .inner {
      margin: auto;
      width: 100%; }
  @media only screen and (min-width: 1024px) and (max-width: 1500px) {
    .tile .button {
      padding-right: 40px; } }
  .tile .slick-next {
    right: -10px; }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      .tile .slick-next {
        right: 0; } }
  .tile .slick-prev {
    left: -10px; }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      .tile .slick-prev {
        left: 0; } }
  .tile .text-slider h2 {
    line-height: 0.9;
    font-size: 10vw;
    margin: 0 0 2rem; }
    .tile .text-slider h2:last-child {
      margin-bottom: 0; }
    @media only screen and (min-width: 768px) {
      .tile .text-slider h2 {
        font-size: 4.5vw; } }
    @media only screen and (min-width: 1024px) {
      .tile .text-slider h2 {
        font-size: 2.5vw; } }
  .tile .services-slider-inner h4 {
    margin-bottom: 0;
    line-height: 1.1; }
    @media (min-width: 1150px) and (max-height: 900px) and (max-width: 1299px), (min-width: 1400px) and (max-height: 900px) {
      .tile .services-slider-inner h4.services-slider-heading {
        display: none; } }
  @media only screen and (min-width: 1500px) and (min-height: 850px) {
    .tile .services-slider-inner .button {
      margin-top: 1rem; } }
  .tile .services-slider img {
    height: 60px;
    margin: 1rem 0; }
  .tile .services-slider h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem; }
  .tile .slick-dots {
    opacity: 0.5;
    bottom: -40px; }

.contact-us-tile h4 {
  margin-bottom: 0; }

.contact-us-tile__buttons button {
  border: none;
  padding: 0.5rem; }
  .contact-us-tile__buttons button:hover {
    cursor: pointer; }
  .contact-us-tile__buttons button:first-child {
    position: relative; }
    .contact-us-tile__buttons button:first-child:after {
      content: "";
      height: 20px;
      width: 2px;
      background-color: white;
      position: absolute;
      right: -3px;
      top: 6px; }

.fp-bg {
  background-size: cover;
  background-position: center center; }

#slide-1 .fp-bg {
  background-image: url(../img/hero/hero1.jpg);
  background-position: center top; }

#slide-2 .fp-bg {
  background-image: url(../img/hero/hero2.jpg);
  background-position: center bottom; }

#slide-3 .fp-bg {
  background-image: url(../img/hero/hero3.jpg); }

#slide-4 .fp-bg {
  background-image: url(../img/hero/hero4.jpg); }

#slide-5 .fp-bg {
  background-image: url(../img/hero/group1.jpg); }

#slide-6 .fp-bg {
  background-image: url(../img/hero/group2.jpg); }

#image-1 .fp-bg {
  background-image: url(../img/hero/smix1.jpg); }

#image-2 .fp-bg {
  background-image: url(../img/hero/talent1.jpg); }

#image-3 .fp-bg {
  background-image: url(../img/hero/talent2.jpg); }

#hero-slider .slide {
  text-align: center; }
  #hero-slider .slide h2 {
    font-size: 2rem;
    padding: 0 2rem;
    line-height: 1;
    position: relative;
    z-index: 1; }
    @media (min-width: 768px) and (min-height: 768px) {
      #hero-slider .slide h2 {
        font-size: 4rem;
        max-width: 1000px;
        margin: auto; } }
  #hero-slider .slide.spotlight .fp-bg {
    background-image: url(../img/bg/spotlight.jpg);
    background-color: #163091;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media (min-width: 700px) and (max-height: 400px) {
      #hero-slider .slide.spotlight .fp-bg {
        background-size: 650px;
        background-position: center 0; } }
    @media (min-width: 1024px) and (min-height: 768px) {
      #hero-slider .slide.spotlight .fp-bg {
        background-position: center 0;
        background-size: 1250px; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #hero-slider .slide.spotlight .fp-bg {
        background-size: cover; } }
  #hero-slider .slide.spotlight .featured {
    max-width: 420px;
    margin-bottom: -50px;
    margin-top: 50px;
    opacity: 0.8;
    width: 100%; }
    @media only screen and (max-height: 567px) {
      #hero-slider .slide.spotlight .featured {
        width: 200px; } }
    @media only screen and (min-width: 700px) {
      #hero-slider .slide.spotlight .featured {
        width: 250px; } }
    @media (min-width: 414px) and (min-height: 630px) {
      #hero-slider .slide.spotlight .featured {
        width: 420px;
        max-width: 100%; } }
    @media (min-width: 768px) and (min-height: 1024px) {
      #hero-slider .slide.spotlight .featured {
        width: 600px; } }
    @media (min-width: 1024px) and (min-height: 768px) {
      #hero-slider .slide.spotlight .featured {
        width: 500px; } }
    @media (min-width: 1200px) and (min-height: 870px) {
      #hero-slider .slide.spotlight .featured {
        width: 600px; } }
    @media (min-width: 1300px) and (min-height: 1000px) {
      #hero-slider .slide.spotlight .featured {
        width: 700px; } }
    @media only screen and (min-width: 700px) {
      #hero-slider .slide.spotlight .featured.family {
        width: 600px;
        max-width: 100%; } }
    @media (min-width: 414px) and (min-height: 630px) {
      #hero-slider .slide.spotlight .featured.family {
        width: 700px;
        max-width: 100%; } }
    @media (min-width: 1024px) and (min-height: 768px) {
      #hero-slider .slide.spotlight .featured.family {
        width: 800px; } }
    @media (min-width: 1200px) and (min-height: 870px) {
      #hero-slider .slide.spotlight .featured.family {
        width: 1250px; } }
  #hero-slider .slide.spotlight .smoke {
    position: absolute;
    max-width: unset;
    width: 560px;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    margin-bottom: -135px; }
    @media (max-height: 567px), (max-width: 319px) {
      #hero-slider .slide.spotlight .smoke {
        display: none; } }
    @media only screen and (min-width: 700px) {
      #hero-slider .slide.spotlight .smoke {
        width: 420px; } }
    @media (min-width: 414px) and (min-height: 630px) {
      #hero-slider .slide.spotlight .smoke {
        width: 560px; } }
    @media (min-width: 768px) and (min-height: 1024px) {
      #hero-slider .slide.spotlight .smoke {
        width: 750px;
        margin-bottom: -155px; } }
    @media (min-width: 1024px) and (min-height: 768px) {
      #hero-slider .slide.spotlight .smoke {
        width: 750px;
        margin-bottom: -155px; } }
    @media (min-width: 1200px) and (min-height: 870px) {
      #hero-slider .slide.spotlight .smoke {
        width: 900px;
        margin-bottom: -185px; } }
    @media (min-width: 1300px) and (min-height: 1000px) {
      #hero-slider .slide.spotlight .smoke {
        margin-bottom: -235px; } }

.mousemove-container {
  overflow: hidden;
  height: 100vh;
  position: absolute;
  z-index: -1; }

#image-slider {
  position: relative; }
  #image-slider h1 {
    font-size: 2rem;
    line-height: 1;
    margin: auto;
    max-width: 1000px;
    text-align: center;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media only screen and (min-width: 1300px) {
      #image-slider h1 {
        font-size: 3.8rem;
        line-height: 0.9; } }

.toggle {
  display: inline-block;
  width: 220px; }
  .toggle:hover {
    cursor: pointer; }
  .toggle__container {
    display: flex;
    justify-content: center; }
    .toggle__container:hover {
      cursor: pointer; }
      .toggle__container:hover .toggle__switch {
        background: rgba(26, 117, 219, 0.2); }
  .toggle__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: 1px solid white;
    border-radius: 1px;
    width: 100%;
    height: 45px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s; }
    .toggle__switch:before, .toggle__switch:after {
      content: ""; }
    .toggle__switch:before {
      display: block;
      background: #1a75db;
      border-radius: 1px;
      width: calc(50% - 5px);
      height: 33px;
      position: absolute;
      top: 5px;
      left: 5px;
      transition: left 0.25s; }
    .toggle:hover .toggle__switch:before {
      background: #156ccf; }
    .toggle__checkbox:checked + .toggle__switch:before {
      left: 50%; }
  .toggle__checkbox {
    position: absolute;
    visibility: hidden; }
  .toggle__label {
    position: relative;
    width: 50%;
    text-align: center; }
  .toggle--is-hidden {
    display: none; }

.footer .container {
  padding-left: 6%;
  padding-right: 6%;
  grid-gap: 2rem;
  display: flex;
  justify-content: center;
  font-size: 0.83rem; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    .footer .container {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (min-width: 1100px) {
    .footer .container {
      display: flex; } }
  @media only screen and (max-width: 1099px), (max-height: 649px) {
    .footer .container {
      padding: 0.8rem 1rem; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    .footer .container {
      padding: 1rem 1rem; } }

.footer .menu {
  display: flex;
  list-style: none;
  flex-direction: column;
  padding-inline-start: 0;
  align-items: center; }
  @media only screen and (max-width: 1099px), (max-height: 649px) {
    .footer .menu {
      font-size: 11px; } }
  .footer .menu a {
    text-decoration: none; }
  .footer .menu li {
    position: relative; }

.footer .copyright-mobile {
  display: flex; }

.footer .copyright-desktop {
  display: none; }

.spinner {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  margin: auto; }
  .spinner.spinner-circle {
    border: 3px rgba(255, 255, 255, 0.25) solid;
    border-top: 3px white solid;
    border-radius: 50%;
    -webkit-animation: spCircRot 0.6s infinite linear;
    animation: spCircRot 0.6s infinite linear; }

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg); } }

@keyframes spCircRot {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

#characterSelect {
  text-align: center; }
  #characterSelect .container {
    padding-left: 6%;
    padding-right: 6%;
    grid-gap: 2rem; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #characterSelect .container {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (min-width: 1100px) {
      #characterSelect .container {
        display: flex; } }
    #characterSelect .container .side {
      flex: 1;
      display: flex;
      flex-direction: column; }
      @media (min-width: 1100px) and (min-height: 800px) {
        #characterSelect .container .side {
          opacity: 1; } }
      #characterSelect .container .side.left {
        display: none; }
        @media only screen and (min-width: 1024px) {
          #characterSelect .container .side.left {
            display: flex; } }
    #characterSelect .container .center {
      display: flex; }
      @media only screen and (min-width: 1024px) {
        #characterSelect .container .center {
          flex: 1.4; } }
      @media only screen and (min-width: 1300px) {
        #characterSelect .container .center {
          flex: 2; } }
      @media only screen and (min-height: 1140px) {
        #characterSelect .container .center {
          flex: 1.8; } }
      #characterSelect .container .center h2 {
        font-size: 2rem;
        line-height: 1;
        margin-top: 3rem; }
        @media only screen and (min-width: 1300px) {
          #characterSelect .container .center h2 {
            font-size: 3.8rem;
            line-height: 0.9; } }
        @media only screen and (min-width: 1024px) {
          #characterSelect .container .center h2 {
            margin-top: inherit; } }
      #characterSelect .container .center img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 10%) scale(1.5);
        z-index: -1;
        opacity: 0.8; }
        @media only screen and (min-width: 768px) {
          #characterSelect .container .center img {
            transform: translate(-50%, -13%) scale(1.2); } }
        @media only screen and (min-width: 1024px) {
          #characterSelect .container .center img {
            transform: translate(-50%, -33%) scale(0.8); } }
        @media only screen and (min-width: 1100px) and (min-height: 650px) {
          #characterSelect .container .center img {
            transform: translate(-50%, -13%); } }
      #characterSelect .container .center .button {
        margin-bottom: 3rem; }
        @media only screen and (min-width: 1024px) {
          #characterSelect .container .center .button {
            margin-bottom: inherit; } }
    #characterSelect .container .content,
    #characterSelect .container .grid {
      margin: auto; }
    #characterSelect .container .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 5px;
      max-width: 500px; }
      @media only screen and (min-height: 700px) {
        #characterSelect .container .grid {
          grid-template-columns: repeat(4, 1fr); } }
      @media only screen and (min-height: 800px) {
        #characterSelect .container .grid {
          grid-template-columns: repeat(3, 1fr); } }
      @media only screen and (min-width: 768px) {
        #characterSelect .container .grid {
          grid-template-columns: repeat(4, 1fr); } }
      @media only screen and (min-width: 1024px) {
        #characterSelect .container .grid {
          grid-template-columns: repeat(4, 75px); } }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #characterSelect .container .grid {
          grid-gap: 10px;
          max-width: unset; } }
      @media only screen and (min-width: 1600px) {
        #characterSelect .container .grid {
          grid-gap: 18px;
          grid-template-columns: repeat(4, 95px); } }
      @media only screen and (min-width: 2000px) {
        #characterSelect .container .grid {
          grid-gap: 18px;
          grid-template-columns: repeat(4, 115px); } }
      #characterSelect .container .grid .item {
        position: relative;
        display: none;
        opacity: 0.8;
        border: 1px solid #fff; }
        @media only screen and (max-width: 1023px) {
          #characterSelect .container .grid .item:nth-child(-n+12) {
            display: block; } }
        @media only screen and (min-width: 1024px) {
          #characterSelect .container .grid .item:nth-child(-n+12) {
            display: block; } }
        @media (min-width: 1024px) and (min-height: 600px) {
          #characterSelect .container .grid .item:nth-child(-n+16) {
            display: block; } }
        @media (min-width: 1024px) and (min-height: 800px) {
          #characterSelect .container .grid .item:nth-child(-n+20) {
            display: block; } }
        #characterSelect .container .grid .item span.question-mark {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: #0E67CB; }
          #characterSelect .container .grid .item span.question-mark:after {
            content: '\003F';
            display: block;
            color: #fff;
            font-family: "proxima-nova", sans-serif;
            font-weight: 900;
            font-style: normal;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -45%);
            font-size: 15vw;
            opacity: 0.5; }
            @media only screen and (min-width: 700px) {
              #characterSelect .container .grid .item span.question-mark:after {
                font-size: 10vw; } }
            @media only screen and (min-width: 1024px) {
              #characterSelect .container .grid .item span.question-mark:after {
                font-size: 3vw; } }
  #characterSelect .fp-bg {
    background-image: url(../img/bg/character-select.jpg);
    background-position: center center; }

#clients {
  text-align: center; }
  #clients .container {
    padding-left: 6%;
    padding-right: 6%;
    grid-gap: 2rem;
    margin: 2rem auto;
    max-width: 1560px; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #clients .container {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (min-width: 1100px) {
      #clients .container {
        display: flex; } }
    @media only screen and (min-width: 768px) {
      #clients .container {
        margin: 4rem auto 2rem; } }
    @media (min-height: 1100px) {
      #clients .container {
        max-width: 1850px; } }
    @media only screen and (min-height: 1140px) {
      #clients .container {
        max-width: 2100px; } }
    @media (min-width: 1150px) and (max-height: 900px) and (max-width: 1299px), (min-width: 1400px) and (max-height: 900px) {
      #clients .container .tile {
        padding-bottom: 85%; } }
    #clients .container .tile p {
      margin-bottom: 1rem; }
    #clients .container .side {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    #clients .container .center {
      display: flex;
      padding: 2rem 0;
      min-width: 0; }
      @media only screen and (min-width: 1024px) {
        #clients .container .center {
          flex: 1.4; } }
      @media only screen and (min-width: 1300px) {
        #clients .container .center {
          flex: 2; } }
      @media only screen and (min-height: 1140px) {
        #clients .container .center {
          flex: 1.8; } }
      @media only screen and (max-width: 1023px) {
        #clients .container .center {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw; } }
      @media only screen and (min-width: 1024px) {
        #clients .container .center {
          padding-top: 0;
          padding-bottom: 0; } }
      #clients .container .center h2 {
        font-size: 2rem;
        line-height: 1;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto; }
        @media only screen and (min-width: 1300px) {
          #clients .container .center h2 {
            font-size: 3.8rem;
            line-height: 0.9; } }
        @media only screen and (min-width: 1300px) {
          #clients .container .center h2 {
            max-width: 560px; } }
    #clients .container .content {
      margin: auto;
      min-width: 0; }
      @media only screen and (min-width: 1500px) and (min-height: 850px) {
        #clients .container .content {
          padding: 0 2rem; } }
      #clients .container .content .button {
        margin: 2rem 0; }
  @media only screen and (min-width: 1024px) {
    #clients .center {
      padding: 0 2rem; } }
  @media only screen and (max-width: 1100px) {
    #clients #client-logos {
      -webkit-mask-image: linear-gradient(to left, transparent 0%, black 35%, black 65%, transparent 100%);
      -webkit-mask-mode: luminance; } }
  @media only screen and (min-width: 1500px) and (min-height: 850px) {
    #clients #client-logos {
      margin: 5rem 0 3rem; } }
  #clients #client-logos .slick-slide {
    display: inline-block;
    vertical-align: middle;
    float: none; }
  #clients #client-logos img {
    margin: auto;
    padding: 10px;
    max-width: 130px;
    width: 100%; }
  @media only screen and (min-width: 1100px) {
    #clients #client-logos {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 2rem;
      column-gap: 3rem; }
      #clients #client-logos div {
        display: flex; }
        #clients #client-logos div img {
          padding: 0; } }
  @media only screen and (max-width: 370px) {
    #clients .contact-us-tile__buttons {
      display: none; } }
  @media only screen and (min-width: 1024px) and (max-width: 1500px) {
    #clients .contact-us-tile__buttons {
      display: none; } }
  @media only screen and (max-width: 370px) {
    #clients .services-slider-inner > h4 {
      display: none; } }
  @media only screen and (min-width: 1024px) and (max-width: 1500px) {
    #clients .services-slider-inner > .button {
      display: none; } }
  @media only screen and (min-width: 1024px) and (max-width: 1500px) {
    #clients .contact-us-tile__text {
      margin: 10px 0 15px; } }

#social .container {
  padding-left: 6%;
  padding-right: 6%;
  grid-gap: 2rem; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #social .container {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (min-width: 1100px) {
    #social .container {
      display: flex; } }

#characterSelect .slide-fade-leave {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
  opacity: 0; }

#characterSelect .slide-fade-active {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
  opacity: 1; }

@media only screen and (min-width: 1100px) and (min-height: 650px) {
  body.character-select {
    overflow: hidden; } }

body.character-select #header {
  position: relative;
  z-index: 10;
  background: transparent !important; }
  @media only screen and (min-width: 768px) {
    body.character-select #header {
      height: 100px; } }
  @media only screen and (min-height: 1140px) {
    body.character-select #header {
      height: 200px; } }

body.character-select #footer {
  display: none; }

body.character-select main {
  position: relative; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    body.character-select main {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column; } }
  @media only screen and (min-height: 1140px) {
    body.character-select main {
      height: calc(100% - 200px); } }

body.character-select #dot-grid {
  z-index: -1; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    body.character-select #dot-grid {
      position: fixed; } }

@media only screen and (min-width: 768px) {
  body.talent-services #header {
    height: 100px; } }

@media only screen and (min-height: 1140px) {
  body.talent-services #header {
    height: 200px; } }

#selected-character-details,
#selected-character-details-static {
  display: none;
  position: relative; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #selected-character-details,
    #selected-character-details-static {
      display: block;
      flex: 1; } }
  #selected-character-details article,
  #selected-character-details-static article {
    height: calc(100vh - 390px);
    display: flex;
    position: absolute;
    left: 5%;
    right: 5%;
    display: none;
    max-width: 1800px;
    margin: 0 auto;
    opacity: 1;
    transform: translateX(0%); }
    @media only screen and (max-height: 800px) {
      #selected-character-details article,
      #selected-character-details-static article {
        height: calc(100vh - 355px); } }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      #selected-character-details article,
      #selected-character-details-static article {
        height: calc(100vh - 460px); } }
    @media only screen and (min-height: 1100px) {
      #selected-character-details article,
      #selected-character-details-static article {
        top: 50%;
        transform: translate(0, -50%);
        height: unset;
        max-height: 600px; } }
    #selected-character-details article.selected,
    #selected-character-details-static article.selected {
      display: flex; }
    #selected-character-details article section,
    #selected-character-details-static article section {
      flex: 1.5;
      transform: translateX(0px); }
      @media only screen and (min-width: 1500px) {
        #selected-character-details article section,
        #selected-character-details-static article section {
          flex: 1; } }
      #selected-character-details article section.image,
      #selected-character-details-static article section.image {
        flex: 2;
        display: flex;
        opacity: 1;
        position: relative; }
        @media only screen and (min-width: 1600px) {
          #selected-character-details article section.image,
          #selected-character-details-static article section.image {
            flex: 2.8; } }
        #selected-character-details article section.image .featured,
        #selected-character-details-static article section.image .featured {
          opacity: 0.9;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -47%);
          transform-origin: center;
          z-index: -1;
          max-width: 60vw;
          max-height: 60vh; }
          #selected-character-details article section.image .featured:hover,
          #selected-character-details-static article section.image .featured:hover {
            filter: brightness(1.05) drop-shadow(0 0 15px rgba(118, 200, 241, 0.3)); }
          @media only screen and (max-height: 800px) {
            #selected-character-details article section.image .featured,
            #selected-character-details-static article section.image .featured {
              transform: translate(-50%, -42%); } }
          @media (min-width: 1500px) and (min-height: 1000px) {
            #selected-character-details article section.image .featured,
            #selected-character-details-static article section.image .featured {
              transform: translate(-50%, -42%);
              max-height: 740px;
              max-width: 100%; } }
          @media only screen and (min-height: 1100px) {
            #selected-character-details article section.image .featured,
            #selected-character-details-static article section.image .featured {
              transform: translate(-50%, -50%); } }
        #selected-character-details article section.image .smoke,
        #selected-character-details-static article section.image .smoke {
          opacity: 0;
          display: none;
          z-index: 1;
          opacity: 0.8;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0%) scale(1.5);
          margin-bottom: -70px;
          pointer-events: none; }
          @media only screen and (min-width: 1100px) and (min-height: 650px) {
            #selected-character-details article section.image .smoke,
            #selected-character-details-static article section.image .smoke {
              display: block; } }
          @media only screen and (min-width: 1500px) {
            #selected-character-details article section.image .smoke,
            #selected-character-details-static article section.image .smoke {
              transform: translate(-50%, 0%) scale(1); } }
          @media only screen and (min-width: 1600px) {
            #selected-character-details article section.image .smoke,
            #selected-character-details-static article section.image .smoke {
              margin-bottom: -140px;
              transform: translate(-50%, 0%) scale(0.8); } }
          @media (min-width: 1500px) and (min-height: 1000px) {
            #selected-character-details article section.image .smoke,
            #selected-character-details-static article section.image .smoke {
              margin-bottom: -230px;
              transform: translate(-50%, 0%) scale(1); } }
          @media only screen and (min-height: 1140px) {
            #selected-character-details article section.image .smoke,
            #selected-character-details-static article section.image .smoke {
              margin-bottom: -140px; } }
      #selected-character-details article section.side,
      #selected-character-details-static article section.side {
        position: relative;
        z-index: 10;
        display: grid;
        grid-gap: 2rem;
        opacity: 1; }
        @media only screen and (max-height: 800px) {
          #selected-character-details article section.side,
          #selected-character-details-static article section.side {
            grid-gap: 1rem; } }
        #selected-character-details article section.side.left,
        #selected-character-details-static article section.side.left {
          grid-template-rows: 1fr; }
        #selected-character-details article section.side.right,
        #selected-character-details-static article section.side.right {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px; }
          @media only screen and (min-height: 850px) {
            #selected-character-details article section.side.right,
            #selected-character-details-static article section.side.right {
              grid-template-rows: 1fr; } }
          @media only screen and (min-height: 925px) {
            #selected-character-details article section.side.right,
            #selected-character-details-static article section.side.right {
              grid-template-columns: repeat(1, 1fr); } }
          @media only screen and (min-height: 1100px) {
            #selected-character-details article section.side.right,
            #selected-character-details-static article section.side.right {
              grid-gap: 1.5rem; } }
      #selected-character-details article section .buttons,
      #selected-character-details-static article section .buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.2rem; }
        #selected-character-details article section .buttons .button,
        #selected-character-details article section .buttons button,
        #selected-character-details-static article section .buttons .button,
        #selected-character-details-static article section .buttons button {
          white-space: nowrap;
          overflow: hidden;
          padding-left: 10px;
          padding-right: 10px;
          text-align: center; }
          @media only screen and (min-width: 1750px) {
            #selected-character-details article section .buttons .button,
            #selected-character-details article section .buttons button,
            #selected-character-details-static article section .buttons .button,
            #selected-character-details-static article section .buttons button {
              text-align: left;
              padding-left: 20px;
              padding-right: 20px; } }
          #selected-character-details article section .buttons .button:after,
          #selected-character-details article section .buttons button:after,
          #selected-character-details-static article section .buttons .button:after,
          #selected-character-details-static article section .buttons button:after {
            display: none; }
            @media only screen and (min-width: 1750px) {
              #selected-character-details article section .buttons .button:after,
              #selected-character-details article section .buttons button:after,
              #selected-character-details-static article section .buttons .button:after,
              #selected-character-details-static article section .buttons button:after {
                display: block; } }
  #selected-character-details .panel,
  #selected-character-details-static .panel {
    text-align: center;
    padding: 2rem 2.5rem 4rem;
    min-width: 0;
    margin: 6px; }
    #selected-character-details .panel.blue,
    #selected-character-details-static .panel.blue {
      background: rgba(0, 168, 253, 0.4);
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 2rem 0.5rem 2rem 1.5rem; }
      @media only screen and (max-height: 800px) {
        #selected-character-details .panel.blue,
        #selected-character-details-static .panel.blue {
          padding: 1rem 0.5rem 1.5rem 1.5rem; } }
      #selected-character-details .panel.blue h2,
      #selected-character-details-static .panel.blue h2 {
        margin: 1.2rem 0 1rem;
        font-size: 1.8rem;
        padding-right: 15px; }
        @media only screen and (max-height: 800px) {
          #selected-character-details .panel.blue h2,
          #selected-character-details-static .panel.blue h2 {
            margin: 0.6rem 0 1rem;
            font-size: 1.5rem; } }
      #selected-character-details .panel.blue .bio,
      #selected-character-details-static .panel.blue .bio {
        height: calc(100vh - 645px);
        overflow: hidden;
        -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
        -webkit-mask-mode: luminance; }
        @media only screen and (max-height: 800px) {
          #selected-character-details .panel.blue .bio,
          #selected-character-details-static .panel.blue .bio {
            height: calc(100vh - 550px); } }
        #selected-character-details .panel.blue .bio p:last-child,
        #selected-character-details-static .panel.blue .bio p:last-child {
          margin-bottom: 2rem; }
          @media only screen and (max-height: 800px) {
            #selected-character-details .panel.blue .bio p:last-child,
            #selected-character-details-static .panel.blue .bio p:last-child {
              margin-bottom: 1rem; } }
        #selected-character-details .panel.blue .bio p:first-child,
        #selected-character-details-static .panel.blue .bio p:first-child {
          margin-top: 0; }
        @media only screen and (min-width: 1500px) and (min-height: 850px) {
          #selected-character-details .panel.blue .bio,
          #selected-character-details-static .panel.blue .bio {
            height: calc(100vh - 679px); } }
        @media only screen and (min-height: 1100px) {
          #selected-character-details .panel.blue .bio,
          #selected-character-details-static .panel.blue .bio {
            height: unset;
            max-height: 334px; } }
        #selected-character-details .panel.blue .bio p,
        #selected-character-details-static .panel.blue .bio p {
          font-size: 0.83rem;
          line-height: 1.8; }
          @media only screen and (max-height: 800px) {
            #selected-character-details .panel.blue .bio p,
            #selected-character-details-static .panel.blue .bio p {
              line-height: 1.6;
              font-size: 0.8rem; } }
        #selected-character-details .panel.blue .bio .mCSB_container,
        #selected-character-details-static .panel.blue .bio .mCSB_container {
          margin-right: 15px; }
        #selected-character-details .panel.blue .bio .mCustomScrollBox .mCSB_scrollTools,
        #selected-character-details-static .panel.blue .bio .mCustomScrollBox .mCSB_scrollTools {
          opacity: 0; }
        #selected-character-details .panel.blue .bio .mCustomScrollBox:hover .mCSB_scrollTools,
        #selected-character-details-static .panel.blue .bio .mCustomScrollBox:hover .mCSB_scrollTools {
          opacity: 0.75; }
    #selected-character-details .panel.profile,
    #selected-character-details-static .panel.profile {
      padding: 2rem; }
      #selected-character-details .panel.profile .bio,
      #selected-character-details-static .panel.profile .bio {
        padding-bottom: 0;
        height: calc(100vh - 420px); }
        @media only screen and (min-width: 1500px) and (min-height: 850px) {
          #selected-character-details .panel.profile .bio,
          #selected-character-details-static .panel.profile .bio {
            height: calc(100vh - 678px); } }
    #selected-character-details .panel.talent-services,
    #selected-character-details-static .panel.talent-services {
      padding: 2rem 0rem 2rem 1.5rem !important; }
      @media only screen and (max-width: 1099px), (max-height: 649px) {
        #selected-character-details .panel.talent-services,
        #selected-character-details-static .panel.talent-services {
          padding: 2rem !important; } }
      #selected-character-details .panel.talent-services .mCSB_container,
      #selected-character-details-static .panel.talent-services .mCSB_container {
        margin-right: 25px !important; }
    #selected-character-details .panel.purple,
    #selected-character-details-static .panel.purple {
      background: rgba(185, 130, 252, 0.4);
      position: relative;
      padding: 1rem 0;
      display: flex;
      flex-direction: column; }
      #selected-character-details .panel.purple:first-child,
      #selected-character-details-static .panel.purple:first-child {
        grid-column: span 2; }
        @media only screen and (min-height: 925px) {
          #selected-character-details .panel.purple:first-child,
          #selected-character-details-static .panel.purple:first-child {
            grid-column: span 1; } }
      @media only screen and (min-height: 900px) {
        #selected-character-details .panel.purple,
        #selected-character-details-static .panel.purple {
          padding: 1.5rem 0; } }
    #selected-character-details .panel.slick-slider,
    #selected-character-details-static .panel.slick-slider {
      padding-left: 0;
      padding-right: 0; }
      #selected-character-details .panel.slick-slider .slick-slide,
      #selected-character-details-static .panel.slick-slider .slick-slide {
        padding: 0 1rem; }
    #selected-character-details .panel .slick-dots,
    #selected-character-details-static .panel .slick-dots {
      bottom: -30px; }
      @media only screen and (min-height: 1100px) {
        #selected-character-details .panel .slick-dots,
        #selected-character-details-static .panel .slick-dots {
          bottom: -25px; } }
    #selected-character-details .panel.slick-dotted.slick-slider,
    #selected-character-details-static .panel.slick-dotted.slick-slider {
      margin-bottom: 0;
      padding-bottom: 2.3rem;
      min-width: 0; }
    #selected-character-details .panel.games,
    #selected-character-details-static .panel.games {
      display: flex;
      padding: 1rem 0; }
      #selected-character-details .panel.games figcaption,
      #selected-character-details-static .panel.games figcaption {
        display: none; }
      #selected-character-details .panel.games img,
      #selected-character-details-static .panel.games img {
        width: auto;
        height: 70px; }
    #selected-character-details .panel .abilities-slide,
    #selected-character-details-static .panel .abilities-slide {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 20px;
      column-gap: 1rem;
      padding: 0 1.5rem; }
      @media only screen and (max-height: 924px) {
        #selected-character-details .panel .abilities-slide,
        #selected-character-details-static .panel .abilities-slide {
          row-gap: 30px; } }
    #selected-character-details .panel .abilities-carousel,
    #selected-character-details-static .panel .abilities-carousel {
      display: block;
      margin-top: auto;
      margin-bottom: auto !important; }
    #selected-character-details .panel .carousel,
    #selected-character-details-static .panel .carousel {
      margin-top: auto;
      margin-bottom: auto !important; }
    #selected-character-details .panel .corner-decoration,
    #selected-character-details-static .panel .corner-decoration {
      position: absolute;
      background-image: url(../img/effects/corner-decoration.svg);
      background-size: cover;
      width: 6px;
      height: 6px; }
      #selected-character-details .panel .corner-decoration.top-left,
      #selected-character-details-static .panel .corner-decoration.top-left {
        top: -6px;
        left: -6px; }
      #selected-character-details .panel .corner-decoration.top-right,
      #selected-character-details-static .panel .corner-decoration.top-right {
        top: -6px;
        right: -6px;
        transform: rotate(90deg); }
      #selected-character-details .panel .corner-decoration.bottom-right,
      #selected-character-details-static .panel .corner-decoration.bottom-right {
        bottom: -6px;
        right: -6px;
        transform: rotate(180deg); }
      #selected-character-details .panel .corner-decoration.bottom-left,
      #selected-character-details-static .panel .corner-decoration.bottom-left {
        bottom: -6px;
        left: -6px;
        transform: rotate(-90deg); }
    #selected-character-details .panel figure,
    #selected-character-details-static .panel figure {
      margin: 0; }
      #selected-character-details .panel figure img,
      #selected-character-details-static .panel figure img {
        width: 50px;
        height: 50px; }
      #selected-character-details .panel figure figcaption,
      #selected-character-details-static .panel figure figcaption {
        text-transform: uppercase;
        font-family: "proxima-nova", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 0.83rem;
        line-height: 1.1;
        margin: 15px auto 0;
        max-width: 120px; }

#selected-character-details-static {
  display: block; }
  #selected-character-details-static article {
    display: flex;
    position: static;
    padding-left: 6%;
    padding-right: 6%;
    transform: unset;
    max-width: unset; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #selected-character-details-static article {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      #selected-character-details-static article {
        flex-direction: column;
        height: auto; } }
    @media only screen and (min-height: 1140px) {
      #selected-character-details-static article {
        max-width: 2000px; } }
    #selected-character-details-static article section .buttons {
      grid-template-columns: repeat(1, 1fr); }
      #selected-character-details-static article section .buttons .button {
        width: 140px;
        margin: auto; }
    #selected-character-details-static article section.image {
      flex-direction: column;
      transform: translate(0, 60px); }
      @media only screen and (max-width: 1099px), (max-height: 649px) {
        #selected-character-details-static article section.image {
          display: none; } }
      #selected-character-details-static article section.image h2 {
        font-size: 2rem;
        line-height: 1;
        text-align: center;
        margin: auto auto 2.8rem auto;
        max-width: 700px;
        padding: 0 1rem; }
        @media only screen and (min-width: 1300px) {
          #selected-character-details-static article section.image h2 {
            font-size: 3.8rem;
            line-height: 0.9; } }
      #selected-character-details-static article section.image .button {
        margin: 0 auto auto auto;
        width: 140px; }
      #selected-character-details-static article section.image .smoke {
        transform: translate(-50%, -60px);
        z-index: -1; }
        @media only screen and (min-height: 1140px) {
          #selected-character-details-static article section.image .smoke {
            transform: translate(-50%, 160px);
            width: 100%; } }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      #selected-character-details-static article section.side.right {
        display: none; } }
    #selected-character-details-static article .panel.blue h2 {
      line-height: 1; }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      #selected-character-details-static article .panel.blue {
        max-width: 500px;
        margin: 4rem auto 1rem;
        padding-bottom: 3rem; } }
    #selected-character-details-static article .panel.blue .bio {
      overflow: auto; }
      @media only screen and (max-height: 800px) {
        #selected-character-details-static article .panel.blue .bio {
          height: calc(100vh - 510px); } }
      @media only screen and (max-width: 1099px), (max-height: 649px) {
        #selected-character-details-static article .panel.blue .bio {
          height: auto; } }

#characters-static {
  background-image: url(../img/bg/character-select.jpg);
  background-position: center;
  background-size: cover; }
  @media only screen and (max-width: 1099px), (max-height: 649px) {
    #characters-static {
      padding-bottom: 3rem; } }

.carousel .slick-slide img,
.abilities-carousel .slick-slide img {
  opacity: 1 !important; }

#character-selector,
#character-selector-static {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  opacity: 0; }
  #character-selector h2,
  #character-selector-static h2 {
    font-size: 1.5rem;
    line-height: 1.25;
    padding-left: 6%;
    padding-right: 6%;
    text-align: center; }
    @media only screen and (min-width: 1300px) {
      #character-selector h2,
      #character-selector-static h2 {
        font-size: 2rem; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #character-selector h2,
      #character-selector-static h2 {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (min-width: 1300px) {
      #character-selector h2,
      #character-selector-static h2 {
        font-size: 1.5rem; } }
    @media only screen and (min-height: 800px) {
      #character-selector h2,
      #character-selector-static h2 {
        font-size: 2rem; } }
  #character-selector .slick-arrows,
  #character-selector-static .slick-arrows {
    order: 1; }
    @media only screen and (max-width: 1099px), (max-height: 649px) {
      #character-selector .slick-arrows h2,
      #character-selector-static .slick-arrows h2 {
        margin: 18px 0; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-selector .slick-arrows,
    #character-selector-static .slick-arrows {
      order: 2;
      width: 320px;
      position: relative; }
      #character-selector .slick-arrows h2,
      #character-selector-static .slick-arrows h2 {
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (min-height: 800px) {
    #character-selector .slick-arrows,
    #character-selector-static .slick-arrows {
      width: 420px; } }

#character-selector-static {
  opacity: 1; }
  @media only screen and (max-height: 800px) {
    #character-selector-static {
      display: none; } }
  @media only screen and (max-width: 1099px), (max-height: 649px) {
    #character-selector-static {
      display: none; } }
  @media only screen and (min-height: 1140px) {
    #character-selector-static {
      margin-top: 100px; } }

#no-results {
  display: none;
  text-align: center; }
  #no-results button.clear-all {
    padding: 12px 35px 11px 12px; }
    #no-results button.clear-all:after {
      right: 8px; }

#character-carousel,
#character-carousel-static {
  display: grid;
  grid-gap: 11px;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 6%;
  padding-right: 6%;
  margin: 0 0 150px;
  order: 3;
  overflow-y: hidden;
  transition: transform 0.2s ease-out; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-carousel,
    #character-carousel-static {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (max-width: 1099px), (max-height: 649px) {
    #character-carousel,
    #character-carousel-static {
      margin-bottom: 50px; } }
  @media only screen and (min-width: 850px) {
    #character-carousel,
    #character-carousel-static {
      grid-template-columns: repeat(5, 1fr);
      margin-top: 1rem; } }
  @media only screen and (min-width: 1024px) {
    #character-carousel,
    #character-carousel-static {
      grid-template-columns: repeat(6, 1fr); } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-carousel,
    #character-carousel-static {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: repeat(1, 1fr);
      margin: 0;
      order: 1;
      transition: none;
      -webkit-mask-image: linear-gradient(to left, transparent 0%, black 20%, black 80%, transparent 100%);
      -webkit-mask-mode: luminance; } }
  @media only screen and (min-height: 1080px) {
    #character-carousel,
    #character-carousel-static {
      width: 100%; } }
  #character-carousel .slick-list,
  #character-carousel-static .slick-list {
    padding: 0 !important; }
  #character-carousel .slick-track,
  #character-carousel-static .slick-track {
    padding: 6px 0; }
  #character-carousel article,
  #character-carousel-static article {
    text-align: center;
    outline: none;
    cursor: pointer; }
    #character-carousel article:hover img,
    #character-carousel-static article:hover img {
      filter: url(#effect-purple); }
    #character-carousel article:hover a span,
    #character-carousel-static article:hover a span {
      transform: translateY(0%); }
    #character-carousel article a,
    #character-carousel-static article a {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      text-decoration: none; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #character-carousel article a,
        #character-carousel-static article a {
          border: 1px solid #fff;
          display: block;
          flex-direction: unset;
          pointer-events: none;
          margin: 0 2px;
          transform: scale(0.9);
          transition: all 0.25s ease-out;
          box-shadow: 0 0 0 1px white, 0 0 10px rgba(255, 255, 255, 0); } }
      @media (min-height: 800px) and (min-width: 1200px) {
        #character-carousel article a,
        #character-carousel-static article a {
          margin: 5px; } }
      #character-carousel article a span,
      #character-carousel-static article a span {
        text-transform: uppercase;
        display: block;
        font-family: "proxima-nova", sans-serif;
        font-weight: 700;
        font-style: normal;
        line-height: 1;
        font-size: 12px;
        order: 2;
        margin: 8px auto 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60px; }
        @media only screen and (min-width: 375px) {
          #character-carousel article a span,
          #character-carousel-static article a span {
            max-width: 74px; } }
        @media only screen and (min-width: 414px) {
          #character-carousel article a span,
          #character-carousel-static article a span {
            max-width: 82px; } }
        @media only screen and (min-width: 600px) {
          #character-carousel article a span,
          #character-carousel-static article a span {
            max-width: unset; } }
        @media only screen and (min-width: 1100px) and (min-height: 650px) {
          #character-carousel article a span,
          #character-carousel-static article a span {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(165, 130, 252, 0.7);
            backdrop-filter: blur(7px);
            padding: 10px;
            transition: all 0.15s ease-out;
            transform: translateY(100%);
            margin: 0;
            font-size: 16px; } }
        @media (min-width: 1200px) and (min-height: 950px) {
          #character-carousel article a span,
          #character-carousel-static article a span {
            display: block; } }
        @media (min-width: 1500px) and (max-height: 950px) {
          #character-carousel article a span,
          #character-carousel-static article a span {
            display: block; } }
        @media only screen and (min-width: 1500px) and (min-height: 850px) {
          #character-carousel article a span,
          #character-carousel-static article a span {
            display: block; } }
      #character-carousel article a .effect-purple,
      #character-carousel article a .effect-blue,
      #character-carousel-static article a .effect-purple,
      #character-carousel-static article a .effect-blue {
        position: absolute;
        width: 0;
        height: 0;
        top: 1px; }
    #character-carousel article img,
    #character-carousel-static article img {
      width: 100%;
      border: 1px solid #fff; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #character-carousel article img,
        #character-carousel-static article img {
          width: 90px;
          border: none; } }
      @media only screen and (min-width: 1500px) and (min-height: 850px) {
        #character-carousel article img,
        #character-carousel-static article img {
          width: 135px; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #character-carousel article.slick-current,
      #character-carousel-static article.slick-current {
        cursor: default;
        pointer-events: none; }
        #character-carousel article.slick-current a,
        #character-carousel-static article.slick-current a {
          transform: scale(1);
          box-shadow: 0 0 0 1px white, 0 0 10px white; }
          #character-carousel article.slick-current a span,
          #character-carousel-static article.slick-current a span {
            background: rgba(0, 168, 253, 0.7); }
        #character-carousel article.slick-current img,
        #character-carousel-static article.slick-current img {
          filter: url(#effect-blue); } }

#character-carousel-static {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 2px 0;
  overflow-x: hidden; }
  @media only screen and (max-height: 800px) {
    #character-carousel-static {
      display: none; } }
  #character-carousel-static article {
    margin: 0 3px;
    cursor: default; }
    #character-carousel-static article:nth-child(2) {
      order: 1; }
    #character-carousel-static article:nth-child(3) {
      order: 2; }
    #character-carousel-static article:nth-child(4) {
      order: 3; }
    #character-carousel-static article:nth-child(5) {
      order: 4; }
    #character-carousel-static article:nth-child(6) {
      order: 5; }
    #character-carousel-static article:nth-child(7) {
      order: 6; }
    #character-carousel-static article:nth-child(8) {
      order: 7; }
    #character-carousel-static article:nth-child(9) {
      order: 8; }
    #character-carousel-static article:nth-child(10) {
      order: 9; }
    #character-carousel-static article:nth-child(11) {
      order: 10; }
    #character-carousel-static article img {
      box-shadow: 0 0 0 1px white, 0 0 10px rgba(255, 255, 255, 0);
      transform: scale(0.9);
      min-width: 135px; }
    #character-carousel-static article:hover img {
      filter: none; }
    #character-carousel-static article.question-mark {
      order: 1;
      box-shadow: 0 0 0 1px white, 0 0 10px rgba(255, 255, 255, 0);
      margin: 0 10px; }
      #character-carousel-static article.question-mark div {
        display: block;
        width: 135px;
        height: 135px;
        background: #0E67CB;
        position: relative;
        box-shadow: 0 0 5px rgba(255, 255, 255, 0.3); }
        #character-carousel-static article.question-mark div:after {
          content: '\003F';
          display: block;
          color: #70BFF7;
          font-family: "proxima-nova", sans-serif;
          font-weight: 900;
          font-style: normal;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -48%);
          font-size: 4vw; }

#search-controls {
  order: 2;
  margin-bottom: 2rem; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #search-controls {
      order: 3; } }
  #search-controls .clear-all {
    display: none; }
  #search-controls .back {
    display: none; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #search-controls .back {
        display: inline-block; } }

#character-search {
  transition: all 0.2s ease-out;
  backdrop-filter: blur(35px) contrast(1.5) brightness(0.8);
  background: linear-gradient(90deg, rgba(0, 168, 253, 0.4) 0%, rgba(185, 130, 252, 0.4) 100%);
  display: flex;
  position: fixed;
  bottom: -2rem;
  left: 0;
  right: 0;
  transform: translateY(100%);
  padding-top: 1.4rem;
  padding-bottom: 3.5rem;
  padding-left: 6%;
  padding-right: 6%;
  z-index: 100;
  box-shadow: 0 20px 30px 20px rgba(0, 0, 0, 0.2); }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-search {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-search {
      box-shadow: 0 20px 30px 20px rgba(0, 0, 0, 0.1); } }
  #character-search.is-open {
    transform: translateY(0%); }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-search {
      padding: 1.4rem 2.5rem 3.5rem; } }
  #character-search .close {
    margin-right: 1rem;
    min-width: 35px; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #character-search .close {
        margin-right: 1.75rem; } }

#character-filters {
  padding-left: 6%;
  padding-right: 6%;
  transition: all 0.2s ease-out;
  backdrop-filter: blur(35px) contrast(1.5) brightness(0.8);
  background: linear-gradient(90deg, rgba(0, 168, 253, 0.4) 0%, rgba(185, 130, 252, 0.4) 100%);
  display: grid;
  grid-template-columns: 37px 1fr;
  grid-gap: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 1.4rem;
  padding-bottom: 2rem;
  transform: translateY(50%);
  box-shadow: 0 20px 30px 20px rgba(0, 0, 0, 0.2);
  z-index: -1;
  opacity: 0;
  height: calc(100vh - 244px); }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-filters {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-filters {
      box-shadow: 0 20px 30px 20px rgba(0, 0, 0, 0.1); } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #character-filters {
      padding: 1.4rem 2.5rem 1.4rem;
      opacity: 1;
      height: auto;
      transform: translateY(100%); } }
  @media only screen and (min-width: 1000px) and (max-height: 649px) {
    #character-filters {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 3rem; } }
  #character-filters #filter-categories {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #character-filters #filter-categories {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        display: grid;
        grid-gap: 0;
        flex-direction: unset; } }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      #character-filters #filter-categories {
        grid-gap: 2rem; } }
    #character-filters #filter-categories div {
      margin-bottom: 2rem; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #character-filters #filter-categories div {
          padding: 0 1rem;
          margin-bottom: 0; } }
      #character-filters #filter-categories div button {
        background: transparent; }

.filter-panel-toggle {
  display: block;
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  text-align: left;
  line-height: 2.5;
  color: #fff;
  padding-right: 60px;
  position: relative;
  padding: 0; }
  .filter-panel-toggle:after {
    content: '';
    display: block;
    top: 50%;
    position: absolute;
    right: 18px;
    transform: translateY(-50%);
    background-repeat: no-repeat; }
  .filter-panel-toggle:after {
    font-size: 16px;
    line-height: 22px;
    width: 22px;
    height: 22px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    content: '\f078';
    width: 15px;
    height: 10px;
    right: 0;
    line-height: 10px;
    transition: all 0.2s ease-out; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    .filter-panel-toggle {
      color: rgba(255, 255, 255, 0.66); } }
  .filter-panel-toggle:focus, .filter-panel-toggle:hover {
    color: #fff;
    box-shadow: unset;
    outline: none; }
  .filter-panel-toggle.on:after {
    transform: scaleY(-1) translateY(5px); }

#filter-panels {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  z-index: 999; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #filter-panels {
      padding: 1.4rem 2.5rem 1.5rem 5.8rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      display: grid;
      align-items: flex-start;
      grid-gap: 0;
      z-index: 10; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) and (min-width: 1500px) and (min-height: 850px) {
    #filter-panels {
      grid-gap: 2rem; } }
  #filter-panels input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0; }
    #filter-panels input:checked + label {
      background: rgba(185, 130, 252, 0.4); }
      #filter-panels input:checked + label:hover, #filter-panels input:checked + label:focus {
        background: rgba(185, 130, 252, 0.5); }
  #filter-panels label {
    display: flex;
    cursor: pointer;
    border: 1px solid #fff;
    padding: 7px 11px 6px 11px;
    margin-bottom: 0.75rem;
    position: relative;
    font-size: 0.83rem;
    text-transform: uppercase;
    background: rgba(39, 70, 204, 0.4);
    font-family: "proxima-nova", sans-serif;
    font-weight: 700;
    font-style: normal; }
    #filter-panels label:focus, #filter-panels label:hover {
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
      border-color: rgba(255, 255, 255, 0.9); }
    #filter-panels label:last-child {
      margin-bottom: 0; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #filter-panels label {
        padding: 12px; } }
  #filter-panels .category {
    padding-top: 1.4rem;
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 1.4rem;
    backdrop-filter: blur(6px) brightness(1.1);
    transition: all 0.26s ease-out;
    background: rgba(39, 70, 204, 0.65);
    transform: translateY(0%, 0%);
    height: calc(100vh - 244px);
    opacity: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    align-items: flex-start;
    grid-gap: 1rem;
    pointer-events: none;
    position: relative;
    z-index: 2;
    position: fixed;
    width: 100%; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #filter-panels .category {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #filter-panels .category {
        backdrop-filter: blur(18px) brightness(1.1);
        transition: all 0.2s ease-out; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #filter-panels .category {
        padding-bottom: 125px;
        height: auto;
        opacity: 1;
        padding-left: 1rem;
        padding-right: 1rem;
        box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.5);
        transform: translate(0%, 0%);
        grid-template-columns: repeat(1, 1fr);
        position: unset; } }
    @media only screen and (min-width: 1000px) and (max-height: 649px) {
      #filter-panels .category {
        max-width: 1000px;
        padding-top: 3rem;
        left: 50%;
        transform: translate(-50%, 0%); } }
    #filter-panels .category.is-open {
      transform: translate(0%, -100%);
      opacity: 1;
      pointer-events: all; }
      @media only screen and (min-width: 1000px) and (max-height: 649px) {
        #filter-panels .category.is-open {
          transform: translate(-50%, -100%); } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #filter-panels .category .back {
        display: none; } }
    #filter-panels .category .custom-scrollbar .mCSB_scrollTools {
      right: 5px; }
    #filter-panels .category .custom-scrollbar .mCSB_inside > .mCSB_container {
      margin-right: 26px; }
    #filter-panels .category .custom-scrollbar .mCSB_scrollTools .mCSB_draggerContainer {
      top: 0;
      height: 100%; }
    #filter-panels .category .selected {
      display: none; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #filter-panels .category .selected {
          grid-template-columns: 1fr auto;
          grid-gap: 1rem; }
          #filter-panels .category .selected.on {
            display: grid; } }
      #filter-panels .category .selected .active-filters {
        display: flex;
        flex-direction: column; }
        #filter-panels .category .selected .active-filters h5 {
          margin: auto 0;
          text-transform: capitalize;
          font-family: "proxima-nova", sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 15px; }
          #filter-panels .category .selected .active-filters h5 span {
            font-family: "proxima-nova", sans-serif;
            font-weight: 700;
            font-style: normal; }
        #filter-panels .category .selected .active-filters span {
          font-family: "proxima-nova", sans-serif;
          font-weight: 400;
          font-style: normal;
          text-transform: capitalize;
          font-size: 0.83rem; }
      #filter-panels .category .selected button {
        padding: 15px 30px 14px 12px;
        align-self: flex-start; }
        #filter-panels .category .selected button:after {
          right: 5px;
          top: 49%; }
    #filter-panels .category .filters {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
      scrollbar-width: none;
      padding-top: 1px; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #filter-panels .category .filters {
          max-height: 400px; } }
      #filter-panels .category .filters fieldset {
        padding: 0;
        border: none;
        margin: 0; }
      #filter-panels .category .filters.fade-bottom {
        -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
        -webkit-mask-mode: luminance; }
        #filter-panels .category .filters.fade-bottom::-webkit-scrollbar {
          width: 0px;
          /* Remove scrollbar space */
          background: transparent;
          /* Optional: just make scrollbar invisible */ }
      #filter-panels .category .filters .spacer {
        padding-top: 30px; }
    #filter-panels .category .next {
      align-self: flex-end;
      grid-column: span 2;
      justify-self: flex-end; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #filter-panels .category .next {
          grid-column: span 1; } }

@media only screen and (max-width: 1099px), (max-height: 649px) {
  .filters-are-open #character-carousel {
    transform: translateY(20px) scale(0.95); } }

.filters-are-open #character-filters {
  transform: translateY(0%);
  opacity: 1;
  z-index: 100; }

@-moz-document url-prefix() {
  #character-search,
  #character-filters {
    backdrop-filter: none;
    background: linear-gradient(90deg, #0047AF 0%, #5651BC 100%); }
  #filter-panels .category {
    background: rgba(39, 70, 204, 0.95);
    backdrop-filter: none; }
  #menu {
    background: rgba(39, 70, 204, 0.95);
    backdrop-filter: none; } }

.slide-in-from-right {
  animation: slide-in-from-right .5s ease-in-out; }

@keyframes slide-in-from-right {
  from {
    transform: translateX(100%);
    opacity: 0; }
  to {
    transform: translateX(0%);
    opacity: 1; } }

.fade-in {
  animation: fade-in .5s ease-in; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-edges {
  -webkit-mask-image: linear-gradient(to left, transparent 0%, black 20%, black 80%, transparent 100%);
  -webkit-mask-mode: luminance; }

#backdrop {
  opacity: 0;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 1000px;
  display: none;
  position: absolute !important;
  left: 50%;
  top: 50%; }
  @media only screen and (min-width: 1500px) {
    #backdrop {
      width: 1290px; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #backdrop {
      display: flex;
      justify-content: center; } }
  #backdrop #glitter-sparkles {
    transform: translate;
    opacity: 0.7; }
  #backdrop .right {
    position: absolute;
    top: -300px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-right: 160px; }
  #backdrop .left {
    position: absolute;
    top: -300px;
    left: 0;
    width: 100%;
    margin-left: 160px; }
  #backdrop #light-floor {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0%); }
  #backdrop #light-beam-front-left {
    filter: invert(53%) sepia(78%) saturate(462%) hue-rotate(164deg) brightness(100%) contrast(110%);
    opacity: 0; }
    #backdrop #light-beam-front-left img {
      transform: scaleX(-1); }
  #backdrop #light-beam-front-right {
    filter: invert(53%) sepia(78%) saturate(462%) hue-rotate(164deg) brightness(100%) contrast(110%);
    opacity: 0; }
  #backdrop #light-beam-back-left {
    position: absolute;
    top: -300px;
    left: -100px;
    z-index: -1;
    transform: scale(1.08);
    margin-top: 400px;
    filter: invert(53%) sepia(78%) saturate(462%) hue-rotate(164deg) brightness(100%) contrast(110%);
    opacity: 0; }
    #backdrop #light-beam-back-left img {
      transform: scaleX(-1); }
  #backdrop #light-beam-back-right {
    position: absolute;
    top: -300px;
    right: -100px;
    z-index: -1;
    margin-top: 400px;
    transform: scale(1.08);
    opacity: 0;
    filter: invert(53%) sepia(78%) saturate(462%) hue-rotate(164deg) brightness(100%) contrast(110%); }
  #backdrop #lens-flare-left {
    position: absolute;
    left: -10px;
    top: -10px; }
    #backdrop #lens-flare-left img {
      transform: scaleX(-1); }
  #backdrop #lens-flare-right {
    position: absolute;
    right: -10px;
    top: -10px;
    opacity: 0; }

#light-background {
  position: absolute;
  transform: translate(-50%, -51%);
  z-index: -10;
  left: 50%;
  top: 50%;
  display: none;
  width: 1920px;
  height: 1280px;
  background-image: url(../img/effects/lightened-bg.png);
  background-size: 1350px;
  background-position: center 70px;
  background-repeat: no-repeat; }
  @media only screen and (min-width: 1500px) {
    #light-background {
      background-size: unset;
      background-position: center; } }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #light-background {
      display: block;
      opacity: 0; } }

.sparkle-canvas {
  -webkit-mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, black 40%, transparent 45%);
  -webkit-mask-mode: luminance; }

#back-to-start {
  opacity: 0;
  position: fixed;
  top: -9999px; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #back-to-start {
      opacity: 1;
      position: relative;
      top: unset; } }

#desktop {
  display: none; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #desktop {
      display: table; } }

body.profile .form {
  margin-top: 0; }

body.profile #contact h2 {
  margin-top: auto;
  padding-top: 2rem; }

#selected-character-details.single {
  display: block;
  padding-left: 6%;
  padding-right: 6%; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #selected-character-details.single {
      padding-left: 5%;
      padding-right: 5%; } }
  #selected-character-details.single article {
    display: flex;
    height: unset;
    max-height: unset;
    position: static;
    transform: none; }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      #selected-character-details.single article section.image .featured {
        transform: translate(-50%, -50%); } }
    #selected-character-details.single article section.image .smoke {
      transform: translate(-50%, 0%) scale(1.5) !important;
      margin-bottom: -70px; }
      @media only screen and (min-width: 1500px) {
        #selected-character-details.single article section.image .smoke {
          transform: translate(-50%, 0%) scale(1) !important; } }
      @media only screen and (min-width: 1600px) {
        #selected-character-details.single article section.image .smoke {
          margin-bottom: -140px;
          transform: translate(-50%, 0%) scale(0.8) !important; } }
      @media (min-width: 1500px) and (min-height: 1000px) {
        #selected-character-details.single article section.image .smoke {
          margin-bottom: -120px;
          transform: translate(-50%, 0%) scale(1) !important; } }
      @media only screen and (min-height: 1140px) {
        #selected-character-details.single article section.image .smoke {
          margin-bottom: -140px; } }
  #selected-character-details.single.no-carousel .side.right {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: unset; }
  #selected-character-details.single.no-carousel .panel.purple:first-child {
    grid-column: unset; }
  #selected-character-details.single.no-carousel + #backdrop {
    transform: translate(-50%, -25%); }
    #selected-character-details.single.no-carousel + #backdrop + #light-background {
      transform: translate(-50%, -43%); }
      @media only screen and (min-width: 1500px) and (min-height: 850px) {
        #selected-character-details.single.no-carousel + #backdrop + #light-background {
          transform: translate(-50%, -43%); } }
  #selected-character-details.single.no-carousel .blue {
    padding-right: 1.1rem; }
    #selected-character-details.single.no-carousel .blue .bio {
      max-height: 300px; }
  #selected-character-details.single.no-carousel article section.image .featured {
    max-height: 700px;
    height: 90vh; }

#gallery {
  text-align: center; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    #gallery .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      height: 100%; } }
  #gallery .gallery-slides {
    min-width: 0;
    opacity: 0; }
    #gallery .gallery-slides .slick-next {
      top: 50%;
      right: 5%;
      z-index: 1; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #gallery .gallery-slides .slick-next {
          right: 95px; } }
    #gallery .gallery-slides .slick-prev {
      left: 5%;
      top: 50%;
      z-index: 1; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #gallery .gallery-slides .slick-prev {
          left: 95px; } }
  #gallery .gallery-photo {
    height: 50vh;
    min-height: 300px;
    background-position: center;
    background-size: cover; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #gallery .gallery-photo {
        height: 100vh; } }
  #gallery .profile {
    margin: auto;
    padding: 30px 20px;
    max-width: 740px;
    width: 100%;
    min-width: 0;
    opacity: 0; }
    @media only screen and (min-width: 768px) {
      #gallery .profile {
        padding: 50px; } }
    #gallery .profile h1 {
      margin-bottom: 0; }
      @media only screen and (min-width: 1500px) and (min-height: 850px) {
        #gallery .profile h1 {
          font-size: 2rem;
          line-height: 1;
          margin-top: 0;
          margin-bottom: 1.8rem; } }
  @media only screen and (min-width: 1500px) and (min-height: 850px) and (min-width: 1300px) {
    #gallery .profile h1 {
      font-size: 3.8rem;
      line-height: 0.9; } }
    #gallery .profile.has-award .full-bio {
      margin-bottom: 5rem; }
      #gallery .profile.has-award .full-bio.mCustomScrollbar {
        max-height: calc(100vh - 550px); }
  #gallery .full-bio {
    font-size: 0.9rem;
    line-height: 1.8;
    min-height: 100px; }
    #gallery .full-bio.mCustomScrollbar {
      max-height: calc(100vh - 450px); }
    #gallery .full-bio p:first-of-type {
      font-weight: bold; }
  #gallery .awards {
    margin: 2rem 0 3rem; }
    @media only screen and (min-width: 768px) {
      #gallery .awards {
        margin: 4rem 0 5rem; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #gallery .awards {
        margin: 2rem 0 3rem; } }
    @media only screen and (min-height: 1140px) {
      #gallery .awards {
        margin: 4rem 0 5rem; } }
    #gallery .awards figure {
      margin: 0; }
    #gallery .awards img {
      margin-bottom: 0.5rem;
      max-height: 45px; }
    #gallery .awards figcaption {
      font-weight: bold;
      margin: 0.6rem 0; }

#stats {
  text-align: center; }
  #stats .container {
    padding-left: 6%;
    padding-right: 6%;
    margin: auto;
    padding-left: 6%;
    padding-right: 6%; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #stats .container {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (min-width: 450px) {
      #stats .container {
        padding-right: calc(6% - 0.7rem);
        padding-left: calc(6% - 0.7rem); } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #stats .container {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 3rem;
        padding-right: calc(5% - 0.7rem);
        padding-left: calc(5% - 0.7rem); } }
    @media (min-width: 1100px) and (max-height: 950px) {
      #stats .container {
        max-width: 1300px; } }
    @media only screen and (min-width: 1600px) {
      #stats .container {
        max-width: 1400px; } }
    @media only screen and (min-width: 1900px) {
      #stats .container {
        max-width: 1650px; } }
    @media only screen and (min-height: 1140px) {
      #stats .container {
        max-width: 2180px; } }
  #stats .title {
    display: flex; }
    #stats .title h2 {
      font-family: "proxima-nova", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: bold;
      margin: auto;
      line-height: 5;
      font-size: 23px; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #stats .title h2 {
          max-width: 100px;
          font-size: 16px;
          line-height: 1; } }
      @media only screen and (min-width: 1300px) and (min-height: 850px) {
        #stats .title h2 {
          max-width: 140px;
          font-size: 23px; } }
  #stats .stats-carousel,
  #stats .games-carousel {
    min-width: 0; }
    #stats .stats-carousel .slick-arrow,
    #stats .games-carousel .slick-arrow {
      left: -137px;
      top: 50%; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #stats .stats-carousel .slick-arrow,
        #stats .games-carousel .slick-arrow {
          left: -115px; } }
      @media only screen and (min-width: 1300px) and (min-height: 850px) {
        #stats .stats-carousel .slick-arrow,
        #stats .games-carousel .slick-arrow {
          left: -137px; } }
    #stats .stats-carousel .slick-next,
    #stats .games-carousel .slick-next {
      margin: 80px 0 0; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #stats .stats-carousel .slick-next,
        #stats .games-carousel .slick-next {
          margin: 50px 0 0; } }
      @media only screen and (min-width: 1300px) and (min-height: 850px) {
        #stats .stats-carousel .slick-next,
        #stats .games-carousel .slick-next {
          margin: 80px 0 0; } }
    #stats .stats-carousel .slick-prev,
    #stats .games-carousel .slick-prev {
      margin: -80px 0 0; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #stats .stats-carousel .slick-prev,
        #stats .games-carousel .slick-prev {
          margin: -50px 0 0; } }
      @media only screen and (min-width: 1300px) and (min-height: 850px) {
        #stats .stats-carousel .slick-prev,
        #stats .games-carousel .slick-prev {
          margin: -80px 0 0; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #stats .stats-carousel .slick-dots,
      #stats .games-carousel .slick-dots {
        visibility: hidden; } }
  #stats .slick-dotted.slick-slider {
    margin: 0; }
  #stats .slick-dots {
    bottom: unset;
    top: -40px; }
  #stats .stat-slide {
    margin: 0; }
    @media only screen and (min-width: 450px) {
      #stats .stat-slide {
        margin: 0.7rem; } }
    #stats .stat-slide figure {
      margin: 0; }
      #stats .stat-slide figure figcaption {
        text-transform: uppercase;
        font-family: "proxima-nova", sans-serif;
        font-weight: 700;
        font-style: normal;
        margin: 12px 0;
        line-height: 1.2;
        font-size: 22px; }
        @media only screen and (min-width: 450px) {
          #stats .stat-slide figure figcaption {
            font-size: 16px; } }
        @media only screen and (min-width: 700px) {
          #stats .stat-slide figure figcaption {
            font-size: 2vw; } }
        @media only screen and (min-width: 1100px) {
          #stats .stat-slide figure figcaption {
            font-size: 1.2vw; } }
        @media only screen and (min-width: 1900px) {
          #stats .stat-slide figure figcaption {
            font-size: 20px; } }
    #stats .stat-slide img {
      width: 25vw;
      margin: 12px 0; }
      @media only screen and (min-width: 450px) {
        #stats .stat-slide img {
          width: 50px; } }
      @media only screen and (min-width: 700px) {
        #stats .stat-slide img {
          width: 7vw; } }
      @media only screen and (min-width: 1100px) {
        #stats .stat-slide img {
          width: 4vw;
          max-width: 60px; } }
    #stats .stat-slide.game img {
      width: auto;
      height: auto; }
      @media only screen and (min-width: 450px) {
        #stats .stat-slide.game img {
          width: 100px; } }
      @media only screen and (min-width: 700px) {
        #stats .stat-slide.game img {
          width: 14vw; } }
      @media only screen and (min-width: 1100px) {
        #stats .stat-slide.game img {
          width: 8vw;
          max-width: 200px; } }
    #stats .stat-slide.placeholder img {
      opacity: 0.2;
      width: 65%; }
    #stats .stat-slide img.placeholder-logo {
      opacity: 0.2; }
    #stats .stat-slide .tile .wrap {
      padding: 10%; }
    @media only screen and (min-width: 450px) {
      #stats .stat-slide.influence {
        grid-column: span 2; } }
    @media only screen and (min-width: 450px) {
      #stats .stat-slide.influence .tile {
        padding-bottom: calc(50% - 0.7rem); } }
    #stats .stat-slide.influence h2 {
      font-size: 2rem;
      margin: 0 0 0.5rem; }
      @media only screen and (min-width: 1100px) and (min-height: 650px) {
        #stats .stat-slide.influence h2 {
          font-size: 2vw;
          margin: 0; } }
      @media only screen and (min-width: 1300px) and (min-height: 850px) {
        #stats .stat-slide.influence h2 {
          font-size: 2.5vw; } }
      @media (min-width: 1100px) and (max-height: 950px) {
        #stats .stat-slide.influence h2 {
          font-size: 2rem; } }
    @media only screen and (min-width: 450px) {
      #stats .stat-slide.twitter {
        grid-column: span 2; } }
    @media only screen and (min-width: 450px) {
      #stats .stat-slide.twitter .tile {
        padding-bottom: calc(50% - 0.7rem); } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #stats .stat-slide.twitter .tile {
        padding-bottom: 100%; } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #stats .stat-slide.twitter {
        grid-column: span 1; } }
  #stats .social-row {
    display: grid;
    grid-gap: 1.5rem; }
    @media only screen and (min-width: 450px) {
      #stats .social-row {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.4rem;
        margin: 0 0.7rem; }
        #stats .social-row .stat-slide {
          margin: 0; } }
    @media only screen and (min-width: 700px) {
      #stats .social-row {
        grid-template-columns: repeat(3, 1fr); } }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #stats .social-row {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0;
        margin: 0; }
        #stats .social-row .stat-slide {
          margin: 0.7rem; } }

@keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slide-out-down {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(100%); } }

.blog-carousel {
  margin-top: 50px; }
  .blog-carousel__controls {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 35px 0;
    position: relative;
    width: 100%; }
    .blog-carousel__controls h2 {
      margin: 0 !important; }
    .blog-carousel__controls .slick-prev,
    .blog-carousel__controls .slick-next {
      position: relative !important;
      z-index: 300;
      top: 15px;
      left: 0; }
    .blog-carousel__controls .slick-prev {
      margin-right: 25px; }
    .blog-carousel__controls .slick-next {
      margin-left: 25px; }

.blog-post {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 6px;
  position: relative;
  height: 100%;
  width: 100%; }
  @media only screen and (min-width: 1100px) {
    .blog-post {
      margin: 0 12.5px; } }
  .blog-post:hover {
    cursor: pointer; }
  .blog-post__overlay {
    backdrop-filter: blur(35px) brightness(0.9);
    background: rgba(39, 70, 204, 0.8);
    bottom: 0;
    display: flex;
    height: 0;
    opacity: 0;
    padding: 25px;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 100%;
    visibility: hidden; }
  .blog-post__overlay-close {
    align-items: center;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    height: 37px;
    width: 37px;
    padding: 0;
    position: absolute;
    top: 15px;
    left: 15px; }
    .blog-post__overlay-close:hover {
      cursor: pointer; }
    .blog-post__overlay-close span {
      line-height: 0; }
    .blog-post__overlay-close svg {
      height: 27px;
      width: 27px;
      transform: rotate(45deg); }
  .blog-post__overlay-content {
    align-items: center;
    align-self: center;
    display: flex;
    justify-self: center;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    opacity: 0; }
    @media only screen and (max-width: 1000px) {
      .blog-post__overlay-content {
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        overflow: auto;
        margin-bottom: 0; } }
  .blog-post__body-title, .blog-post__date {
    margin: 0; }
  .blog-post__body {
    margin: 25px 0; }
    @media only screen and (max-width: 1000px) {
      .blog-post__body {
        font-size: 14px; } }
  .blog-post__footer {
    align-items: center;
    backdrop-filter: blur(35px) brightness(0.9);
    background: rgba(39, 70, 204, 0.8);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    padding: 18px;
    position: absolute;
    transform: translateY(0);
    width: 100%;
    visibility: hidden; }
    @media only screen and (min-width: 700px) {
      .blog-post__footer {
        flex-direction: row;
        padding: 25px; } }
  .blog-post__title {
    margin: 0; }
    @media only screen and (max-width: 600px) {
      .blog-post__title {
        margin: 0 0 5px;
        font-size: 15px; } }
  .blog-post__read-more {
    align-items: center;
    display: flex;
    font-weight: 900;
    text-transform: uppercase; }
    @media only screen and (max-width: 600px) {
      .blog-post__read-more {
        font-size: 14px; } }
    .blog-post__read-more:hover {
      cursor: pointer; }
    .blog-post__read-more figure {
      margin: 1em 0 1em 10px; }
      @media only screen and (max-width: 600px) {
        .blog-post__read-more figure {
          margin: 0; } }
    .blog-post__read-more figcaption {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0; }
    .blog-post__read-more img {
      width: 26px;
      height: 26px;
      margin-left: 10px; }
  .blog-post.slick-current:not(.blog-post--overlay-is-open) .blog-post__footer {
    visibility: visible;
    animation: slide-in-up 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .blog-post:not(.slick-current) .blog-post__overlay {
    height: 100%;
    opacity: 1;
    visibility: visible; }
  .blog-post:not(.slick-current):not(.blog-post--overlay-is-open) .blog-post__overlay-content,
  .blog-post:not(.slick-current):not(.blog-post--overlay-is-open) .blog-post__overlay-close {
    visibility: hidden; }
  .blog-post--overlay-is-open .blog-post__overlay {
    height: 100%;
    opacity: 1;
    visibility: visible; }
  .blog-post--overlay-is-open .blog-post__overlay-content {
    opacity: 1; }
  .blog-post--overlay-is-open .blog-post__footer {
    animation: slide-out-down 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    visibility: hidden; }

#family {
  text-align: center;
  overflow: hidden; }
  #family .fp-tableCell {
    vertical-align: bottom; }
  #family img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    transform: scale(1.03); }
  #family .container {
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 50px;
    padding-top: 50px;
    display: flex;
    background: -moz-linear-gradient(top, rgba(27, 75, 191, 0) 0%, rgba(27, 75, 191, 0.01) 1%, #1b4abe 100%);
    background: -webkit-linear-gradient(top, rgba(27, 75, 191, 0) 0%, rgba(27, 75, 191, 0.01) 1%, #1b4abe 100%);
    background: linear-gradient(to bottom, rgba(27, 75, 191, 0) 0%, rgba(27, 75, 191, 0.01) 1%, #1b4abe 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001b4bbf', endColorstr='#1b4abe', GradientType=0); }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #family .container {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (min-width: 768px) {
      #family .container {
        padding-top: 120px;
        padding-bottom: 120px; } }
    #family .container .inner {
      margin: auto;
      max-width: 920px; }
      #family .container .inner h1 {
        margin-bottom: 1.5rem;
        opacity: 0; }
        @media only screen and (min-width: 768px) {
          #family .container .inner h1 {
            font-size: 2rem;
            line-height: 1; } }
  @media only screen and (min-width: 768px) and (min-width: 1300px) {
    #family .container .inner h1 {
      font-size: 3.8rem;
      line-height: 0.9; } }
      #family .container .inner p,
      #family .container .inner .button {
        opacity: 0; }
      #family .container .inner .button {
        margin-top: 1rem; }

#grid-gallery {
  text-align: center;
  overflow: hidden;
  padding: 0;
  margin-bottom: 50px; }
  @media only screen and (min-width: 1024px) {
    #grid-gallery {
      margin-bottom: 0; } }
  #grid-gallery .container {
    padding-left: 6%;
    padding-right: 6%;
    margin-left: auto;
    margin-right: auto;
    max-width: 2300px; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #grid-gallery .container {
        padding-left: 5%;
        padding-right: 5%; } }
    @media only screen and (min-width: 1024px) {
      #grid-gallery .container {
        padding-top: 100px;
        padding-bottom: 100px; } }
    @media only screen and (min-height: 800px) {
      #grid-gallery .container {
        padding-top: 0;
        padding-bottom: 0; } }
    @media (min-height: 800px) and (min-width: 1240px) {
      #grid-gallery .container {
        max-width: 1150px; } }
    @media (min-height: 800px) and (min-width: 1440px) {
      #grid-gallery .container {
        max-width: 1400px; } }
    @media (min-height: 800px) and (min-width: 1840px) {
      #grid-gallery .container {
        max-width: 1700px; } }
    @media (min-width: 1100px) and (max-height: 950px) {
      #grid-gallery .container {
        max-width: 1300px; } }
    @media (min-width: 1500px) and (max-height: 950px) {
      #grid-gallery .container {
        max-width: 1500px; } }
    #grid-gallery .container section {
      min-width: 0; }
      @media only screen and (min-width: 1024px) {
        #grid-gallery .container section {
          display: grid;
          grid-gap: 1rem; }
          #grid-gallery .container section.left {
            grid-template-columns: 22.5% 1fr;
            margin-bottom: -40px; } }
    @media only screen and (min-width: 1024px) and (min-width: 1500px) and (min-height: 850px) {
      #grid-gallery .container section.left {
        margin-bottom: -13%; } }
    @media only screen and (min-width: 1024px) and (min-height: 800px) and (min-width: 1440px) {
      #grid-gallery .container section.left {
        margin-bottom: -5%; } }
      @media only screen and (min-width: 1024px) {
            #grid-gallery .container section.left .carousel-letterbox {
              margin-bottom: 1rem; }
          #grid-gallery .container section.right {
            grid-template-columns: 1fr 22.5%;
            align-items: end; }
            #grid-gallery .container section.right .carousel-rectangle {
              order: 2; }
            #grid-gallery .container section.right .slick-wrap {
              display: flex;
              flex-direction: column;
              align-items: flex-end; }
            #grid-gallery .container section.right .carousel-letterbox {
              margin-top: 1rem; } }
    #grid-gallery .container .slick-wrap {
      min-width: 0; }
    @media only screen and (min-width: 1024px) {
      #grid-gallery .container .center {
        position: absolute;
        z-index: 5;
        width: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-30deg);
        margin: 4px 0 0;
        max-width: 1500px; } }
  #grid-gallery h2 {
    line-height: 1;
    margin: 0; }
    @media only screen and (min-width: 780px) {
      #grid-gallery h2 {
        font-size: 10vw; } }
    @media only screen and (min-width: 1024px) {
      #grid-gallery h2 {
        background: -webkit-linear-gradient(45deg, #0B33B6, #0B33B6);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 5rem;
        text-align: left;
        padding-left: 11.5%; } }
    @media (min-height: 800px) and (min-width: 1440px) {
      #grid-gallery h2 {
        font-size: 6rem; } }
    @media (min-height: 800px) and (min-width: 1840px) {
      #grid-gallery h2 {
        font-size: 8rem; } }
    @media (min-width: 1100px) and (max-height: 950px) {
      #grid-gallery h2 {
        font-size: 5.5rem; } }
    @media only screen and (min-width: 1024px) {
      #grid-gallery h2.light {
        background: -webkit-linear-gradient(45deg, #0E6FD8, #0E6FD8);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: right;
        padding-right: 11.5%;
        padding-left: 0; } }
  #grid-gallery .divider {
    margin: 15px 0 23px;
    position: relative; }
    @media only screen and (min-width: 1024px) {
      #grid-gallery .divider {
        margin: 2px auto 11px;
        width: 85%; } }
    #grid-gallery .divider:after, #grid-gallery .divider:before {
      content: '';
      display: block;
      border-top: 1px solid #fff;
      margin-top: -1px;
      width: calc(50% - 5.5rem);
      position: absolute;
      top: 50%;
      left: 0; }
      @media only screen and (min-width: 1024px) {
        #grid-gallery .divider:after, #grid-gallery .divider:before {
          width: calc(50% - 6.5rem); } }
    #grid-gallery .divider:after {
      left: unset;
      right: 0; }
  @media only screen and (max-width: 1023px) {
    #grid-gallery .carousel-rectangle {
      display: none; } }
  @media only screen and (max-width: 1023px) {
    #grid-gallery .carousel-rectangle.mobile {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: -1;
      display: block;
      transform: scale(1.01);
      display: block; }
      #grid-gallery .carousel-rectangle.mobile img {
        height: 100vh;
        width: 100vw;
        object-fit: cover;
        object-position: center;
        top: 0;
        left: 0;
        filter: brightness(0.8); } }
  #grid-gallery .carousel-letterbox {
    width: 36%; }
    @media only screen and (max-width: 1023px) {
      #grid-gallery .carousel-letterbox {
        display: none; } }
  #grid-gallery .carousel-square {
    width: 13%; }
    @media only screen and (max-width: 1023px) {
      #grid-gallery .carousel-square {
        display: none; } }

body.services .scroller {
  display: none; }

body.brand-services #clients .container {
  padding-left: 0;
  padding-right: 0; }

body.brand-services #clients h2 {
  font-size: 2rem;
  line-height: 1;
  margin: auto;
  max-width: 800px; }
  @media only screen and (min-width: 1300px) {
    body.brand-services #clients h2 {
      font-size: 3.8rem;
      line-height: 0.9; } }

@media only screen and (min-width: 1500px) and (min-height: 850px) {
  body.brand-services #clients #client-logos {
    grid-template-columns: repeat(6, 1fr);
    column-gap: 6rem;
    row-gap: 4rem; } }

#services {
  text-align: center; }
  #services .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: calc(100% - 80px);
    margin-top: 80px; }
    @media only screen and (min-height: 700px) {
      #services .container {
        margin-top: 100px;
        height: calc(100% - 100px); } }
    @media only screen and (min-width: 1100px) {
      #services .container {
        margin-top: 0;
        height: 100%; } }
    @media only screen and (min-width: 768px) {
      #services .container {
        grid-template-columns: repeat(2, 1fr); } }
    #services .container section {
      position: relative;
      display: flex;
      overflow: hidden; }
      #services .container section .inner {
        margin: auto; }
        #services .container section .inner h2 {
          font-size: 2rem;
          line-height: 1;
          margin-bottom: 1.8rem;
          opacity: 0; }
          @media only screen and (min-width: 1300px) {
            #services .container section .inner h2 {
              font-size: 3.8rem;
              line-height: 0.9; } }
        #services .container section .inner .button {
          opacity: 0; }
        #services .container section .inner img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          width: 100vw;
          height: 50vh;
          object-fit: cover;
          object-position: center;
          transform: scale(1.03); }
          @media only screen and (min-width: 768px) {
            #services .container section .inner img {
              height: 100vh;
              object-position: bottom; } }

#service-intro {
  text-align: center; }
  #service-intro .container {
    padding-left: 6%;
    padding-right: 6%;
    margin: 150px auto 90px; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #service-intro .container {
        padding-left: 5%;
        padding-right: 5%; } }
    @media (min-height: 800px) and (min-width: 1100px) {
      #service-intro .container {
        margin: 0 auto; } }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      #service-intro .container {
        max-width: 1500px; } }
    @media only screen and (min-height: 1140px) {
      #service-intro .container {
        max-width: 2000px; } }
  #service-intro h1 {
    font-size: 2rem;
    line-height: 1;
    margin: auto auto 1.8rem;
    opacity: 0;
    font-size: 3rem; }
    @media only screen and (min-width: 1300px) {
      #service-intro h1 {
        font-size: 3.8rem;
        line-height: 0.9; } }
    @media only screen and (min-width: 768px) {
      #service-intro h1 {
        margin: auto auto 2.5rem; } }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      #service-intro h1 {
        margin: auto auto 1.5rem;
        max-width: 870px;
        font-size: 4rem; } }
  #service-intro h2 {
    margin: auto;
    max-width: 1050px;
    font-size: 1.5rem;
    line-height: 1.25;
    font-size: 1rem;
    margin: auto auto 1.8rem;
    opacity: 0; }
    @media only screen and (min-width: 1300px) {
      #service-intro h2 {
        font-size: 2rem; } }
    @media only screen and (min-width: 768px) {
      #service-intro h2 {
        font-size: 2rem;
        margin: auto auto 2.5rem; } }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      #service-intro h2 {
        margin: auto auto 2.5rem; } }
    @media (min-height: 800px) and (min-width: 1100px) {
      #service-intro h2 {
        max-width: 1040px; } }
  #service-intro h3 {
    font-size: 1.5rem;
    line-height: 1;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: bold;
    line-height: 1.2;
    margin: auto auto 3rem;
    opacity: 0; }
    @media (min-height: 800px) and (min-width: 1100px) {
      #service-intro h3 {
        display: none; } }
    @media (min-width: 1500px) and (min-height: 1000px) {
      #service-intro h3 {
        display: block;
        max-width: unset; } }
  #service-intro .tile {
    opacity: 0; }
  #service-intro .tiles {
    grid-gap: 1rem;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto; }
    @media only screen and (min-width: 768px) {
      #service-intro .tiles {
        grid-template-columns: repeat(3, 1fr); } }
    @media (min-height: 800px) and (min-width: 1100px) {
      #service-intro .tiles {
        max-width: 670px; } }
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
      #service-intro .tiles {
        max-width: 880px; } }
    @media (min-width: 1500px) and (min-height: 950px) {
      #service-intro .tiles {
        max-width: 1130px; } }
    @media only screen and (min-height: 1140px) {
      #service-intro .tiles {
        grid-gap: 2.5rem;
        max-width: 1500px; } }
    #service-intro .tiles h4 {
      margin-bottom: 0;
      line-height: 1; }
      @media only screen and (min-width: 768px) {
        #service-intro .tiles h4 {
          font-size: 2.7vw; } }
      @media (min-height: 800px) and (min-width: 1100px) {
        #service-intro .tiles h4 {
          font-size: 1.3vw;
          margin-top: 1rem; } }
      @media only screen and (min-width: 1500px) and (min-height: 850px) {
        #service-intro .tiles h4 {
          margin-bottom: 1.5rem;
          font-size: 2rem; } }
      @media (min-width: 1500px) and (min-height: 950px) {
        #service-intro .tiles h4 {
          display: block; } }
    #service-intro .tiles img {
      width: 28vw;
      margin-bottom: 2rem;
      height: 80px; }
      @media (min-height: 800px) and (min-width: 1100px) {
        #service-intro .tiles img {
          margin-bottom: 0; } }
      @media only screen and (min-width: 768px) {
        #service-intro .tiles img {
          width: 8vw; } }
      @media only screen and (min-width: 1500px) and (min-height: 850px) {
        #service-intro .tiles img {
          height: 80px;
          width: auto; } }
  @media only screen and (min-width: 1500px) and (min-height: 850px) and (min-height: 1140px) {
    #service-intro .tiles img {
      height: 150px; } }
      @media (min-width: 1500px) and (min-height: 950px) {
        #service-intro .tiles img {
          margin-bottom: 2rem; } }
    #service-intro .tiles p {
      display: none; }
      @media (min-width: 1500px) and (min-height: 950px) {
        #service-intro .tiles p {
          display: block; } }

#image {
  text-align: center;
  overflow: hidden; }
  #image .container {
    padding-left: 6%;
    padding-right: 6%; }
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
      #image .container {
        padding-left: 5%;
        padding-right: 5%; } }
  #image img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    transform: scaleX(1.03);
    opacity: 0.7; }
  #image h1 {
    font-size: 2rem;
    line-height: 1;
    margin: auto;
    max-width: 1000px; }
    @media only screen and (min-width: 1300px) {
      #image h1 {
        font-size: 3.8rem;
        line-height: 0.9; } }

.cms-page {
  padding-left: 6%;
  padding-right: 6%;
  margin-bottom: 100px; }
  @media only screen and (min-width: 1100px) and (min-height: 650px) {
    .cms-page {
      padding-left: 5%;
      padding-right: 5%; } }
  @media only screen and (min-width: 1300px) {
    .cms-page {
      max-width: 80%; } }
  .cms-page__title h1 {
    margin-bottom: 35px;
    font-size: 2rem;
    line-height: 1; }
    @media only screen and (min-width: 1300px) {
      .cms-page__title h1 {
        font-size: 3.8rem;
        line-height: 0.9; } }

body.page {
  position: relative;
  overflow-x: hidden; }
  body.page .dot-grid {
    z-index: -1;
    position: fixed;
    background-position: 49.5% 94px; }
  body.page #header {
    z-index: 100;
    position: relative; }
