html {
    height: 100%;
    overflow-x: hidden;
    background-color: #1A46B6;

    @include desktop {
        background-color: unset;
    }
}

body {
    @include main-background;
    color: $colour-text;
    @include font-regular;
    font-size: 1rem;
    height: 100%;
    overflow-x: hidden;
}

#header {
    display: flex;
    height: 80px;
    @include gutter;

    .back {
        margin: auto 0 auto 0;
        border: none;
        text-align: center;
        box-shadow: none;
        background: transparent;
    }

    .contact-button {
        margin: auto auto auto 1rem;
        padding: 13px 38px 11px 16px;

        @include mobile-only {
            padding: 0 0 0 1rem;
            border: none;
            font-size: 0;
            line-height: 0;
        }

        

        @include max(399px) {
            position: absolute;
            top: 49px;
            right: 4px;
        }

        @media (max-height: 699px) and (max-width: 399px) {
            top: 39px;
        }

        &:after {
            right: 12px !important;

            @include mobile-only {
                content: '\f0e0';
                width: 15px;
                font-weight: 100;
                font-size: 24px;
                line-height: 23px;
                transform: translateY(-50%) !important;

                @include max(399px) {
                    width: 35px;
                }
            }
        }

        &--close {
            &:after {
                content: '\f00d';
                font-weight: 100;
                font-size: 24px;
                line-height: 23px;
                width: 10px;

                @include max(399px) {
                    width: 35px;
                }

                @include min(1100px) {
                    width: 15px;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }

        &--hide {
            display: none;
        }
    }

    @include min-h(700px) {
        height: 100px;
    }

    @media (max-height: 799px), (max-width: 1099px) {
        background: $colour-header;
    }

    @include x-large-height {
        height: 150px;
    }

    div {
        flex: 1;
        display: flex;
    }

    .logo {
        display: inline-block;
        margin: auto;

        img {
            width: 90px;

            @include desktop {
                width: 125px;
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 9999;
        display: none;
        opacity: 0;
    }
}

body.fullpage {
    header {
        position: fixed;
        width: 100%;
        z-index: 100;
    }
}

// Decorative 
#dot-grid,
.dot-grid {
    background-image: url(../img/bg/dot-grid.svg);
    background-position: center 0.1%;
    background-repeat: repeat;
    background-size: 140px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;

    @include min-h(700px) {
        background-position: center 0.3%;
    }

    @include portrait {
        background-position: center 0.85%;
    }

    @include landscape {
        background-size: 200px;
        background-position: center 0.6%;
    }

    @include desktop {
        background-position: center 0.75%;
    }
}

.dot-grid {
    opacity: 0.4;
}
