@import './../settings';
@import './../animations';

@mixin full-height-overlay {
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.blog-carousel {
  margin-top: 50px;

  &__controls {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 35px 0;
    position: relative;
    width: 100%;

    h2 {
      margin: 0 !important;
    }

    .slick-prev,
    .slick-next {
      position: relative !important;
      z-index: 300;
      top: 15px;
      left: 0;
    }

    .slick-prev {
      margin-right: 25px;
    }

    .slick-next {
      margin-left: 25px;
    }
  }
}

.blog-post {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 6px;
  position: relative;
  height: 100%;
  width: 100%;

  @include min($desktop) {
    margin: 0 12.5px;
  }

  &:hover {
    cursor: pointer;
  }

  &__overlay {
    backdrop-filter: blur(35px) brightness(0.9);
    background: rgba(39,70,204,0.8);
    bottom: 0;
    display: flex;
    height: 0;
    opacity: 0;
    padding: 25px;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    width: 100%;
    visibility: hidden;
  }

  &__overlay-close {
    align-items: center;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    height: 37px;
    width: 37px;
    padding: 0;
    position: absolute;
    top: 15px;
    left: 15px;

    &:hover {
      cursor: pointer;
    }

    span {
      line-height: 0;
    }

    svg {
      height: 27px;
      width: 27px;
      transform: rotate(45deg);
    }
  }

  &__overlay-content {
    align-items: center;
    align-self: center;
    display: flex;
    justify-self: center;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    opacity: 0;

    @include max(1000px) {
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px;
      overflow: auto;
      margin-bottom: 0;
    }
  }

  &__body-title,
  &__date {
    margin: 0;
  }

  &__body {
    margin: 25px 0;

    @include max(1000px) {
      font-size: 14px;
    }
  }

  &__footer {
    align-items: center;
    backdrop-filter: blur(35px) brightness(0.9);
    background: rgba(39,70,204,0.8);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    padding: 18px;
    position: absolute;
    transform: translateY(0);
    width: 100%;
    visibility: hidden;

    @include min($mobile) {
      flex-direction: row;
      padding: 25px;
    }
  }

  &__title {
    margin: 0;

    @include max(600px) {
      margin: 0 0 5px;
      font-size: 15px;
    }
  }

  &__read-more {
    align-items: center;
    display: flex;
    font-weight: 900;
    text-transform: uppercase;

    @include max(600px) {
      font-size: 14px;
    }

    &:hover {
      cursor: pointer;
    }

    figure {
      margin: 1em 0 1em 10px;
      @include max(600px) {
        margin: 0;
      }
    }

    figcaption {
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }

    img {
      width: 26px;
      height: 26px;
      margin-left: 10px;
    }
  }

  &.slick-current:not(.blog-post--overlay-is-open) {
    .blog-post__footer {
      visibility: visible;
      animation: slide-in-up .5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
  }

  &:not(.slick-current) {
    .blog-post__overlay {
      @include full-height-overlay;
    }

    &:not(.blog-post--overlay-is-open) {
      .blog-post__overlay-content,
      .blog-post__overlay-close {
        visibility: hidden;
      }
    }
  }

  &--overlay-is-open {
    .blog-post__overlay {
      @include full-height-overlay;
    }

    .blog-post__overlay-content {
      opacity: 1;
    }

    .blog-post__footer {
      animation: slide-out-down .5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
      opacity: 0;
      visibility: hidden;
    }
  }
}
