$animation-duration: 0.2s;

.form {
    max-width: 805px;
    margin: auto;
    width: 100%;
    padding-top: 35px;

    @include gutter;

    @include desktop {
        @include no-gutter;
    }

    button,
    .button {
        margin-top: 1.5rem;
    }

    &__subtitle {
        display: flex;
        justify-content: center;
        text-align: center;

        @include min(desktop) {
            padding: 8px 35px;
        }
    }
}

.message {
    @include panel-blue;
    padding: 0;
    list-style: none;
    margin: 0 0 2rem;
    padding: 0.5rem 0.8rem;
    font-size: $font-size;
    position: relative;

    &:after {
        display: block;
        @include fa-style;
        content: '\f0f3';
        position: absolute;
        right: 0;
        top: 6px;
    }

    &.error:after {
        content: '\f071';
        right: 3px;
        top: 6px;
        font-size: 14px;
    }

    &.success:after {
        content: '\f058';
        font-weight: 500;
        right: 3px;
    }
}

::-webkit-input-placeholder {
    color: $muted-white;
}

::-moz-placeholder {
    color: $muted-white;
}

:-ms-input-placeholder {
    color: $muted-white;
}

:-moz-placeholder {
    color: $muted-white;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
}

@keyframes next {
    0% {
        transform: translate(0%, -50%);
    }

    50% {
        transform: translate(30%, -50%);
    }

    100% {
        transform: translate(0%, -50%);
    }
}

button,
.button {
    border: 1px solid #fff;
    outline: none;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    line-height: 1.2;
    padding: 16px 22px 14px;
    font-size: $input-font-size;
    @include font-bold;
    text-transform: uppercase;
    cursor: pointer;
    @include button-hover;

    &.search {
        @include icon-style;

        &:after {
            width: 22px;
            height: 22px;
            background-image: url(../img/icons/search.svg);
        }
    }

    &.filter {
        @include icon-style;

        &:after {
            width: 21px;
            height: 19px;
            background-image: url(../img/icons/filter.svg);
        }
    }

    &.close,
    &.clear {
        @include icon-style;

        &:after {
            @include fa-style;
            content: '\f00d';
            font-weight: 100;
        }
    }

    &.back {
        @include icon-style;

        &:after {
            @include fa-style;
            content: '\f053';
        }
    }

    &.next {
        @include icon-style;

        &:after {

            @include fa-style;
            content: '\f054';
            width: 15px;
        }

        &:hover,
        &:focus {
            &:after {
                animation-name: next;
                animation-duration: 1s;
                animation-iteration-count: infinite;
            }
        }
    }

    &.icon-only {
        font-size: 0;
        line-height: 0;
        width: 37px;
        height: 35px;
        padding: 0;

        &:after {
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
        }

        &.search:after {
            margin-right: -1px;
        }
    }

    &.icon-only.next {
        &:after {
            animation-name: unset;
            animation-duration: unset;
            animation-iteration-count: unset;
        }
    }
}

input {

    &[type=text],
    &[type=email] {
        background: transparent;
        @include font-bold;
        width: 100%;
        border: none;
        border-bottom: 1px solid #fff;
        font-size: $input-font-size;
        color: #fff;
        text-transform: uppercase;
        line-height: $line-height;

        &:focus {
            outline: none;
        }
    }
}

textarea {
    background: transparent;
    @include font-regular;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-top-color: rgba(255, 255, 255, 0);
    border-left-color: rgba(255, 255, 255, 0);
    border-right-color: rgba(255, 255, 255, 0);
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0.35rem;
    margin-bottom: 2rem;
    height: 1rem;
    transition: all $animation-duration;
    outline: none;

    &:focus {
        border-color: rgba(255, 255, 255, 1);
        min-height: 10rem;
    }
}

.is-filled {
    textarea {
        border-color: rgba(255, 255, 255, 1);
        min-height: 10rem;
    }

    .required:after {
        opacity: 0;
    }
}

label {
    text-transform: uppercase;
    @include font-bold;
    padding: 18px 0 0 0;
    display: block;
    font-size: $input-font-size;
    margin-bottom: 0.45rem;

    &.required {
        position: relative;

        &:after {
            @include fa-style;
            content: '\f069';
            position: absolute;
            right: 0;
            font-size: 4px;
            width: 4px;
            top: 14px;
            transition: all $animation-duration;
        }
    }
}

small {
    .fas {
        font-size: 4px;
        height: 4px;
        transform: translateY(-6px);
        margin-right: 2px;
    }
}

// Fancy inputs

@mixin label-active {
    font-size: 0.8rem;
    transform: translateY(-20px);
    opacity: 0.8;
}

.form-field {
    display: block;
    margin-bottom: 1rem;
    margin-bottom: 2rem;

    .control {
        overflow: hidden;
        position: relative;
        width: 100%;

        &:after {
            content: '';
            display: block;
            transition: all $animation-duration;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: #fff;
            transform: scaleX(0);
            transform-origin: left;
        }

        label {
            left: 0;
            margin: 0;
            position: absolute;
            top: 0;
            transition: all $animation-duration;
            width: 100%;
        }

        input {
            appearance: none;
            background: transparent;
            border: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.8);
            display: block;
            margin-top: 20px;
            outline: 0;
            padding: 0 0 0 0;
            width: 100%;
        }
    }

    &.is-active {
        .control {
            &:after {
                transform: scaleX(1);
            }
        }

        label {
            @include label-active;
        }
    }

    &.is-filled {
        label {
            @include label-active;
        }
    }
}

#contact.open {
    display: flex;
    flex-direction: column;

    .dot-grid {
        z-index: -1;
    }
}

#contact {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    overflow-y: auto;
    overflow-x: hidden;

    @include mobile-only {
        padding-bottom: 3.5rem;
    }

    @include max-h(800px) {
        padding: 80px 0;
    }

    @media (max-height: 799px),
    (max-width: 1099px) {
        padding-top: 100px;
    }

    h2 {
        text-align: center;
        @include sub-title;
        @include gutter;
        margin: 3rem 0;
        line-height: 1.1;

        &.form__subtitle-tag {
            @include sub-title-light;
            line-height: 1.2;
            margin-left: auto;
            margin-right: auto;
            max-width: 600px;
        }
    }
}

// Google recaptcha
@include max-h(1000px) {
    .grecaptcha-badge {
        bottom: -200px !important;
    }
}
