// Fix for stupid slick sliders
section {
    min-width: 0;
}

.slick-slide:focus {
    outline: none;
}

.slick-arrow {
    z-index: 10;
    
    @include slider-nav-button;
    top: 47%;

    &.slick-next {
        @include slider-nav-next;
    }

    &.slick-prev {
        @include slider-nav-prev;
    }

    &.slick-disabled {
        border-color: rgba(255, 255, 255, 0.1);
        pointer-events: none;
        box-shadow: unset;
    }
}

.slick-dots {
    li {
        margin: 0;
        width: 12px;

        button {
            display: flex;
            padding: 0;

            &:before {
                color: #fff;
                opacity: 1;
                content: '';
                width: 8px;
                height: 8px;
                border: 1px solid #fff;
                border-radius: 100%;
                margin: auto;
                position: static;
            }

            box-shadow: none;
        }

        &.slick-active {
            button {
                &:before {
                    background-color: #fff;
                    opacity: 1;
                }
            }
        }
    }
}