.tiles {
    display: grid;
    grid-gap: 2rem;

    @include portrait {
        @include grid-2;
    }

    @include landscape {
        @include grid-1;
    }
}

.tile {
    width: 100%;
    padding-bottom: 100%;
    background: rgba(0, 0, 0, 0.2);
    height: 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    h4 {
        @include sub-title;
        line-height: 0.9;
        margin-top: 0;
    }

    p {
        font-size: $font-size;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    &.blue {
        @include panel-blue;
    }

    &.purple {
        @include panel-purple;
    }

    &.contact {
        p {
            margin-bottom: 3rem;
        }
    }

    .wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        padding: 2rem;

        .inner {
            margin: auto;
            width: 100%;
        }
    }

    .button {
        @include medium-only {
            padding-right: 40px;
        }
    }

    .slick-next {
        right: -10px;

        @include large {
            right: 0;
        }
    }

    .slick-prev {
        left: -10px;

        @include large {
            left: 0;
        }
    }

    .text-slider {
        h2 {
            line-height: 0.9;
            font-size: 10vw;
            margin: 0 0 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            @include portrait {
                font-size: 4.5vw;
            }

            @include landscape {
                font-size: 2.5vw;
            }
        }
    }

    .services-slider-inner {
        h4 {
            margin-bottom: 0;
            line-height: 1.1;

            &.services-slider-heading {
                @media (min-width: 1150px) and (max-height: 900px) and (max-width: 1299px),
                (min-width: 1400px) and (max-height: 900px) {
                    display: none;
                }
            }
        }

        .button {
            @include large {
                margin-top: 1rem;
            }
        }
    }

    .services-slider {
        img {
            height: 60px;
            margin: 1rem 0;
        }

        h4 {
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }
    }

    .slick-dots {
        opacity: 0.5;
        bottom: -40px;
    }
}

.contact-us-tile {
    h4 {
        margin-bottom: 0;
    }

    &__buttons {
        button {
            border: none;
            padding: 0.5rem;

            &:hover {
                cursor: pointer;
            }

            &:first-child {
                position: relative;

                &:after {
                    content: "";
                    height: 20px;
                    width: 2px;
                    background-color: white;
                    position: absolute;
                    right: -3px;
                    top: 6px;
                }
            }
        }
    }
}