// Colours
$colour-text: #fff;
$colour-primary: #0E67CB;
$colour-header: rgba(25, 50, 148, 0.7);
$muted-white: rgba(255, 255, 255, 0.66);

// Dimensions
$header-regular: 100px;
$header-large: 200px;


// Screen Sizes
$mobile: 700px;
$desktop: 1100px;
$largeDesktop: 1500px;

// Font size
$font-size: 0.83rem;
$input-font-size: 0.865rem;
$line-height: 1.8;

// Screen settings 'mobile first'

@mixin min($size) {
    @media only screen and (min-width:$size) {
        @content
    }
}

@mixin max($size) {
    @media only screen and (max-width:$size) {
        @content
    }
}

@mixin min-h($size) {
    @media only screen and (min-height:$size) {
        @content
    }
}

@mixin max-h($size) {
    @media only screen and (max-height:$size) {
        @content
    }
}

@mixin desktop {
    @media only screen and (min-width: 1100px) and (min-height: 650px) {
        @content;
    }
}

@mixin tiny {
    @media only screen and (max-width: 370px) {
        @content;
    }
}

@mixin portrait {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin landscape {
    @media only screen and (min-width: 1024px) {
        @content;
    }
}

@mixin mobile-only {
    @media only screen and (max-width: 1099px), (max-height: 649px) {
        @content;
    }
}

@mixin filters-medium {
    @media only screen and (min-width: 1000px) and (max-height: 649px) {
        @content;
    }
}

@mixin medium {
    @media only screen and (min-width: 1300px) and (min-height: 850px) {
        @content;
    }
}

@mixin medium-only {
    @media only screen and (min-width: 1024px) and (max-width: 1500px) {
        @content;
    }
}


@mixin large {
    @media only screen and (min-width: 1500px) and (min-height: 850px) {
        @content;
    }
}

@mixin x-large-height {
    @media only screen and (min-height: 1140px) {
        @content;
    }
}

// Foundation specific screen settings
$breakpoints: (small: 0,
    medium: 768px,
    large: 1100px);

// Animation
$ease: ease-out;
