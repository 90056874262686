#desktop {
    display: none;

    @include desktop {
        display: table;
    }
}

body.profile {
    .form {
        margin-top: 0;
    }
    #contact h2 {
        margin-top: auto;
        padding-top: 2rem;
    }
}

#selected-character-details.single {
    display: block;
    @include gutter;

    article {
        display: flex;
        height: unset;
        max-height: unset;
        position: static;
        transform: none;

        section {
            &.image {
                .featured {
                    @include large {
                        transform: translate(-50%, -50%);
                    }
                }

                .smoke {
                    transform: translate(-50%, 0%) scale(1.5) !important;
                    margin-bottom: -70px;

                    @include min(1500px) {
                        transform: translate(-50%, 0%) scale(1) !important;
                    }

                    @include min(1600px) {
                        margin-bottom: -140px;
                        transform: translate(-50%, 0%) scale(0.8) !important;
                    }

                    @media (min-width: 1500px) and (min-height: 1000px) {
                        margin-bottom: -120px;
                        transform: translate(-50%, 0%) scale(1) !important;
                    }

                    @include x-large-height {
                        margin-bottom: -140px;
                    }

                }
            }
        }
    }

    // Styles for version without a carousel at the bottom, so has more space
    &.no-carousel {
        .side.right {
            @include grid-1;
            grid-template-rows: unset;
        }

        .panel.purple:first-child {
            grid-column: unset;
        }

        +#backdrop {
            transform: translate(-50%, -25%);

            +#light-background {
                transform: translate(-50%, -43%);

                @include large {
                    transform: translate(-50%, -43%);
                }
            }
        }

        .blue {
            padding-right: 1.1rem;

            .bio {
                max-height: 300px;
            }
        }

        article {
            section {
                &.image {
                    .featured {
                        max-height: 700px;
                        height: 90vh;
                    }
                }
            }
        }
    }
}


#gallery {
    text-align: center;

    .container {
        @include desktop {
            display: grid;
            @include grid-2;
            height: 100%;
        }
    }

    .gallery-slides {
        min-width: 0;
        opacity: 0;

        .slick-next {
            top: 50%;
            right: 5%;
            z-index: 1;

            @include desktop {
                right: 95px;
            }

        }

        .slick-prev {
            left: 5%;
            top: 50%;
            z-index: 1;

            @include desktop {
                left: 95px;
            }
        }
    }

    .gallery-photo {
        height: 50vh;
        min-height: 300px;
        background-position: center;
        background-size: cover;

        @include desktop {
            height: 100vh;
        }
    }

    .profile {
        margin: auto;
        padding: 30px 20px;
        max-width: 740px;
        width: 100%;
        min-width: 0;
        opacity: 0;

        @include portrait {
            padding: 50px;
        }

        h1 {
            margin-bottom: 0;

            @include large {
                @include title;
                margin-top: 0;
                margin-bottom: 1.8rem;
            }
        }

        &.has-award {
            .full-bio {
                margin-bottom: 5rem;

                &.mCustomScrollbar {
                    max-height: calc(100vh - 550px);
                }
            }
        }
    }

    .full-bio {
        font-size: 0.9rem;
        line-height: 1.8;
        min-height: 100px;

        &.mCustomScrollbar {
            max-height: calc(100vh - 450px);
        }

        p {
            &:first-of-type {
                font-weight: bold;
            }
        }
    }

    .awards {
        margin: 2rem 0 3rem;

        @include portrait {
            margin: 4rem 0 5rem;
        }

        @include desktop {
            margin: 2rem 0 3rem;
        }

        @include x-large-height {
            margin: 4rem 0 5rem;
        }

        figure {
            margin: 0;
        }

        img {
            margin-bottom: 0.5rem;
            max-height: 45px;
        }

        figcaption {
            font-weight: bold;
            margin: 0.6rem 0;
        }
    }
}

#stats {
    text-align: center;

    .container {
        @include gutter;
        margin: auto;
        padding-left: 6%;
        padding-right: 6%;

        @include min(450px) {
            padding-right: calc(6% - 0.7rem);
            padding-left: calc(6% - 0.7rem);
        }

        @include desktop {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 3rem;
            padding-right: calc(5% - 0.7rem);
            padding-left: calc(5% - 0.7rem);
        }

        

        @media (min-width: $desktop) and (max-height: 950px) {
            max-width: 1300px;
        }

        @include min(1600px) {
            max-width: 1400px;
        }

        @include min(1900px) {
            max-width: 1650px;
        }

        @include x-large-height {
            max-width: 2180px;
        }


    }

    .title {
        display: flex;

        h2 {
            @include font-regular;
            font-weight: bold;
            margin: auto;
            line-height: 5;
            font-size: 23px;

            @include desktop {
                max-width: 100px;
                font-size: 16px;
                line-height: 1;
            }

            @include medium {
                max-width: 140px;
                font-size: 23px;
            }
        }
    }

    .stats-carousel,
    .games-carousel {
        min-width: 0;

        .slick-arrow {
            left: -137px;
            top: 50%;

            @include desktop {
                left: -115px;
            }

            @include medium {
                left: -137px;
            }
        }

        .slick-next {
            margin: 80px 0 0;

            @include desktop {
                margin: 50px 0 0;
            }

            @include medium {
                margin: 80px 0 0;
            }
        }

        .slick-prev {
            margin: -80px 0 0;

            @include desktop {
                margin: -50px 0 0;
            }

            @include medium {
                margin: -80px 0 0;
            }
        }

        .slick-dots {
            @include desktop {
                visibility: hidden;
            }
        }
    }

    .slick-dotted.slick-slider {
        margin: 0;
    }

    .slick-dots {
        bottom: unset;
        top: -40px;
    }

    .stat-slide {
        margin: 0;

        @include min(450px) {
            margin: 0.7rem;
        }

        figure {
            margin: 0;

            figcaption {
                text-transform: uppercase;
                @include font-bold;
                margin: 12px 0;
                line-height: 1.2;
                font-size: 22px;

                @include min(450px) {
                    font-size: 16px;
                }

                @include min(700px) {
                    font-size: 2vw;
                }

                @include min(1100px) {
                    font-size: 1.2vw;
                }

                @include min(1900px) {
                    font-size: 20px;
                }
            }
        }

        img {
            width: 25vw;
            margin: 12px 0;

            @include min(450px) {
                width: 50px;
            }

            @include min(700px) {
                width: 7vw;
            }

            @include min(1100px) {
                width: 4vw;
                max-width: 60px;
            }
        }

        &.game {
            img {
                width: auto;
                height: auto;

                @include min(450px) {
                    width: 100px;
                }

                @include min(700px) {
                    width: 14vw;
                }

                @include min(1100px) {
                    width: 8vw;
                    max-width: 200px;
                }
            }
        }

        &.placeholder {
            img {
                opacity: 0.2;
                width: 65%;
            }
        }

        img.placeholder-logo {
            opacity: 0.2;
        }

        .tile {
            .wrap {
                padding: 10%;
            }
        }

        &.influence {
            @include min(450px) {
                grid-column: span 2;
            }

            .tile {
                @include min(450px) {
                    padding-bottom: calc(50% - 0.7rem);
                }
            }

            h2 {
                font-size: 2rem;
                margin: 0 0 0.5rem;

                @include desktop {
                    font-size: 2vw;
                    margin: 0;
                }

                @include medium {
                    font-size: 2.5vw;
                }

                @media (min-width: $desktop) and (max-height: 950px) {
                    font-size: 2rem;
                }
            }
        }

        &.twitter {
            @include min(450px) {
                grid-column: span 2;
            }

            .tile {
                @include min(450px) {
                    padding-bottom: calc(50% - 0.7rem);
                }

                @include desktop {
                    padding-bottom: 100%;
                }
            }

            @include desktop {
                grid-column: span 1;
            }
        }
    }

    .social-row {
        display: grid;
        grid-gap: 1.5rem;

        @include min(450px) {
            @include grid-2;
            grid-gap: 1.4rem;
            margin: 0 0.7rem;

            .stat-slide {
                margin: 0;
            }
        }

        @include min(700px) {
            @include grid-3;
        }

        @include desktop {
            @include grid-5;
            grid-gap: 0;
            margin: 0;

            .stat-slide {
                margin: 0.7rem;
            }
        }

    }
}
