.toggle {
  display: inline-block;
  width: 220px;

  &:hover {
    cursor: pointer;
  }

  &__container {
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;

      .toggle__switch {
        background: rgba(26, 117, 219, 0.2);
      }
    }
  }

  &__switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    border: 1px solid white;
    border-radius: 1px;
    width: 100%;
    height: 45px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;

    &:before,
    &:after {
      content: "";
    }

    &:before {
      display: block;
      background: #1a75db;
  		border-radius: 1px;
  		width:  calc(50% - 5px);
  		height: 33px;
  		position: absolute;
  		top: 5px;
  		left: 5px;
  		transition: left 0.25s;
    }

    .toggle:hover &:before {
      background: #156ccf;
	  }

  	.toggle__checkbox:checked + & {
  		&:before {
  			left: 50%;
  		}
  	}
  }

  &__checkbox {
    position: absolute;
    visibility: hidden;
  }

  &__label {
    position: relative;
    width: 50%;
    text-align: center;
  }

  &--is-hidden {
    display: none;
  }
}
