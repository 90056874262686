#loading {
    @include main-background;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    display: flex;

    #container {
        width: 80%;
        margin: auto;
        text-align: center;

        @include desktop {
            width: 50%;
        }
    }

    #loading-bar {
        background: rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    .progress {
        height: 3px;
        background: #fff;
        width: 0.01%;
        position: relative;
    }

    .count {
        @include font-bold;
        font-size: 2rem;
        height: 2rem;
    }
}