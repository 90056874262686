.fp-bg {
    background-size: cover;
    background-position: center center;
}

#slide-1 {
    .fp-bg {
        background-image: url(../img/hero/hero1.jpg);
        background-position: center top;
    }
}

#slide-2 {
    .fp-bg {
        background-image: url(../img/hero/hero2.jpg);
        background-position: center bottom;
    }
}

#slide-3 {
    .fp-bg {
        background-image: url(../img/hero/hero3.jpg);
    }
}

#slide-4 {
    .fp-bg {
        background-image: url(../img/hero/hero4.jpg);
    }
}

#slide-5 {
    .fp-bg {
        background-image: url(../img/hero/group1.jpg);
    }
}

#slide-6 {
    .fp-bg {
        background-image: url(../img/hero/group2.jpg);
    }
}

#image-1 {
    .fp-bg {
        background-image: url(../img/hero/smix1.jpg);
    }
}

#image-2 {
    .fp-bg {
        background-image: url(../img/hero/talent1.jpg);
    }
}

#image-3 {
    .fp-bg {
        background-image: url(../img/hero/talent2.jpg);
    }
}

#hero-slider {
    .slide {
        text-align: center;

        h2 {
            font-size: 2rem;
            padding: 0 2rem;
            line-height: 1;
            position: relative;
            z-index: 1;

            @media (min-width: 768px) and (min-height: 768px) {
                font-size: 4rem;
                max-width: 1000px;
                margin: auto;
            }
        }

        &.spotlight {
            .fp-bg {
                background-image: url(../img/bg/spotlight.jpg);
                background-color: #163091;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                @media (min-width: 700px) and (max-height: 400px) {
                    background-size: 650px;
                    background-position: center 0;
                }

                @media (min-width: 1024px) and (min-height: 768px) {
                    background-position: center 0;
                    background-size: 1250px;
                }

                @include desktop {
                    background-size: cover;
                }
            }

            .featured {
                max-width: 420px;
                margin-bottom: -50px;
                margin-top: 50px;
                opacity: 0.8;
                width: 100%;

                @include max-h(567px) {
                    width: 200px;
                }

                @include min(700px) {
                    width: 250px;
                }

                @media (min-width: 414px) and (min-height: 630px) {
                    width: 420px;
                    max-width: 100%;
                }

                @media (min-width: 768px) and (min-height: 1024px) {
                    width: 600px;
                }

                @media (min-width: 1024px) and (min-height: 768px) {
                    width: 500px;
                }

                @media (min-width: 1200px) and (min-height: 870px) {
                    width: 600px;
                }

                @media (min-width: 1300px) and (min-height: 1000px) {
                    width: 700px;
                }

                &.family {
                    @include min(700px) {
                        width: 600px;
                        max-width: 100%;
                    }
                    @media (min-width: 414px) and (min-height: 630px) {
                        width: 700px;
                        max-width: 100%;
                    }
                    @media (min-width: 1024px) and (min-height: 768px) {
                        width: 800px;
                    }

                    @media (min-width: 1200px) and (min-height: 870px) {
                        width: 1250px;
                    }
                }
            }

            .smoke {
                position: absolute;
                max-width: unset;
                width: 560px;
                left: 50%;
                bottom: 50%;
                transform: translate(-50%, 50%);
                margin-bottom: -135px;

                @media (max-height: 567px),
                (max-width: 319px) {
                    display: none;
                }

                @include min(700px) {
                    width: 420px;
                }

                @media (min-width: 414px) and (min-height: 630px) {
                    width: 560px;
                }

                @media (min-width: 768px) and (min-height: 1024px) {
                    width: 750px;
                    margin-bottom: -155px;
                }

                @media (min-width: 1024px) and (min-height: 768px) {
                    width: 750px;
                    margin-bottom: -155px;
                }

                @media (min-width: 1200px) and (min-height: 870px) {
                    width: 900px;
                    margin-bottom: -185px;
                }

                @media (min-width: 1300px) and (min-height: 1000px) {
                    margin-bottom: -235px;
                }
            }
        }
    }

}

.mousemove-container {
    overflow: hidden;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

#image-slider {
    position: relative;
    h1 {
        @include title;
        margin: auto;
        max-width: 1000px;
        text-align: center;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}