.cms-page {
  @include gutter;

  

  @include min(1300px) {
    max-width: 80%;
  }

  margin-bottom: 100px;

  &__title h1 {
    margin-bottom: 35px;
    @include title;
  }
}

body.page {
  position: relative;
  overflow-x: hidden;

  .dot-grid {
    z-index: -1;
    position: fixed;
    background-position: 49.5% 94px;
  }

    #header {
        z-index: 100;
        position: relative;
    }
}