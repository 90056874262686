@mixin gutter {
    padding-left: 6%;
    padding-right: 6%;

    @include desktop {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@mixin force-full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin transition {
    transition: all 2s $ease;
}

@mixin transition-fast {
    transition: all 0.5s $ease;
}

@mixin grid-1 {
    grid-template-columns: repeat(1, 1fr);
}

@mixin grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

@mixin grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

@mixin grid-4 {
    grid-template-columns: repeat(4, 1fr);
}

@mixin grid-5 {
    grid-template-columns: repeat(5, 1fr);
}

@mixin grid-6 {
    grid-template-columns: repeat(6, 1fr);
}

@mixin full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin no-gutter {
    padding-left: 0;
    padding-right: 0;
}

@mixin panel-blue-purple {
    transition: all .2s $ease;
    backdrop-filter: blur(35px) contrast(1.5) brightness(0.8);
    background: linear-gradient(90deg, rgba(0,168,253,0.4) 0%, rgba(185,130,252,0.4) 100%);
}

@mixin nav-bg {
    transition: all .5s $ease;
    backdrop-filter: blur(35px) contrast(1.5) brightness(1);
    background: linear-gradient(90deg, rgba(0,168,253,0.4) 0%, rgba(185,130,252,0.4) 100%);
}

@mixin panel-blue {
    background: rgba(0,168,253,0.4);
}

@mixin panel-purple {
    background: rgba(185,130,252,0.4);
}

@mixin panel-dark-blue {
    backdrop-filter: blur(6px) brightness(1.1);
    transition: all .26s $ease;
    background: rgba(39,70,204,0.65);

    @include desktop {
        backdrop-filter: blur(18px) brightness(1.1);
        transition: all .2s $ease;
    }
}

@mixin glow {
    box-shadow: 0 0 5px rgba(255,255,255,0.3);
}

@mixin button-hover {
    &:focus,
    &:hover {
        background: rgba(255, 255, 255, 0.1);
        @include glow;
        border-color: rgba(255,255,255,0.9);
    }
}

@mixin main-background {
    background: radial-gradient(circle, rgba(31, 95, 224, 1) 0%, rgba(21, 48, 145, 1) 100%);
    background-attachment: fixed;

    @include min-h(800px) {
        background: radial-gradient(circle, rgba(31, 95, 224, 1) 0%, rgba(21, 48, 145, 1) 85%);
        background-attachment: fixed;
    }
}

@mixin filter-panel-shadow {
    box-shadow: 0 20px 30px 20px rgba(0,0,0,0.2);
    
    @include desktop {
        box-shadow: 0 20px 30px 20px rgba(0,0,0,0.1);
    }
}

@mixin fade-bottom {
    -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
    -webkit-mask-mode: luminance;
}

@mixin fade-bottom-unset {
    -webkit-mask-image: unset;
    -webkit-mask-mode: unset;
}

@mixin fade-edges {
    -webkit-mask-image: linear-gradient(to left, transparent 0%, black 20%, black 80%, transparent 100%);
    -webkit-mask-mode: luminance;
}

@mixin sparkle-mask {
    -webkit-mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, black 40%, transparent 45%);
    -webkit-mask-mode: luminance;
}

@mixin slide-container {
    @include gutter;
    grid-gap: 2rem;

    @include min($desktop) {
        display: flex;
    }
}

@mixin slide-center {
    display: flex;

    @include landscape {
        flex: 1.4
    }

    @include min(1300px) {
        flex: 2;
    }

    @include x-large-height {
        flex: 1.8;
    }
}

@mixin slide-side {
    flex: 1;
    display: flex;
    flex-direction: column;
}


// ICON STYLES

@mixin slider-nav-next {
    &:before {
        content: '';
        border-style: solid;
        border-width: 4px 0 4px 9px;
        border-color: transparent transparent transparent #fff;
        font-size: unset;
        margin-left: 1px;
        display: inline-block;
        opacity: .75;
    }
}

@mixin slider-nav-prev {
    &:before {
        content: '';
        border-style: solid;
        border-width: 4px 9px 4px 0;
        border-color: transparent #fff transparent transparent;
        font-size: unset;
        margin-right: 1px;
        display: inline-block;
        opacity: .75;
    }
}

@mixin slider-nav-button {
    width: 34px;
    height: 34px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    text-align: center;
    line-height: 1.9;

    @include button-hover;

    &:hover {
        &:after,
        &:before {
            opacity: 1 !important;
        }
    }
}

@mixin icon-style {
    padding-right: 60px;
    position: relative;

    &:after {
        content: '';
        display: block;
        top: 50%;
        position: absolute;
        right: 18px;
        transform: translateY(-50%);
        background-repeat: no-repeat;
    }
}

@mixin fa-style {
    font-size: 16px;
    line-height: 22px;
    width: 22px;
    height: 22px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
}

// ANIMATIONS
@keyframes fade-in {
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
}

@mixin fade-in {
    animation-name: fade-in;
    animation-timing-function: linear;
    animation-duration: 0.5s;
    animation-delay: 0.1s;
    animation-fill-mode: backwards;
}
