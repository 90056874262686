body.services {
    .scroller {
        display: none;
    }
}

body.brand-services {
    #clients {
        .container {
            @include no-gutter;
        }

        h2 {
            @include title;
            margin: auto;
            max-width: 800px;
        }

        #client-logos {
            @include large {
                @include grid-6;
                column-gap: 6rem;
                row-gap: 4rem;
            }
        }
    }
}

#services {
    text-align: center;

    .container {
        display: grid;
        @include grid-1;
        height: calc(100% - 80px);
        margin-top: 80px;

        @include min-h(700px) {
            margin-top: 100px;
            height: calc(100% - 100px);
        }

        @include min($desktop) {
            margin-top: 0;
            height: 100%;
        }

        @include portrait {
            @include grid-2;
        }

        section {
            position: relative;
            display: flex;
            overflow: hidden;

            .inner {
                margin: auto;

                h2 {
                    @include title;
                    margin-bottom: 1.8rem;
                    opacity: 0;
                }

                .button {
                    opacity: 0;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    width: 100vw;
                    height: 50vh;
                    object-fit: cover;
                    object-position: center;
                    transform: scale(1.03);

                    @include portrait {
                        height: 100vh;
                        object-position: bottom;
                    }
                }
            }
        }
    }
}

#service-intro {
    text-align: center;

    .container {
        @include gutter;
        margin: 150px auto 90px;

        @media (min-height: 800px) and (min-width: $desktop) {
            margin: 0 auto;
        }

        @include large {
            max-width: 1500px;
        }

        @include x-large-height {
            max-width: 2000px;
        }
    }

    h1 {
        @include title;
        margin: auto auto 1.8rem;
        opacity: 0;
        font-size: 3rem;

        @include portrait {
            margin: auto auto 2.5rem;
        }

        @include large {
            margin: auto auto 1.5rem;
            max-width: 870px;
            font-size: 4rem;
        }


    }

    h2 {
        margin: auto;
        max-width: 1050px;
        @include sub-title;
        font-size: 1rem;
        margin: auto auto 1.8rem;
        opacity: 0;

        @include portrait {
            font-size: 2rem;
            margin: auto auto 2.5rem;
        }

        @include large {
            margin: auto auto 2.5rem;
        }

        @media (min-height: 800px) and (min-width: $desktop) {
            max-width: 1040px;
        }
    }

    h3 {
        @include sub-title-light;
        line-height: 1.2;
        margin: auto auto 3rem;
        opacity: 0;

        @media (min-height: 800px) and (min-width: $desktop) {
            display: none;
        }

        @media (min-width: 1500px) and (min-height: 1000px) {
            display: block;
            max-width: unset;
        }

    }

    .tile {
        opacity: 0;
    }

    .tiles {
        grid-gap: 1rem;
        margin-bottom: 3rem;
        margin-left: auto;
        margin-right: auto;

        @include portrait {
            @include grid-3;
        }

        @media (min-height: 800px) and (min-width: $desktop) {
            max-width: 670px;
        }

        @include large {
            max-width: 880px;
        }

        @media (min-width: 1500px) and (min-height: 950px) {
            max-width: 1130px;
        }

        @include x-large-height {
            grid-gap: 2.5rem;
            max-width: 1500px;
        }

        h4 {
            margin-bottom: 0;
            line-height: 1;

            @include portrait {
                font-size: 2.7vw;
            }

            @media (min-height: 800px) and (min-width: $desktop) {
                font-size: 1.3vw;
                margin-top: 1rem;
            }

            @include large {
                margin-bottom: 1.5rem;
                font-size: 2rem;
            }

            @media (min-width: 1500px) and (min-height: 950px) {
                display: block;
            }
        }

        img {
            width: 28vw;
            margin-bottom: 2rem;
            height: 80px;

            @media (min-height: 800px) and (min-width: $desktop) {
                margin-bottom: 0;
            }

            @include portrait {
                width: 8vw;
            }

            @include large {
                height: 80px;
                width: auto;

                @include x-large-height {
                    height: 150px;
                }
            }

            @media (min-width: 1500px) and (min-height: 950px) {
                margin-bottom: 2rem;
            }
        }

        p {
            display: none;

            @media (min-width: 1500px) and (min-height: 950px) {
                display: block;
            }
        }
    }
}

#image {
    text-align: center;
    overflow: hidden;

    .container {
        @include gutter;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: center;
        transform: scaleX(1.03);
        opacity: 0.7;
    }

    h1 {
        @include title;
        margin: auto;
        max-width: 1000px;
    }
}