body.character-select {

    @include desktop {
        overflow: hidden;
    }

    #header {
        position: relative;
        z-index: 10;
        background: transparent !important;

        @include portrait {
            height: 100px;
        }

        @include x-large-height {
            height: 200px;
        }
    }

    #footer {
        display: none;
    }

    main {
        position: relative;

        @include desktop {
            height: calc(100% - #{$header-regular});
            display: flex;
            flex-direction: column;
        }

        @include x-large-height {
            height: calc(100% - #{$header-large});
        }
    }

    #dot-grid {
        z-index: -1;

        @include desktop {
            position: fixed;
        }
    }
}

body.talent-services {
    #header {
        @include portrait {
            height: 100px;
        }

        @include x-large-height {
            height: 200px;
        }
    }
}

#selected-character-details,
#selected-character-details-static {
    display: none;
    position: relative;

    @include desktop {
        display: block;
        flex: 1;
    }

    article {
        height: calc(100vh - 390px);
        display: flex;
        position: absolute;
        left: 5%;
        right: 5%;
        display: none;
        max-width: 1800px;
        margin: 0 auto;
        opacity: 1;
        transform: translateX(0%);

        @include max-h(800px) {
            height: calc(100vh - 355px);
        }

        @include large {
            height: calc(100vh - 460px);
        }

        @include min-h(1100px) {
            top: 50%;
            transform: translate(0, -50%);
            height: unset;
            max-height: 600px;
        }

        &.selected {
            display: flex;
        }

        section {
            flex: 1.5;
            transform: translateX(0px);

            @include min(1500px) {
                flex: 1;
            }

            &.image {
                flex: 2;
                display: flex;
                opacity: 1;
                position: relative;

                @include min(1600px) {
                    flex: 2.8;
                }

                .featured {
                    opacity: 0.9;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -47%);
                    transform-origin: center;
                    z-index: -1;
                    max-width: 60vw;
                    max-height: 60vh;

                    &:hover {
                        filter: brightness(1.05) drop-shadow(0 0 15px rgba(118, 200, 241, 0.3));
                    }

                    @include max-h(800px) {
                        transform: translate(-50%, -42%);
                    }

                    @media (min-width: 1500px) and (min-height: 1000px) {
                        transform: translate(-50%, -42%);
                        max-height: 740px;
                        max-width: 100%;
                    }

                    @include min-h(1100px) {
                        transform: translate(-50%, -50%);
                    }
                }

                .smoke {
                    opacity: 0;
                    display: none;
                    z-index: 1;
                    opacity: 0.8;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0%) scale(1.5);
                    margin-bottom: -70px;
                    pointer-events: none;

                    @include desktop {
                        display: block;
                    }

                    @include min(1500px) {
                        transform: translate(-50%, 0%) scale(1);
                    }

                    @include min(1600px) {
                        margin-bottom: -140px;
                        transform: translate(-50%, 0%) scale(0.8);
                    }

                    @media (min-width: 1500px) and (min-height: 1000px) {
                        margin-bottom: -230px;
                        transform: translate(-50%, 0%) scale(1);
                    }

                    @include x-large-height {
                        margin-bottom: -140px;
                    }

                }
            }

            &.side {
                position: relative;
                z-index: 10;
                display: grid;
                grid-gap: 2rem;
                opacity: 1;

                @include max-h(800px) {
                    grid-gap: 1rem;
                }

                &.left {
                    grid-template-rows: 1fr;
                }

                &.right {
                    display: grid;
                    @include grid-2;
                    grid-gap: 10px;

                    @include min-h(850px) {
                        grid-template-rows: 1fr;
                    }

                    @include min-h(925px) {
                        @include grid-1;
                    }

                    @include min-h(1100px) {
                        grid-gap: 1.5rem;
                    }
                }
            }

            .buttons {
                display: grid;
                @include grid-2;
                grid-gap: 1.2rem;

                .button,
                button {
                    white-space: nowrap;
                    overflow: hidden;
                    padding-left: 10px;
                    padding-right: 10px;
                    text-align: center;

                    @include min(1750px) {
                        text-align: left;
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    &:after {
                        display: none;

                        @include min(1750px) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .panel {
        text-align: center;
        padding: 2rem 2.5rem 4rem;
        min-width: 0;
        margin: 6px;

        &.blue {
            @include panel-blue;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 2rem 0.5rem 2rem 1.5rem;

            @include max-h(800px) {
                padding: 1rem 0.5rem 1.5rem 1.5rem;
            }

            h2 {
                margin: 1.2rem 0 1rem;
                font-size: 1.8rem;
                padding-right: 15px;

                @include max-h(800px) {
                    margin: 0.6rem 0 1rem;
                    font-size: 1.5rem;
                }
            }

            .bio {
                @include max-h(800px) {
                    height: calc(100vh - 550px);
                }

                height: calc(100vh - 645px);
                overflow: hidden;
                @include fade-bottom;

                

                p:last-child {
                    margin-bottom: 2rem;

                    @include max-h(800px) {
                        margin-bottom: 1rem;
                    }
                }

                p:first-child {
                    margin-top: 0;
                }

                @include large {
                    height: calc(100vh - 679px);
                }

                @include min-h(1100px) {
                    height: unset;
                    max-height: 334px;
                }

                p {
                    font-size: $font-size;
                    line-height: $line-height;

                    @include max-h(800px) {
                        line-height: 1.6;
                        font-size: 0.8rem;
                    }
                }

                .mCSB_container {
                    margin-right: 15px;
                }

                .mCustomScrollBox {
                    .mCSB_scrollTools {
                        opacity: 0;
                    }

                    &:hover {
                        .mCSB_scrollTools {
                            opacity: 0.75;
                        }
                    }
                }


            }
        }

        &.profile {
            padding: 2rem;

            .bio {
                padding-bottom: 0;
                height: calc(100vh - 420px);

                @include large {
                    height: calc(100vh - 678px);
                }
            }
        }

        &.talent-services {
            padding: 2rem 0rem 2rem 1.5rem !important;

            @include mobile-only {
                padding: 2rem !important;
            }

            .mCSB_container {
                margin-right: 25px !important;
            }
        }

        &.purple {
            @include panel-purple;
            position: relative;
            padding: 1rem 0;
            display: flex;
            flex-direction: column;

            &:first-child {
                grid-column: span 2;

                @include min-h(925px) {
                    grid-column: span 1;
                }
            }

            @include min-h(900px) {
                padding: 1.5rem 0;
            }
        }

        &.slick-slider {
            padding-left: 0;
            padding-right: 0;

            .slick-slide {
                padding: 0 1rem;
            }
        }

        .slick-dots {
            bottom: -30px;

            @include min-h(1100px) {
                bottom: -25px;
            }
        }

        &.slick-dotted.slick-slider {
            margin-bottom: 0;
            padding-bottom: 2.3rem;
            min-width: 0;
        }

        &.games {
            display: flex;
            padding: 1rem 0;

            figcaption {
                display: none;
            }

            img {
                width: auto;
                height: 70px;
            }
        }

        .abilities-slide {
            display: grid !important;
            @include grid-2;
            row-gap: 20px;
            column-gap: 1rem;
            padding: 0 1.5rem;

            @include max-h(924px) {
                row-gap: 30px;
            }
        }

        .abilities-carousel {
            display: block;
            margin-top: auto;
            margin-bottom: auto !important;
        }

        .carousel {
            margin-top: auto;
            margin-bottom: auto !important;
        }

        .corner-decoration {
            position: absolute;
            background-image: url(../img/effects/corner-decoration.svg);
            background-size: cover;
            width: 6px;
            height: 6px;

            &.top-left {
                top: -6px;
                left: -6px;
            }

            &.top-right {
                top: -6px;
                right: -6px;
                transform: rotate(90deg);
            }

            &.bottom-right {
                bottom: -6px;
                right: -6px;
                transform: rotate(180deg);
            }

            &.bottom-left {
                bottom: -6px;
                left: -6px;
                transform: rotate(-90deg);
            }
        }

        figure {
            margin: 0;

            img {
                width: 50px;
                height: 50px;
            }

            figcaption {
                text-transform: uppercase;
                @include font-bold;
                font-size: $font-size;
                line-height: 1.1;
                margin: 15px auto 0;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
                max-width: 120px;
            }
        }
    }
}

#selected-character-details-static {
    display: block;

    article {
        display: flex;
        position: static;
        @include gutter;
        transform: unset;
        max-width: unset;

        @include mobile-only {
            flex-direction: column;
            height: auto;
        }

        @include x-large-height {
            max-width: 2000px;
        }

        section {
            .buttons {
                @include grid-1;

                .button {
                    width: 140px;
                    margin: auto;
                }
            }

            &.image {
                flex-direction: column;
                transform: translate(0, 60px);

                @include mobile-only {
                    display: none;
                }

                h2 {
                    @include title;
                    text-align: center;
                    margin: auto auto 2.8rem auto;
                    max-width: 700px;
                    padding: 0 1rem;
                }

                .button {
                    margin: 0 auto auto auto;
                    width: 140px;
                }

                .smoke {
                    transform: translate(-50%, -60px);
                    z-index: -1;

                    @include x-large-height {
                        transform: translate(-50%, 160px);
                        width: 100%;
                    }
                }
            }

            &.side.right {
                @include mobile-only {
                    display: none;
                }
            }
        }

        .panel.blue {
            h2 {
                line-height: 1;
            }

            @include mobile-only {
                max-width: 500px;
                margin: 4rem auto 1rem;
                padding-bottom: 3rem;
            }

            .bio {
                overflow: auto;

                @include max-h(800px) {
                    height: calc(100vh - 510px);
                }

                @include mobile-only {
                    height: auto;
                }
            }
        }
    }
}

#characters-static {
    background-image: url(../img/bg/character-select.jpg);
    background-position: center;
    background-size: cover;

    @include mobile-only {
        padding-bottom: 3rem;
    }
}

// This prevents slick adding fade-in on carousel item change.
.carousel,
.abilities-carousel {
    .slick-slide img {
        opacity: 1 !important;
    }
}

// CHARACTER SELECTOR - BOTTOM AREA

#character-selector,
#character-selector-static {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    opacity: 0;

    h2 {
        @include sub-title;
        @include gutter;
        text-align: center;

        @include min(1300px) {
            font-size: 1.5rem;
        }

        @include min-h(800px) {
            font-size: 2rem;
        }
    }

    .slick-arrows {
        order: 1;

        @include mobile-only {
            h2 {
                margin: 18px 0;
            }
        }
    }

    @include desktop {
        .slick-arrows {
            order: 2;
            width: 320px;
            position: relative;

            h2 {
                @include no-gutter;
            }
        }
    }

    @include min-h(800px) {
        .slick-arrows {
            width: 420px;
        }
    }
}

#character-selector-static {
    opacity: 1;

    @include max-h(800px) {
        display: none;
    }

    @include mobile-only {
        display: none;
    }

    @include x-large-height {
        margin-top: 100px;
    }
}

#no-results {
    display: none;
    text-align: center;

    button {
        &.clear-all {
            padding: 12px 35px 11px 12px;

            &:after {
                right: 8px;
            }
        }
    }
}

// Thumbs
#character-carousel,
#character-carousel-static {
    display: grid;
    grid-gap: 11px;
    @include grid-4;
    @include gutter;
    margin: 0 0 150px;
    order: 3;
    overflow-y: hidden;
    transition: transform 0.2s ease-out;

    @include mobile-only {
        margin-bottom: 50px;
    }

    @include min(850px) {
        @include grid-5;
        margin-top: 1rem;
    }

    @include landscape {
        @include grid-6;
    }

    @include desktop {
        @include no-gutter;
        @include grid-1;
        margin: 0;
        order: 1;
        transition: none;
        @include fade-edges;
    }

    @include min-h(1080px) {
        width: 100%;
    }

    .slick-list {
        padding: 0 !important;
    }

    .slick-track {
        padding: 6px 0;
    }

    article {
        text-align: center;
        outline: none;
        cursor: pointer;

        &:hover {
            img {
                filter: url(#effect-purple);
            }

            a span {
                transform: translateY(0%);
            }
        }

        a {
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;
            text-decoration: none;

            @include desktop {
                border: 1px solid #fff;
                display: block;
                flex-direction: unset;
                pointer-events: none;
                margin: 0 2px;
                transform: scale(0.9);
                transition: all .25s $ease;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 0);
            }

            @media (min-height: 800px) and (min-width: 1200px) {
                margin: 5px;
            }

            span {
                text-transform: uppercase;
                display: block;
                @include font-bold;
                line-height: 1;
                font-size: 12px;
                order: 2;
                margin: 8px auto 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 60px;

                @include min(375px) {
                    max-width: 74px;
                }

                @include min(414px) {
                    max-width: 82px;
                }

                @include min(600px) {
                    max-width: unset;
                }

                @include desktop {
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgba(165, 130, 252, 0.7);
                    backdrop-filter: blur(7px);
                    padding: 10px;
                    transition: all .15s $ease;
                    transform: translateY(100%);
                    margin: 0;
                    font-size: 16px;
                }

                @media (min-width: 1200px) and (min-height: 950px) {
                    display: block;
                }

                @media (min-width: 1500px) and (max-height: 950px) {
                    display: block;
                }

                @include large {
                    display: block;
                }
            }

            .effect-purple,
            .effect-blue {
                position: absolute;
                width: 0;
                height: 0;
                top: 1px;
            }

            
        }

        img {
            width: 100%;
            border: 1px solid #fff;

            @include desktop {
                width: 90px;
                border: none;
            }

            @include large {
                width: 135px;
            }
        }

        @include desktop {
            &.slick-current {
                cursor: default;
                pointer-events: none;

                a {
                    transform: scale(1);
                    box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 1);

                    span {
                        background: rgba(0, 168, 253, 0.7);
                    }
                }

                img {
                    filter: url(#effect-blue);
                }
            }
        }
    }
}

#character-carousel-static {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 2px 0;
    overflow-x: hidden;

    @include max-h(800px) {
        display: none;
    }

    article {
        margin: 0 3px;
        cursor: default;

        &:nth-child(2) {
            order: 1
        }

        &:nth-child(3) {
            order: 2
        }

        &:nth-child(4) {
            order: 3
        }

        &:nth-child(5) {
            order: 4
        }

        &:nth-child(6) {
            order: 5
        }

        &:nth-child(7) {
            order: 6
        }

        &:nth-child(8) {
            order: 7
        }

        &:nth-child(9) {
            order: 8
        }

        &:nth-child(10) {
            order: 9
        }

        &:nth-child(11) {
            order: 10
        }

        img {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 0);
            transform: scale(0.9);
            min-width: 135px;
        }

        &:hover img {
            filter: none;
        }

        &.question-mark {
            order: 1;
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 255, 255, 0);
            margin: 0 10px;

            div {
                display: block;
                width: 135px;
                height: 135px;
                background: $colour-primary;
                position: relative;
                @include glow;

                &:after {
                    content: '\003F';
                    display: block;
                    color: #70BFF7;
                    @include font-black;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -48%);
                    font-size: 4vw;
                }
            }
        }
    }
}

#search-controls {
    order: 2;
    margin-bottom: 2rem;

    @include desktop {
        order: 3;
    }

    .clear-all {
        display: none;
    }

    .back {
        display: none;

        @include desktop {
            display: inline-block;
        }
    }
}

#character-search {
    @include panel-blue-purple;
    display: flex;
    position: fixed;
    bottom: -2rem;
    left: 0;
    right: 0;
    transform: translateY(100%);
    padding-top: 1.4rem;
    padding-bottom: 3.5rem;
    @include gutter;
    z-index: 100;
    @include filter-panel-shadow;



    &.is-open {
        transform: translateY(0%);
    }

    @include desktop {
        padding: 1.4rem 2.5rem 3.5rem;
    }

    .close {
        margin-right: 1rem;
        min-width: 35px;

        @include desktop {
            margin-right: 1.75rem;
        }
    }
}

#character-filters {
    @include gutter;
    @include panel-blue-purple;
    display: grid;
    grid-template-columns: 37px 1fr;
    grid-gap: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 1.4rem;
    padding-bottom: 2rem;
    transform: translateY(50%);
    @include filter-panel-shadow;
    z-index: -1;
    opacity: 0;
    height: calc(100vh - 244px);

    @include desktop {
        padding: 1.4rem 2.5rem 1.4rem;
        opacity: 1;
        height: auto;
        transform: translateY(100%);
    }

    @include filters-medium {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3rem;
    }

    #filter-categories {
        display: flex;
        flex-direction: column;

        @include desktop {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            display: grid;
            grid-gap: 0;
            flex-direction: unset;
        }

        @include large {
            grid-gap: 2rem;
        }

        div {
            margin-bottom: 2rem;

            @include desktop {
                padding: 0 1rem;
                margin-bottom: 0;
            }

            button {
                background: transparent;
            }
        }
    }
}

.filter-panel-toggle {
    display: block;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    text-align: left;
    line-height: 2.5;
    color: #fff;
    @include icon-style;
    padding: 0;

    &:after {
        @include fa-style;
        content: '\f078';
        width: 15px;
        height: 10px;
        right: 0;
        line-height: 10px;
        transition: all 0.2s $ease;
    }

    @include desktop {
        color: $muted-white;
    }

    &:focus,
    &:hover {
        color: #fff;
        box-shadow: unset;
        outline: none;
    }

    &.on {
        &:after {
            transform: scaleY(-1) translateY(5px);
        }
    }
}

#filter-panels {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    z-index: 999;

    @include desktop {
        padding: 1.4rem 2.5rem 1.5rem 5.8rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        display: grid;
        align-items: flex-start;
        grid-gap: 0;
        z-index: 10;

        @include large {
            grid-gap: 2rem;
        }
    }

    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:checked {
            +label {
                background: rgba(185, 130, 252, 0.4);

                &:hover,
                &:focus {
                    background: rgba(185, 130, 252, 0.5);
                }
            }
        }
    }

    label {
        display: flex;
        cursor: pointer;
        border: 1px solid #fff;
        padding: 7px 11px 6px 11px;
        margin-bottom: 0.75rem;
        position: relative;
        font-size: $font-size;
        text-transform: uppercase;
        background: rgba(39, 70, 204, 0.4);
        @include font-bold;
        @include button-hover;

        &:last-child {
            margin-bottom: 0;
        }

        @include desktop {
            padding: 12px;
        }
    }

    .category {
        padding-top: 1.4rem;
        @include gutter;
        padding-bottom: 1.4rem;
        @include panel-dark-blue;
        transform: translateY(0%, 0%);
        height: calc(100vh - 244px);
        opacity: 0;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: 1fr;
        align-items: flex-start;
        grid-gap: 1rem;
        pointer-events: none;
        position: relative;
        z-index: 2;
        position: fixed;
        width: 100%;

        @include desktop {
            padding-bottom: 125px;
            height: auto;
            opacity: 1;
            padding-left: 1rem;
            padding-right: 1rem;
            box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.5);
            transform: translate(0%, 0%);
            @include grid-1;
            position: unset;
        }

        @include filters-medium {
            max-width: 1000px;
            padding-top: 3rem;
            left: 50%;
            transform: translate(-50%, 0%);
        }

        &.is-open {
            transform: translate(0%, -100%);
            opacity: 1;
            pointer-events: all;

            @include filters-medium {
                transform: translate(-50%, -100%);
            }
        }

        .back {
            @include desktop {
                display: none;
            }
        }

        .custom-scrollbar .mCSB_scrollTools {
            right: 5px;
        }

        .custom-scrollbar .mCSB_inside > .mCSB_container {
            margin-right: 26px;
        }

        .custom-scrollbar .mCSB_scrollTools .mCSB_draggerContainer {
            top: 0;
            height: 100%;
        }

        .selected {
            display: none;

            @include desktop {
                grid-template-columns: 1fr auto;
                grid-gap: 1rem;

                &.on {
                    display: grid;
                }
            }

            .active-filters {
                display: flex;
                flex-direction: column;

                h5 {
                    margin: auto 0;
                    text-transform: capitalize;
                    @include font-bold;
                    font-size: 15px;

                    span {
                        @include font-bold;
                    }
                }

                span {
                    @include font-regular;
                    text-transform: capitalize;
                    font-size: $font-size;
                }
            }

            button {
                padding: 15px 30px 14px 12px;
                align-self: flex-start;

                &:after {
                    right: 5px;
                    top: 49%;
                }
            }
        }

        .filters {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: auto;
            scrollbar-width: none;
            padding-top: 1px;

            @include desktop {
                max-height: 400px;
            }

            fieldset {
                padding: 0;
                border: none;
                margin: 0;
            }

            &.fade-bottom {
                @include fade-bottom;

                &::-webkit-scrollbar {
                    width: 0px;
                    /* Remove scrollbar space */
                    background: transparent;
                    /* Optional: just make scrollbar invisible */
                }
            }

            .spacer {
                padding-top: 30px;
            }
        }

        .next {
            align-self: flex-end;
            grid-column: span 2;
            justify-self: flex-end;

            @include desktop {
                grid-column: span 1;
            }
        }
    }
}

.filters-are-open {
    #character-carousel {
        @include mobile-only {
            transform: translateY(20px) scale(0.95);
        }
    }

    #character-filters {
        transform: translateY(0%);
        opacity: 1;
        z-index: 100;
    }
}



// FIREFOX BUG FIXES

@-moz-document url-prefix() {

    #character-search,
    #character-filters {
        backdrop-filter: none;
        background: linear-gradient(90deg, #0047AF 0%, #5651BC 100%);
    }

    #filter-panels .category {
        background: rgba(39, 70, 204, 0.95);
        backdrop-filter: none;
    }

    #menu {
        background: rgba(39, 70, 204, 0.95);
        backdrop-filter: none;
    }
}

// ANIMATIONS
.slide-in-from-right {
    animation: slide-in-from-right .5s ease-in-out;
}

@keyframes slide-in-from-right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.fade-in {
    animation: fade-in .5s ease-in;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-edges {
    @include fade-edges;
}

#backdrop {
    opacity: 0;

    @mixin position-beams-top {
        position: absolute;
        top: -300px;
    }

    @mixin beam-color {
        filter: invert(53%) sepia(78%) saturate(462%) hue-rotate(164deg) brightness(100%) contrast(110%);
    }

    transform: translate(-50%, -50%);
    z-index: -1;
    width: 1000px;
    display: none;
    position: absolute !important;
    left: 50%;
    top: 50%;

    @include min(1500px) {
        width: 1290px;
    }

    @include desktop {
        display: flex;
        justify-content: center;
    }

    #glitter-sparkles {
        transform: translate;
        opacity: 0.7;
    }

    .right {
        @include position-beams-top;
        right: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-right: 160px;
    }

    .left {
        @include position-beams-top;
        left: 0;
        width: 100%;
        margin-left: 160px;
    }

    #light-floor {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0%);
    }

    #light-beam-front-left {
        @include beam-color;
        opacity: 0;

        img {
            transform: scaleX(-1);
        }
    }

    #light-beam-front-right {
        @include beam-color;
        opacity: 0;
    }

    #light-beam-back-left {
        @include position-beams-top;
        left: -100px;
        z-index: -1;
        transform: scale(1.08);
        margin-top: 400px;
        @include beam-color;
        opacity: 0;

        img {
            transform: scaleX(-1);
        }
    }

    #light-beam-back-right {
        @include position-beams-top;
        right: -100px;
        z-index: -1;
        margin-top: 400px;
        transform: scale(1.08);
        opacity: 0;
        @include beam-color;
    }

    #lens-flare-left {
        position: absolute;
        left: -10px;
        top: -10px;

        img {
            transform: scaleX(-1);
        }
    }

    #lens-flare-right {
        position: absolute;
        right: -10px;
        top: -10px;
        opacity: 0;
    }
}

#light-background {
    position: absolute;
    transform: translate(-50%, -51%);
    z-index: -10;
    left: 50%;
    top: 50%;
    display: none;
    width: 1920px;
    height: 1280px;
    background-image: url(../img/effects/lightened-bg.png);
    background-size: 1350px;
    background-position: center 70px;
    background-repeat: no-repeat;

    @include min(1500px) {
        background-size: unset;
        background-position: center;
    }

    @include desktop {
        display: block;
        opacity: 0;
    }
}

.sparkle-canvas {
    @include sparkle-mask;
}

#back-to-start {
    opacity: 0;
    position: fixed;
    top: -9999px;

    @include desktop {
        opacity: 1;
        position: relative;
        top: unset;
    }
}