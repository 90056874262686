// @font-face {
//     font-family: 'proxima_novablack';
//     src: url('/public/fonts/proxima_nova_black-webfont.woff2') format('woff2'),
//         url('/public/fonts/proxima_nova_black-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'proxima_novabold';
//     src: url('/public/fonts/proxima_nova_bold-webfont.woff2') format('woff2'),
//         url('/public/fonts/proxima_nova_bold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'proxima_novaregular';
//     src: url('/public/fonts/proxima_nova_regular-webfont.woff2') format('woff2'),
//         url('/public/fonts/proxima_nova_regular-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

@mixin font-regular {
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin font-bold {
    font-family: "proxima-nova", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@mixin font-black {
    font-family: "proxima-nova", sans-serif;
    font-weight: 900;
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-black;
    text-transform: uppercase;
}

a {
    color: $colour-text;
}

@mixin title {
    font-size: 2rem;
    line-height: 1;

    @include min(1300px) {
        font-size: 3.8rem;
        line-height: 0.9;
    }
}

@mixin sub-title {
    font-size: 1.5rem;
    line-height: 1.25;

    @include min(1300px) {
        font-size: 2rem;
    }
}

@mixin sub-title-light {
    font-size: 1.5rem;
    line-height: 1;
    @include font-regular;
    font-weight: bold;
}