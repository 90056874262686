.file-container {
  overflow:hidden;
  margin-top: 10px;
  input[type="file"] {
    cursor: inherit;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    height:60px;
  }
  svg {
    fill: #ffffff;
    margin-left:5px;
  }
  .file-label{
    padding: 8px;
    background: #000000;
    color: #ffffff;
    font-size: 15px;
    width: 100%;
    text-align: center!important;
    margin-bottom: 20px;
    cursor: pointer;
  }

  input[type="file"]:focus + .file-label,
  input[type="file"] + .file-label:hover {
    background-color: red;
  }
  input[type="file"] + .file-label {
    cursor: pointer; 
  }
}
.col-lg-6.file-container {
    position: relative;
}
.finalist-submit {
  background: #be8f2d;
  color:#ffffff;
  width:100%;
  border:0px;
  font-size: 18px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}
