.social {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: none;
        margin: auto 0 auto auto;

        @include min(400px) {
            display: flex;
        }

        li {
            display: flex;
            margin-left: 13px;
            line-height: 0;

             a {
                 display: block;

                 i {
                     font-size: 1.4rem;

                     @include desktop {
                         font-size: 1.6rem;
                     }
                 }
             }
        }
    }
}